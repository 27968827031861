export const SELECT_USER = 'SELECT_USER';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const ERROR_UPDATE_NOTIFICATION = 'ERROR_UPDATE_NOTIFICATION';
export const SUCCESS_UPDATE_NOTIFICATION = 'SUCCESS_UPDATE_NOTIFICATION';
export const EMPTY_MESSAGE_ACTIVITY = 'EMPTY_MESSAGE_ACTIVITY';

export const INIT_INVITE_USER = 'INIT_INVITE_USER'; // when invite user modal is opened
export const INVITE_USER = 'INVITE_USER';
export const INVITE_USER_SENT = 'INVITE_USER_SENT';
export const INVITE_USER_NOT_SENT = 'INVITE_USER_NOT_SENT';

export const FETCH_USERS_LIST_ACTIVITY_PAGE = 'FETCH_USERS_LIST_ACTIVITY_PAGE';
export const SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE =
  'SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE';
export const ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE =
  'ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE';

export const FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE =
  'FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE';
export const SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE =
  'SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE';
export const ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE =
  'ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE';

export const LOAD_SELF_DATA_AS_SELECTED_USER =
  'LOAD_SELF_DATA_AS_SELECTED_USER'; // used when logged in user is contributor

export const FETCH_FEED_USER = 'FETCH_FEED_USER';
export const SUCCESS_FETCH_FEED_USER = 'SUCCESS_FETCH_FEED_USER';
export const ERROR_FETCH_FEED_USER = 'ERROR_FETCH_FEED_USER';

export const FETCH_SITE_LIST_USER = 'FETCH_SITE_LIST_USER';
export const SUCCESS_FETCH_SITE_LIST_USER = 'SUCCESS_FETCH_SITE_LIST_USER';
export const ERROR_FETCH_SITE_LIST_USER = 'ERROR_FETCH_SITE_LIST_USER';

export const FETCH_MORE_SITE_LIST_USER = 'FETCH_MORE_SITE_LIST_USER';
export const SUCCESS_FETCH_MORE_SITE_LIST_USER =
  'SUCCESS_FETCH_MORE_SITE_LIST_USER';
export const ERROR_FETCH_MORE_SITE_LIST_USER =
  'ERROR_FETCH_MORE_SITE_LIST_USER';

export const ADD_TO_SITE = 'ADD_TO_SITE';
export const SUCCESS_ADD_TO_SITE = 'SUCCESS_ADD_TO_SITE';
export const ERROR_ADD_TO_SITE = 'ERROR_ADD_TO_SITE';

export const START_UPLOAD_IMAGE = 'START_UPLOAD_IMAGE';
export const SUCCESS_UPLOAD_IMAGE = 'SUCCESS_UPLOAD_IMAGE';
export const ERROR_UPLOAD_IMAGE = 'ERROR_UPLOAD_IMAGE';
export const INIT_UPLOAD = 'INIT_UPLOAD';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SUCCESS_UPDATE_PROFILE = 'SUCCESS_UPDATE_PROFILE';
export const ERROR_UPDATE_PROFILE = 'ERROR_UPDATE_PROFILE';

export const LEFT_ACTIVITY_PAGE = 'LEFT_ACTIVITY_PAGE';

export const START_DELETE_USER = 'START_DELETE_USER';
export const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';
export const ERROR_DELETE_USER = 'ERROR_DELETE_USER';

export const START_RE_INVITE_USER = 'START_RE_INVITE_USER';
export const SUCCESS_RE_INVITE_USER = 'SUCCESS_RE_INVITE_USER';
export const ERROR_RE_INVITE_USER = 'ERROR_RE_INVITE_USER';

export const USER_ACTIVITY_PANEL_HIDE = 'USER_ACTIVITY_PANEL_HIDE';

export const FETCH_PROJECT_USER = 'FETCH_PROJECT_USER';
export const SUCCESS_FETCH_PROJECT_USER = 'SUCCESS_FETCH_PROJECT_USER';
export const ERROR_FETCH_PROJECT_USER = 'ERROR_FETCH_PROJECT_USER';

export const UPDATE_USERS_CHECKED_STATUS = 'UPDATE_USERS_CHECKED_STATUS';
export const UPDATE_SITES_CHECKED_STATUS = 'UPDATE_SITES_CHECKED_STATUS';

export const ADD_TO_PROJECT = 'ADD_TO_PROJECT';
export const SUCCESS_ADD_TO_PROJECT = 'SUCCESS_ADD_TO_PROJECT';
export const ERROR_ADD_TO_PROJECT = 'ERROR_ADD_TO_PROJECT';

export const FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE =
  'FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE';
export const SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE =
  'SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE';
export const ERROR_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE =
  'ERROR_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE';

export const REMOVE_INVITED_TEAM = 'REMOVE_INVITED_TEAM';
export const SUCCESS_REMOVE_INVITED_TEAM = 'SUCCESS_REMOVE_INVITED_TEAM';
export const ERROR_REMOVE_INVITED_TEAM = 'ERROR_REMOVE_INVITED_TEAM';

export const EMPTY_SITE_LIST_USER = 'EMPTY_SITE_LIST_USER';
