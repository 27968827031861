import { call, fork, join, put, select, takeLatest } from 'redux-saga/effects';

import {
  ERROR_COMPOSE_EMAIL,
  ERROR_EMAIL_TASKS,
  ERROR_EMAIL_TASK_TO_SITE,
  ERROR_FETCH_NEXT_EMAIL_TASKS,
  ERROR_REPLY_EMAIL,
  ERROR_VERIFY_MAILBOX,
  FETCH_NEXT_EMAIL_TASKS,
  NEW_EMAIL_SELECTED,
  SET_SELECTED_MAILBOX_ID,
  START_COMPOSE_EMAIL,
  START_EMAIL_TASK_TO_SITE,
  START_REPLY_EMAIL,
  START_VERIFY_MAILBOX,
  SUCCESS_COMPOSE_EMAIL,
  SUCCESS_EMAIL_TASKS,
  SUCCESS_EMAIL_TASK_TO_SITE,
  SUCCESS_EMAIL_VERIFICATION,
  SUCCESS_FETCH_NEXT_EMAIL_TASKS,
  SUCCESS_REPLY_EMAIL,
  SUCCESS_VERIFY_MAILBOX,
} from 'actions/email';

import { LogoutObjectForPutEffect } from 'utils/constants';

import {
  FETCH_BOARD_COLUMN,
  FETCH_NEXT_BOARD_EMAIL_TASKS,
  NEW_BOARD_WEBSITE_SELECTED,
  SUCCESS_EMAIL_COMPLETE_TASKS,
  SUCCESS_EMAIL_CRITICAL_TASKS,
  SUCCESS_EMAIL_HIGH_TASKS,
  SUCCESS_EMAIL_IN_PROGRESS_TASKS,
  SUCCESS_EMAIL_LOW_TASKS,
  SUCCESS_EMAIL_MEDIUM_TASKS,
  SUCCESS_EMAIL_OPEN_TASKS,
  SUCCESS_EMAIL_PENDING_REVIEW_TASKS,
  SUCCESS_NEXT_EMAIL_COMPLETE_TASKS,
  SUCCESS_NEXT_EMAIL_CRITICAL_TASKS,
  SUCCESS_NEXT_EMAIL_HIGH_TASKS,
  SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS,
  SUCCESS_NEXT_EMAIL_LOW_TASKS,
  SUCCESS_NEXT_EMAIL_MEDIUM_TASKS,
  SUCCESS_NEXT_EMAIL_OPEN_TASKS,
  SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS,
} from 'actions/board';
import {
  ERROR_FILTER_FETCHING,
  NEW_TASK_SELECTED,
  NEW_WEBSITE_SELECTED,
  SUCCESS_FILTER_FETCHING,
} from 'actions/tasks';
import {
  assignEmailToSite,
  compose,
  emailVerification,
  fetchAllEmails,
  fetchEmailForBoardsColumn,
  replyToEmail,
  verifyMailboxEmail,
} from '../api/email';
import { fetchTaskFilters } from '../api/tasks';
import { getSelectedWebsiteId } from 'utils/functions';

function* getTasksFilters(id) {
  const data = yield call(fetchTaskFilters, id);

  if (data.status) {
    yield put({
      type: SUCCESS_FILTER_FETCHING,
      filters: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    if (data.message) {
      yield put({
        type: ERROR_FILTER_FETCHING,
        message: data.message || 'Something went wrong, try again later!',
      });
    } else {
      yield put({
        type: ERROR_FILTER_FETCHING,
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  }
}

function* fetchEmailsWorker(id) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(fetchAllEmails, {
    id,
    workspace_id: selectedWorkspace.id,
  });

  if (data.status) {
    yield put({
      type: SUCCESS_EMAIL_TASKS,
      payload: data.data,
      moreRecords: data.more_records,
    });
    yield put({
      type: SET_SELECTED_MAILBOX_ID,
      id: id,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EMAIL_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

function* fetchEmailsWorkerForBoards(params) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchEmailForBoardsColumn,
    params,
    selectedWorkspace?.id
  );
  const mapColumnsToActions = {
    open: SUCCESS_EMAIL_OPEN_TASKS,
    'in-progress': SUCCESS_EMAIL_IN_PROGRESS_TASKS,
    'pending-review': SUCCESS_EMAIL_PENDING_REVIEW_TASKS,
    complete: SUCCESS_EMAIL_COMPLETE_TASKS,
    low: SUCCESS_EMAIL_LOW_TASKS,
    medium: SUCCESS_EMAIL_MEDIUM_TASKS,
    high: SUCCESS_EMAIL_HIGH_TASKS,
    critical: SUCCESS_EMAIL_CRITICAL_TASKS,
  };

  if (data.status) {
    yield put({
      // type: SUCCESS_EMAIL_TASKS,
      type: mapColumnsToActions[params.columnType],
      payload: data.data,
      moreRecords: data.more_records,
      totalColumnRecords: data.total_column_records,
    });
    yield put({
      type: SET_SELECTED_MAILBOX_ID,
      id: params.id,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EMAIL_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

function* fetchTasksAndFilters(action) {
  const { id, screen } = action;

  if (id !== '') {
    const fetchTaskFiltersJob = yield fork(getTasksFilters, id);
    // const fetchTasksListJob = yield fork(fetchEmailsWorker, id,screen);

    if (screen === 'board') {
      // Fetch Email Status tasks
      const fetchOpenTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_status',
        columnType: 'open',
      });
      const fetchInProgressTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_status',
        columnType: 'in-progress',
      });
      const fetchPendingReviewTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_status',
        columnType: 'pending-review',
      });
      const fetchCompleteTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_status',
        columnType: 'complete',
      });
      // Fetch Email Urgency tasks
      const fetchLowTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_priority',
        columnType: 'low',
      });
      const fetchMediumTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_priority',
        columnType: 'medium',
      });
      const fetchHighTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_priority',
        columnType: 'high',
      });
      const fetchCriticalTasks = yield fork(fetchEmailsWorkerForBoards, {
        id,
        taskCategory: 'task_priority',
        columnType: 'critical',
      });
      yield join([
        fetchOpenTasks,
        fetchInProgressTasks,
        fetchPendingReviewTasks,
        fetchCompleteTasks,
        fetchLowTasks,
        fetchMediumTasks,
        fetchHighTasks,
        fetchCriticalTasks,
      ]);
    } else {
      const fetchTasksListJob = yield fork(fetchEmailsWorker, id);
      yield join([fetchTasksListJob]);
    }

    yield join([
      fetchTaskFiltersJob,
      // fetchTasksListJob
    ]);
  }
}

export function* fetchEmailsWatcher() {
  yield takeLatest([NEW_EMAIL_SELECTED], fetchTasksAndFilters);
}

function* fetchNextEmailTaskForBoardWorker(action) {
  const { params } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(
    fetchEmailForBoardsColumn,
    params,
    selectedWorkspace?.id
  );
  const mapColumnsToActions = {
    open: SUCCESS_NEXT_EMAIL_OPEN_TASKS,
    'in-progress': SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS,
    'pending-review': SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS,
    complete: SUCCESS_NEXT_EMAIL_COMPLETE_TASKS,
    low: SUCCESS_NEXT_EMAIL_LOW_TASKS,
    medium: SUCCESS_NEXT_EMAIL_MEDIUM_TASKS,
    high: SUCCESS_NEXT_EMAIL_HIGH_TASKS,
    critical: SUCCESS_NEXT_EMAIL_CRITICAL_TASKS,
  };
  if (data.status) {
    yield put({
      type: mapColumnsToActions[params.columnType],
      payload: data.data,
      moreRecords: data.more_records,
    });
    yield put({
      type: SET_SELECTED_MAILBOX_ID,
      id: params.id,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EMAIL_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}
export function* fetchNextEmailTaskForBoardWatcher() {
  yield takeLatest(
    FETCH_NEXT_BOARD_EMAIL_TASKS,
    fetchNextEmailTaskForBoardWorker
  );
}

function* verifyMailboxWorker(action) {
  const { emailObj } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(verifyMailboxEmail, emailObj, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_VERIFY_MAILBOX,
      payload: data.data,
      message: data.message,
    });
    yield call(emailVerificationWorker);
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_VERIFY_MAILBOX,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* verifyMailboxWatcher() {
  yield takeLatest(START_VERIFY_MAILBOX, verifyMailboxWorker);
}

function* assignEmailToSiteWorker(action) {
  const { emailObj } = action;
  const data = yield call(assignEmailToSite, emailObj);

  if (data.status) {
    yield put({
      type: SUCCESS_EMAIL_TASK_TO_SITE,
      message: data.message,
    });
    // Select the site which this task is assigned to
    if (emailObj.is_mailbox) {
      yield put({
        type: NEW_EMAIL_SELECTED,
        id: emailObj.site_id,
      });
      yield put({
        type: NEW_TASK_SELECTED,
        taskId: emailObj.task_id,
      });
    } else {
      if (emailObj.accessFromBoards) {
        yield put({
          type: NEW_BOARD_WEBSITE_SELECTED,
          websiteId: emailObj.site_id,
        });
      } else {
        yield put({
          type: NEW_WEBSITE_SELECTED,
          websiteId: emailObj.site_id,
        });
        yield put({
          type: NEW_TASK_SELECTED,
          taskId: emailObj.task_id,
        });
      }
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EMAIL_TASK_TO_SITE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* assignEmailToSiteWatcher() {
  yield takeLatest(START_EMAIL_TASK_TO_SITE, assignEmailToSiteWorker);
}

function* emailVerificationWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(emailVerification, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_EMAIL_VERIFICATION,
      verified: data.verified,
    });
  } else {
    yield call(emailVerificationWorker);
  }
}

function* replyEmailWorker(action) {
  const { emailObj } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(replyToEmail, emailObj, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_REPLY_EMAIL,
      message: data.message || 'Replied successfully',
    });

    const getId = (state) => state.tasks.selectedTask;
    const id = yield select(getId);
    yield put({
      type: NEW_TASK_SELECTED,
      taskId: id,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_REPLY_EMAIL,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* replyEmailWatcher() {
  yield takeLatest(START_REPLY_EMAIL, replyEmailWorker);
}

function* composeEmailWorker(action) {
  const { emailObj, screen } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(compose, emailObj, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_COMPOSE_EMAIL,
      message: data.message,
    });
    // Select the mailbox
    const getId = (state) => state.tasks.selectedMailboxId; // take from reducer
    const id = yield select(getId);
    if (screen) {
      yield put({
        type: NEW_EMAIL_SELECTED,
        id: id,
        screen: 'board',
      });
    } else {
      yield put({
        type: NEW_EMAIL_SELECTED,
        id: id,
      });
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_COMPOSE_EMAIL,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* composeEmailWatcher() {
  yield takeLatest(START_COMPOSE_EMAIL, composeEmailWorker);
}

function* fetchNextEmailTasksWorker(action) {
  const { offset } = action;

  const selectedWebsite = yield call(getSelectedWebsiteId);

  const data = yield call(fetchAllEmails, { id: selectedWebsite, offset });

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_EMAIL_TASKS,
      payload: data.data,
      moreRecords: data.more_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_EMAIL_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchNextEmailTasksWatcher() {
  yield takeLatest(FETCH_NEXT_EMAIL_TASKS, fetchNextEmailTasksWorker);
}

function* fetchBoardColumnWorker(action) {
  const { params } = action;

  const taskYields = [];

  const isStatusColumn = {
    open: true,
    'in-progress': true,
    'pending-review': true,
    complete: true,
  };

  if (params.emailSelected) {
    for (let datum of params.data) {
      const task = yield fork(fetchEmailsWorkerForBoards, {
        id: datum.websiteId,
        taskCategory: isStatusColumn[datum.column_type]
          ? 'task_status'
          : 'task_priority',
        columnType: datum.column_type,
      });

      taskYields.push(task);
    }
    yield join(taskYields);
  }
}

export function* fetchBoardEmailColumnWatcher() {
  yield takeLatest(FETCH_BOARD_COLUMN, fetchBoardColumnWorker);
}
