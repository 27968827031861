import {
  DELETE_FORM,
  ERROR_DELETE_FORM,
  ERROR_GET_ALL_FORMS,
  ERROR_GET_ALL_FORM_TEMPLATES,
  ERROR_GET_FORM_SUBMISSIONS,
  ERROR_GET_SINGLE_FORM,
  ERROR_SAVE_FORM,
  ERROR_SAVE_TEMPLATE,
  ERROR_SEARCH_FORMS,
  ERROR_UPDATE_FORM,
  ERROR_UPDATE_FORM_STATUS,
  GET_ALL_FORMS,
  GET_ALL_FORM_TEMPLATES,
  GET_FORM_SUBMISSIONS,
  GET_SINGLE_FORM,
  RESET_DISCOVERY_FLOW,
  SAVE_FORM,
  SAVE_TEMPLATE,
  SET_IS_ADD_FORM_OPEN,
  SET_IS_EDITING_FORM,
  START_SEARCH_FORMS,
  SUCCESS_DELETE_FORM,
  SUCCESS_GET_ALL_FORMS,
  SUCCESS_GET_ALL_FORM_TEMPLATES,
  SUCCESS_GET_FORM_SUBMISSIONS,
  SUCCESS_GET_SINGLE_FORM,
  SUCCESS_SAVE_FORM,
  SUCCESS_SAVE_TEMPLATE,
  SUCCESS_SEARCH_FORMS,
  SUCCESS_UPDATE_FORM,
  SUCCESS_UPDATE_FORM_STATUS,
  TOGGLE_HAS_CREATED_FORM,
  TOGGLE_HAS_CREATED_TEMPLATE,
  TOGGLE_HAS_DELETED_FORM,
  TOGGLE_HAS_GOTTEN_ALL_FORMS,
  TOGGLE_HAS_GOTTEN_FORM,
  TOGGLE_HAS_GOTTEN_FORM_SUBMISSION,
  TOGGLE_HAS_UPDATED_FORM,
  TOGGLE_HAS_UPDATED_FORM_STATUS,
  UPDATE_FORM,
  UPDATE_FORM_STATUS,
} from 'actions/discovery_flow';

const initState = {
  forms: [],
  hasCreatedForm: false,
  hasGottenForms: false,
  isFormCreated: false,
  hasUpdatedForm: false,
  isGettingFormTemplates: false,
  error: '',
  form: {},
  hasGottenForm: false,
  hasGottenFormTemplates: false,
  curFormSubmissions: [],
  hasGottenFormSubmission: false,
  hasDeletedForm: false,
  hasUpdatedFormStatus: false,
  isSearchingForms: false,
  hasCreatedFormTemplate: false,
  isAddFormOpen: false,
  isEditingForm: false,
};

const discovery_flow = (previousState = initState, action) => {
  switch (action.type) {
    // Getting all Forms
    case GET_ALL_FORMS:
      return {
        ...previousState,
        hasGottenForms: false,
      };
    case SUCCESS_GET_ALL_FORMS:
      return {
        ...previousState,
        forms: action.data,
        hasGottenForms: true,
      };
    case ERROR_GET_ALL_FORMS:
      return {
        ...previousState,
        hasGottenForms: true,
        error: action.error,
      };
    case TOGGLE_HAS_GOTTEN_ALL_FORMS:
      return {
        ...previousState,
        hasGottenForms: !previousState.hasGottenForms,
      };
    // Searching Forms
    case START_SEARCH_FORMS:
      return {
        ...previousState,
        isSearchingForms: true,
      };
    case SUCCESS_SEARCH_FORMS:
      return {
        ...previousState,
        forms: action.data,
        isSearchingForms: false,
      };
    case ERROR_SEARCH_FORMS:
      return {
        ...previousState,
        isSearchingForms: false,
        error: action.error,
      };
    // Getting single Form
    case GET_SINGLE_FORM:
      return {
        ...previousState,
        hasGottenForm: false,
      };
    case SUCCESS_GET_SINGLE_FORM:
      return {
        ...previousState,
        form: action.data,
        hasGottenForm: true,
      };
    case ERROR_GET_SINGLE_FORM:
      return {
        ...previousState,
        hasGottenForm: false,
        error: action.error,
      };
    case TOGGLE_HAS_GOTTEN_FORM:
      return {
        ...previousState,
        hasGottenForm: !previousState.hasGottenForm,
      };
    // Getting all form templates
    case GET_ALL_FORM_TEMPLATES:
      return {
        ...previousState,
        isGettingFormTemplates: true,
        hasGottenFormTemplates: false,
      };
    case SUCCESS_GET_ALL_FORM_TEMPLATES:
      return {
        ...previousState,
        formTemplates: action.data,
        isGettingFormTemplates: false,
        hasGottenFormTemplates: true,
      };
    case ERROR_GET_ALL_FORM_TEMPLATES:
      return {
        ...previousState,
        isGettingFormTemplates: false,
        hasGottenFormTemplates: false,
        error: action.error,
      };
    // Create form templates
    case SAVE_TEMPLATE:
      return {
        ...previousState,
        hasCreatedFormTemplate: false,
      };
    case SUCCESS_SAVE_TEMPLATE:
      const newTemplates = [].concat(action.data, previousState.formTemplates);
      return {
        ...previousState,
        formTemplates: newTemplates,
        hasCreatedFormTemplate: true,
      };
    case ERROR_SAVE_TEMPLATE:
      return {
        ...previousState,
        hasCreatedFormTemplate: false,
        error: action.error,
      };
    case TOGGLE_HAS_CREATED_TEMPLATE:
      return {
        ...previousState,
        hasCreatedFormTemplate: !previousState.hasCreatedFormTemplate,
      };
    // Create forms
    case SAVE_FORM:
      return {
        ...previousState,
        hasCreatedForm: false,
      };
    case SUCCESS_SAVE_FORM:
      const newForm = [].concat(action.data, previousState.forms);
      return {
        ...previousState,
        forms: newForm,
        hasCreatedForm: true,
      };
    case ERROR_SAVE_FORM:
      return {
        ...previousState,
        hasCreatedForm: false,
        error: action.error,
      };
    case TOGGLE_HAS_CREATED_FORM:
      return {
        ...previousState,
        hasCreatedForm: !previousState.hasCreatedForm,
      };
    //Form submissions
    case GET_FORM_SUBMISSIONS:
      return {
        ...previousState,
        hasGottenFormSubmission: false,
      };
    case SUCCESS_GET_FORM_SUBMISSIONS:
      return {
        ...previousState,
        hasGottenFormSubmission: true,
        curFormSubmissions: action.data,
      };
    case ERROR_GET_FORM_SUBMISSIONS:
      return {
        ...previousState,
        hasGottenFormSubmission: false,
        error: action.error,
      };
    case TOGGLE_HAS_GOTTEN_FORM_SUBMISSION:
      return {
        ...previousState,
        hasGottenFormSubmission: !previousState.hasGottenFormSubmission,
      };
    // Updating forms
    case UPDATE_FORM:
      return {
        ...previousState,
        isUpdatingForm: true,
        hasUpdatedForm: false,
      };
    case SUCCESS_UPDATE_FORM:
      const updatedForms = previousState.forms.map((form) => {
        let newForm = form;
        if (form.id === action.data.id) newForm = action.data;
        return newForm;
      });
      return {
        ...previousState,
        forms: updatedForms,
        isUpdatingForm: false,
        hasUpdatedForm: true,
      };
    case ERROR_UPDATE_FORM:
      return {
        ...previousState,
        isUpdatingForm: false,
        hasUpdatedForm: false,
        error: action.error,
      };
    case TOGGLE_HAS_UPDATED_FORM:
      return {
        ...previousState,
        hasUpdatedForm: !previousState.hasUpdatedForm,
      };
    // Updating form status
    case UPDATE_FORM_STATUS:
      return {
        ...previousState,
        hasUpdatedFormStatus: false,
      };
    case SUCCESS_UPDATE_FORM_STATUS:
      const formsWithUpdatedStatus = previousState.forms.map((form) => {
        let newForm = form;
        if (form.id === action.data.form_id) newForm.status = !form.status;
        return newForm;
      });
      return {
        ...previousState,
        forms: formsWithUpdatedStatus,
        hasUpdatedFormStatus: true,
      };
    case ERROR_UPDATE_FORM_STATUS:
      return {
        ...previousState,
        hasUpdatedFormStatus: false,
        error: action.error,
      };
    case TOGGLE_HAS_UPDATED_FORM_STATUS:
      return {
        ...previousState,
        hasUpdatedFormStatus: !previousState.hasUpdatedFormStatus,
      };
    // Deleting forms
    case DELETE_FORM:
      return {
        ...previousState,
        hasDeletedForm: false,
      };
    case SUCCESS_DELETE_FORM:
      const filteredForms = previousState.forms.filter((form) => {
        return form.id !== action.data.form_id;
      });
      return {
        ...previousState,
        forms: filteredForms,
        hasDeletedForm: true,
      };
    case ERROR_DELETE_FORM:
      return {
        ...previousState,
        hasDeletedForm: false,
        error: action.error,
      };
    case TOGGLE_HAS_DELETED_FORM:
      return {
        ...previousState,
        hasDeletedForm: !previousState.hasDeletedForm,
      };
    case SET_IS_ADD_FORM_OPEN:
      return {
        ...previousState,
        isAddFormOpen: action.value,
      };
    case SET_IS_EDITING_FORM:
      return {
        ...previousState,
        isEditingForm: action.value,
      };
    case RESET_DISCOVERY_FLOW:
      return initState;
    default:
      return previousState;
  }
};

export default discovery_flow;
