import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '../api/endpoints/user';
import type {
  CannedSnippetTemplateList,
  GlobalSettings,
  SnippetDetails,
  VerifyDomainData,
} from '../types/setting';

export interface SettingState {
  globalSettings: GlobalSettings | null;
  wpfeedback_logo_file: any;
  openSideNav: boolean;
  openIconSideNav: boolean;
  refreshedWebhookToken: string;
  verifyDomainData: VerifyDomainData | null;
  clickupTeams: any[];
  clickupWorkspaces: any[];
  asanaWorkspaces: any[];
  asanaTeams: any[];
  jiraWebsites: any[];
  clickupFolders: any[];
  clickupTaskList: any[];
  asanaProjects: any[];
  asanaSections: any[];
  teamworkProjects: any[];
  teamworkTaskLists: any[];
  jiraProjects: any[];
  rocketStatus: boolean;
  basecampProjects: any[];
  basecampTasks: any[];
  trelloBoard: any[];
  trelloList: any[];
  changeToggleState: boolean;
  cannedSnippetList: any[];
  selectedSnippet: string;
  snippetDetails: SnippetDetails | null;
  cannedSnippetTemplateList: CannedSnippetTemplateList[];
  mondayWorkspace: any[];
  mondayBoard: any[];
  mondayGroup: any[];
}

const initialState: SettingState = {
  globalSettings: null,
  wpfeedback_logo_file: null,
  openSideNav: false,
  openIconSideNav: false,
  refreshedWebhookToken: '',
  verifyDomainData: null,
  clickupTeams: [],
  clickupWorkspaces: [],
  asanaWorkspaces: [],
  asanaTeams: [],
  jiraWebsites: [],
  clickupFolders: [],
  clickupTaskList: [],
  asanaProjects: [],
  asanaSections: [],
  teamworkProjects: [],
  teamworkTaskLists: [],
  jiraProjects: [],
  mondayWorkspace: [],
  mondayBoard: [],
  mondayGroup: [],
  rocketStatus: false,
  basecampProjects: [],
  basecampTasks: [],
  trelloBoard: [],
  trelloList: [],
  changeToggleState: false,
  cannedSnippetList: [],
  selectedSnippet: '',
  snippetDetails: null,
  cannedSnippetTemplateList: [],
};

const taskSlice = createSlice({
  name: 'settings_new',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.fetchGlobalSettings.matchFulfilled,
      (state, { payload }: PayloadAction<GlobalSettings>) => {
        state.globalSettings = payload;
      }
    );
  },
});

export const {} = taskSlice.actions;

export default taskSlice.reducer;
