import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface UrlPreviewResponse {
  title: string;
  description: string;
  image: string;
  url: string;
  charset: string;
}

export const urlPreviewApi = createApi({
  reducerPath: 'urlPreviewApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://link-preview.fox-918.workers.dev/',
  }),
  tagTypes: ['Website'],
  endpoints: (builder) => ({
    getUrlPreview: builder.query<UrlPreviewResponse, string>({
      query: (url) => `?q=${encodeURIComponent(url)}`,
      providesTags: (_, error, url) => {
        if (error) return [];
        return [{ type: 'Website', id: url }];
      },
    }),
  }),
});

export const { useGetUrlPreviewQuery } = urlPreviewApi;
