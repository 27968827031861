import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TagManager from 'react-gtm-module';
import { theme } from 'theme';
import { TooltipProvider } from 'ui/components/Tooltip/index.js';
import './App.css';
import MaintenancePage from './components/Maintenanace.js';
import { useAppSelector } from './hooks/redux.js';
import Main from './Main.jsx';
import './style.css';
const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();
queryClient.setQueryDefaults(['authenticate'], {
  staleTime: Infinity,
  gcTime: Infinity,
});

function App() {
  const isInMaintenance = useAppSelector(
    (state) => state.global.isInMaintenance
  );
  const posthogClient = usePostHog();
  posthogClient.init(import.meta.env.VITE_APP_POSTHOG_PROJECT_API_KEY, {
    api_host:
      import.meta.env.VITE_APP_POSTHOG_API_HOST ||
      'https://hedgehog.atarimworker.io',
  });
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    posthogClient.set_config({ disable_session_recording: true });
  }
  if (isInMaintenance) {
    return <MaintenancePage />;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <TooltipProvider delayDuration={200}>
            <div
              className="flex h-screen w-full overflow-hidden"
              vaul-drawer-wrapper=""
            >
              <Main />
            </div>
          </TooltipProvider>
        </DndProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
