import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  ASANA_GET_PROJECTS_SETTINGS,
  ASANA_GET_SECTION_SETTINGS,
  ASANA_GET_TEAM,
  ASANA_GET_WORKSPACES,
  ASANA_STORE_TEAMS,
  ASANA_STORE_WORKSPACE,
  BASECAMP_GET_PROJECTS_SETTINGS,
  BASECAMP_GET_SECTION_SETTINGS,
  CLICKUP_COMPLETE_AUTH,
  CLICKUP_GET_FOLDERS_SETTINGS,
  CLICKUP_GET_TASK_LIST_SETTINGS,
  CLICKUP_GET_TEAMS,
  CLICKUP_GET_WORKSPACES,
  CLICKUP_STORE_TEAM,
  CLICKUP_STORE_WORKSPACE,
  ERROR_CHANGE_CUSTOM_WEBHOOK,
  ERROR_CHANGE_INSTAWP_SETTINGS,
  ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  ERROR_CHANGE_MAINWP_SETTINGS,
  ERROR_CHANGE_MONDAY_SETTINGS,
  ERROR_CHANGE_SLACK_SETTINGS,
  ERROR_CHANGE_WEBHOOK_STATUS,
  ERROR_CLICKUP_STORE_TEAM,
  ERROR_CLICKUP_STORE_WORKSPACE,
  ERROR_CONNECT_ROCKET_SETTING,
  ERROR_CREATE_SNIPPET,
  ERROR_CREATE_SNIPPET_TEMPLATE,
  ERROR_FETCH_ALL_SNIPPET,
  ERROR_FETCH_ALL_SNIPPET_TEMPLATE,
  ERROR_IMPORT_SNIPPET_TEMPLATE,
  ERROR_MONDAY_STORE_WORKSPACE,
  ERROR_NEW_SNIPPET_SELECTED,
  ERROR_REFRESH_WEBHOOK_TOKEN,
  ERROR_RESET_SETTINGS,
  ERROR_SAVE_GLOBAL_SETTINGS,
  ERROR_SNIPPET_DELETE,
  ERROR_UPDATE_SNIPPET,
  ERROR_VALIDATE_EMAIL_INBOX,
  ERROR_VERIFY_DOMAIN,
  ERROR_VERIFY_FORWARDING,
  GLOBAL_SETTINGS_FETCHED,
  GLOBAL_SETTINGS_NOT_FETCHED,
  IMPORT_SNIPPET_TEMPLATE,
  JIRA_GET_PROJECTS_SETTINGS,
  JIRA_GET_WEBSITES,
  JIRA_STORE_WEBSITES,
  MONDAY_GET_BOARD_SETTINGS,
  MONDAY_GET_GROUP_SETTINGS,
  MONDAY_GET_WORKSPACE,
  MONDAY_STORE_WORKSPACE,
  NEW_SNIPPET_SELECTED,
  RESET_SETTINGS,
  SAVE_GLOBAL_SETTINGS,
  SETTINGS_PAGE_MOUNTED,
  START_CHANGE_AUTOMATIC_TOGGLE_SETTINGS,
  START_CHANGE_CUSTOM_WEBHOOK,
  START_CHANGE_INSTAWP_SETTINGS,
  START_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  START_CHANGE_MAINWP_SETTINGS,
  START_CHANGE_MONDAY_SETTINGS,
  START_CHANGE_SLACK_SETTINGS,
  START_CHANGE_WEBHOOK_STATUS,
  START_CONNECT_ROCKET_SETTING,
  START_CREATE_SNIPPET,
  START_CREATE_SNIPPET_TEMPLATE,
  START_FETCH_ALL_SNIPPET,
  START_FETCH_ALL_SNIPPET_TEMPLATE,
  START_REFRESH_WEBHOOK_TOKEN,
  START_SNIPPET_DELETE,
  START_UPDATE_SNIPPET,
  STORE_INTEGRATION_REQUIREMENT,
  SUCCESS_ASANA_GET_PROJECTS_SETTINGS,
  SUCCESS_ASANA_GET_SECTION_SETTINGS,
  SUCCESS_ASANA_GET_TEAM,
  SUCCESS_ASANA_GET_WORKSPACES,
  SUCCESS_ASANA_STORE_TEAMS,
  SUCCESS_ASANA_STORE_WORKSPACE,
  SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS,
  SUCCESS_BASECAMP_GET_SECTION_SETTINGS,
  SUCCESS_CHANGE_CUSTOM_WEBHOOK,
  SUCCESS_CHANGE_INSTAWP_SETTINGS,
  SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
  SUCCESS_CHANGE_MAINWP_SETTINGS,
  SUCCESS_CHANGE_MONDAY_SETTINGS,
  SUCCESS_CHANGE_SLACK_SETTINGS,
  SUCCESS_CHANGE_WEBHOOK_STATUS,
  SUCCESS_CLICKUP_COMPLETE_AUTH,
  SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS,
  SUCCESS_CLICKUP_GET_TASK_LIST_SETTING,
  SUCCESS_CLICKUP_GET_TEAMS,
  SUCCESS_CLICKUP_GET_WORKSPACES,
  SUCCESS_CLICKUP_STORE_TEAM,
  SUCCESS_CLICKUP_STORE_WORKSPACE,
  SUCCESS_CONNECT_ROCKET_SETTING,
  SUCCESS_CREATE_SNIPPET,
  SUCCESS_CREATE_SNIPPET_TEMPLATE,
  SUCCESS_FETCH_ALL_SNIPPET,
  SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE,
  SUCCESS_IMPORT_SNIPPET_TEMPLATE,
  SUCCESS_JIRA_GET_PROJECTS_SETTINGS,
  SUCCESS_JIRA_GET_WEBSITES,
  SUCCESS_JIRA_STORE_WEBSITES,
  SUCCESS_MONDAY_GET_BOARD_SETTINGS,
  SUCCESS_MONDAY_GET_GROUP_SETTINGS,
  SUCCESS_MONDAY_GET_WORKSPACE,
  SUCCESS_MONDAY_STORE_WORKSPACE,
  SUCCESS_NEW_SNIPPET_SELECTED,
  SUCCESS_REFRESH_WEBHOOK_TOKEN,
  SUCCESS_RESET_SETTINGS,
  SUCCESS_SAVE_GLOBAL_SETTINGS,
  SUCCESS_SNIPPET_DELETE,
  SUCCESS_STORE_INTEGRATION_REQUIREMENT,
  SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS,
  SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS,
  SUCCESS_TRELLO_GET_LIST_SETTINGS,
  SUCCESS_TRELLO_GET_PROJECTS_SETTINGS,
  SUCCESS_UPDATE_SNIPPET,
  SUCCESS_VALIDATE_EMAIL_INBOX,
  SUCCESS_VERIFY_DOMAIN,
  SUCCESS_VERIFY_FORWARDING,
  TEAMWORK_GET_PROJECTS_SETTINGS,
  TEAMWORK_GET_TASKLIST_SETTINGS,
  TRELLO_GET_LIST_SETTINGS,
  TRELLO_GET_PROJECTS_SETTINGS,
  UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS,
  UPDATE_FOLDER_SELECTED_SETTINGS,
  UPDATE_MONDAY_BOARD_SELECTED_SETTINGS,
  UPDATE_PROJECT_SELECTED_SETTINGS,
  UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS,
  UPDATE_TRELLO_BOARD_SELECTED_SETTINGS,
  VALIDATE_EMAIL_INBOX,
  VERIFY_DOMAIN,
  VERIFY_FORWARDING,
} from 'actions/global_settings';
import { START_PROFILE_FETCH } from 'actions/user';

import {
  asanaGetProjectsSettings,
  asanaGetSectionSetting,
  asanaGetTeam,
  asanaGetWorkspaces,
  asanaStoreTeams,
  asanaStoreWorkspace,
  basecampGetProjectsSettings,
  basecampGetTaskSetting,
  changeAutomaticTaskSetting,
  changeCustomWebhook,
  changeInstawpSetting,
  changeIntegrationSetting,
  changeMainwpSetting,
  changeMondaySetting,
  changeSlackSetting,
  changeWebhookStatus,
  clickupCompleteAuth,
  clickupGetFoldersSettings,
  clickupGetTaskListSetting,
  clickupGetTeams,
  clickupGetWorkspaces,
  clickupStoreTeam,
  clickupStoreWorkspace,
  connectRocketSetting,
  createSnippetSetting,
  createSnippetTemplateSetting,
  deleteSnippetSetting,
  fetchAllSnippetSetting,
  fetchAllSnippetTemplateSetting,
  fetchGlobalSettings,
  fetchSnippetDetailSetting,
  importSnippetTemplateSetting,
  jiraGetProjectsSettings,
  jiraGetWebsites,
  jiraStoreWebsite,
  mondayGetBoardsSettings,
  mondayGetGroupSetting,
  mondayGetWorkspace,
  mondayStorespace,
  newWebhookToken,
  resetSettings,
  storeIntegrationRequirement,
  teamworkGetProjectsSettings,
  teamworkGetTaskListSetting,
  trelloGetListSetting,
  trelloGetProjectsSettings,
  updateGlobalSettings,
  updateSnippetSetting,
  validateEmailInbox,
  verifyDomain,
  verifyForwarding,
} from '../api/global_settings';
import { sendMessageToExtension } from 'utils/chromeExtension';
import {
  ApiErrorFallbackMessage,
  LogoutObjectForPutEffect,
} from 'utils/constants';
import { getSelectedWorkspace } from 'utils/functions';

function* fetchGlobalSettingsWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(fetchGlobalSettings, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: GLOBAL_SETTINGS_FETCHED,
      globalSettings: response.data,
    });
  } else {
    yield put({
      type: GLOBAL_SETTINGS_NOT_FETCHED,
      message: response.message || ApiErrorFallbackMessage,
    });
  }
}

export function* fetchGlobalSettingsWatcher() {
  yield takeLatest(SETTINGS_PAGE_MOUNTED, fetchGlobalSettingsWorker);
}

function* updateGlobalSettingsWorker(action) {
  const { formData } = action;

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    updateGlobalSettings,
    formData,
    selectedWorkspace?.id
  );
  if (response.status) {
    sendMessageToExtension({
      type: 'whiteLabelUpdate',
    });
    yield put({
      type: SUCCESS_SAVE_GLOBAL_SETTINGS,
      message: 'Saved successfully!',
    });
    if (localStorage.getItem('add_projects')) {
      yield put({
        type: START_PROFILE_FETCH,
      });
    }
    localStorage.removeItem('add_projects');
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SAVE_GLOBAL_SETTINGS,
      message: response.message || ApiErrorFallbackMessage,
    });
  }
}

export function* updateGlobalSettingsWatcher() {
  yield takeLatest(SAVE_GLOBAL_SETTINGS, updateGlobalSettingsWorker);
}

function* resetSettingsWorker() {
  const response = yield call(resetSettings);
  if (response.status) {
    yield put({
      type: SUCCESS_RESET_SETTINGS,
      message: 'Reset successful!',
      whiteLabel: response.data,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_RESET_SETTINGS,
      message: response.message || ApiErrorFallbackMessage,
    });
  }
}

export function* resetSettingsWatcher() {
  yield takeLatest(RESET_SETTINGS, resetSettingsWorker);
}

function* refreshWebhookTokenWorker() {
  const data = yield call(newWebhookToken);

  if (data.status) {
    yield put({
      type: SUCCESS_REFRESH_WEBHOOK_TOKEN,
      message: data.message || 'Token refreshed successfully!',
      webhookToken: data.new_webhook_token,
    });
  } else {
    yield put({
      type: ERROR_REFRESH_WEBHOOK_TOKEN,
      message: data.message || ApiErrorFallbackMessage,
    });
  }
}

export function* refreshWebhookTokenWatcher() {
  yield takeLatest(START_REFRESH_WEBHOOK_TOKEN, refreshWebhookTokenWorker);
}

function* changeWebhookStatusWorker(action) {
  const { obj } = action;

  const data = yield call(changeWebhookStatus, obj);
  if (data.status) {
    yield put({
      type: SUCCESS_CHANGE_WEBHOOK_STATUS,
      message: data.message || 'Status changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_WEBHOOK_STATUS,
      message: data.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeWebhookStatusWatcher() {
  yield takeLatest(START_CHANGE_WEBHOOK_STATUS, changeWebhookStatusWorker);
}

function* changeCustomWebhookWorker(action) {
  const { obj } = action;
  const data = yield call(changeCustomWebhook, obj);

  if (data.status) {
    yield put({
      type: SUCCESS_CHANGE_CUSTOM_WEBHOOK,
      message: data.message || 'URL changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_CUSTOM_WEBHOOK,
      message: data.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeCustomWebhookWatcher() {
  yield takeLatest(START_CHANGE_CUSTOM_WEBHOOK, changeCustomWebhookWorker);
}

function* changeSlackSettingWorker(action) {
  const { obj } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(changeSlackSetting, obj, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_CHANGE_SLACK_SETTINGS,
      message: data?.message || 'Slack settings changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_SLACK_SETTINGS,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeSlackSettingWatcher() {
  yield takeLatest(START_CHANGE_SLACK_SETTINGS, changeSlackSettingWorker);
}

function* changeIntegrationSettingsSettingWorker(action) {
  const { obj } = action;

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(changeIntegrationSetting, obj, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
      wpf_integrations: data.request.wpf_integrations,
      message: data?.message || 'Integration settings changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeIntegrationSettingWatcher() {
  yield takeLatest(
    START_CHANGE_INTEGRATION_TOGGLE_SETTINGS,
    changeIntegrationSettingsSettingWorker
  );
}

function* changeAutomaticTaskSettingWorker(action) {
  const { obj } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  yield call(changeAutomaticTaskSetting, obj, selectedWorkspace?.id);
}

export function* changeAutomaticSettingWatcher() {
  yield takeLatest(
    START_CHANGE_AUTOMATIC_TOGGLE_SETTINGS,
    changeAutomaticTaskSettingWorker
  );
}

function* verifyForwardingWorker() {
  const response = yield call(verifyForwarding);

  if (response.status) {
    yield put({
      type: SUCCESS_VERIFY_FORWARDING,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_VERIFY_FORWARDING,
      message: response.message || ApiErrorFallbackMessage,
    });
  }
}

export function* vertifyForwardingWatcher() {
  yield takeLatest(VERIFY_FORWARDING, verifyForwardingWorker);
}

function* verifyDomainWorker(action) {
  const { paramObj } = action;
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const response = yield call(verifyDomain, paramObj, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: SUCCESS_VERIFY_DOMAIN,
      message: 'Verified successfully!' || response.message,
      data: response.data,
      verifyDomainStatus: response.status,
    });
  } else {
    yield put({
      type: ERROR_VERIFY_DOMAIN,
      message: 'Verification failed' || response.message,
      data: response.data,
    });
  }
}

export function* verifyDomainWatcher() {
  yield takeLatest(VERIFY_DOMAIN, verifyDomainWorker);
}

function* clickupCompleteAuthWorker(action) {
  const { paramObj } = action;
  const response = yield call(clickupCompleteAuth, paramObj);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_COMPLETE_AUTH,
      teams: response.data,
    });
    yield put({
      type: CLICKUP_GET_WORKSPACES,
    });
  } else {
    console.error('ClickUp Authentication complete!', response);
  }
}

export function* clickupCompleteAuthWatcher() {
  yield takeLatest(CLICKUP_COMPLETE_AUTH, clickupCompleteAuthWorker);
}

function* clickupGetTeamsWorker() {
  const response = yield call(clickupGetTeams);

  if (response.status) {
    // select the first team
    const teams = response.data.map((team, index) =>
      index === 0
        ? {
            ...team,
            selected: true,
          }
        : team
    );
    yield put({
      type: SUCCESS_CLICKUP_GET_TEAMS,
      teams: teams,
      message: 'Teams fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Teams failed!', response.message);
    // yield put({
    //     type: ERROR_CLICKUP_GET_TEAMS,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* clickupGetTeamsWatcher() {
  yield takeLatest(CLICKUP_GET_TEAMS, clickupGetTeamsWorker);
}

function* clickupStoreTeamWorker(action) {
  const { paramObj } = action;
  const response = yield call(clickupStoreTeam, paramObj);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_STORE_TEAM,
      message: 'Team saved successfully!' || response.message,
      team_id: paramObj.team_id,
    });
    yield put({
      type: CLICKUP_GET_WORKSPACES,
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_STORE_TEAM,
      message: 'Team saving failed' || response.message,
    });
  }
}

export function* clickupStoreTeamWatcher() {
  yield takeLatest(CLICKUP_STORE_TEAM, clickupStoreTeamWorker);
}

function* clickupGetWorkspacesWorker() {
  const response = yield call(clickupGetWorkspaces);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_GET_WORKSPACES,
      workspaces: response.data,
      message: 'Workspaces fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Workspaces failed!', response.message);
    // yield put({
    //     type: ERROR_CLICKUP_GET_WORKSPACES,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* clickupGetWorkspacesWatcher() {
  yield takeLatest(CLICKUP_GET_WORKSPACES, clickupGetWorkspacesWorker);
}

function* asanaGetWorkspacesWorker() {
  const response = yield call(asanaGetWorkspaces);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_GET_WORKSPACES,
      workspaces: response.data,
      message: 'Workspaces fetched successfully!' || response.message,
    });
  } else {
    console.error('Asana Get Workspaces failed!', response.message);
    // yield put({
    //     type: ERROR_CLICKUP_GET_WORKSPACES,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* asanaGetWorkspacesWatcher() {
  yield takeLatest(ASANA_GET_WORKSPACES, asanaGetWorkspacesWorker);
}

function* asanaGetTeamWorker() {
  const response = yield call(asanaGetTeam);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_GET_TEAM,
      teams: response.data,
      message: 'Teams fetched successfully!' || response.message,
    });
  } else {
    console.error('Asana Get Teams failed!', response.message);
    // yield put({
    //     type: ERROR_CLICKUP_GET_WORKSPACES,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* asanaGetTeamWatcher() {
  yield takeLatest(ASANA_GET_TEAM, asanaGetTeamWorker);
}

function* clickupStoreWorkspaceWorker(action) {
  const { paramObj } = action;
  const response = yield call(clickupStoreWorkspace, paramObj);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_STORE_WORKSPACE,
      message: 'Workspace saved successfully!' || response.message,
      space_id: paramObj.space_id,
    });
    yield put({
      type: CLICKUP_GET_FOLDERS_SETTINGS,
      params: 2,
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_STORE_WORKSPACE,
      message: 'Workspace saving failed' || response.message,
    });
  }
}

export function* clickupStoreWorkspaceWatcher() {
  yield takeLatest(CLICKUP_STORE_WORKSPACE, clickupStoreWorkspaceWorker);
}

function* asanaStoreWorkspaceWorker(action) {
  const { paramObj } = action;
  const response = yield call(asanaStoreWorkspace, paramObj);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_STORE_WORKSPACE,
      message: 'Workspace saved successfully!' || response.message,
      workspace_id: paramObj.workspace_id,
    });

    const asanaTeam = yield call(asanaGetTeam);
    if (asanaTeam.status) {
      yield put({
        type: SUCCESS_ASANA_GET_TEAM,
        teams: asanaTeam.data,
        message: 'Teams fetched successfully!' || response.message,
      });
    }
  } else {
    yield put({
      // type: ERROR_ASANA_STORE_WORKSPACE,
      message: 'Workspace saving failed' || response.message,
    });
  }
}

export function* asanaStoreWorkspaceWatcher() {
  yield takeLatest(ASANA_STORE_WORKSPACE, asanaStoreWorkspaceWorker);
}

function* asanaStoreTeamsWorker(action) {
  const { paramObj } = action;
  const response = yield call(asanaStoreTeams, paramObj);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_STORE_TEAMS,
      message: 'Teams saved successfully!' || response.message,
      team_id: paramObj.team_id,
    });
    yield put({
      type: ASANA_GET_PROJECTS_SETTINGS,
      params: 3,
    });
  } else {
    yield put({
      // type: ERROR_ASANA_STORE_WORKSPACE,
      message: 'Workspace saving failed' || response.message,
    });
  }
}

export function* asanaStoreTeamsWatcher() {
  yield takeLatest(ASANA_STORE_TEAMS, asanaStoreTeamsWorker);
}

function* jiraGetWorkspacesWorker() {
  const response = yield call(jiraGetWebsites);

  if (response.status) {
    yield put({
      type: SUCCESS_JIRA_GET_WEBSITES,
      websites: response.data,
      message: 'Projects fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Workspaces failed!', response.message);
    // yield put({
    //     type: ERROR_CLICKUP_GET_WORKSPACES,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* jiraGetWorkspacesWatcher() {
  yield takeLatest(JIRA_GET_WEBSITES, jiraGetWorkspacesWorker);
}

function* jiraStoreWebsites(action) {
  const { paramObj } = action;
  const response = yield call(jiraStoreWebsite, paramObj);
  if (response.status) {
    yield put({
      type: SUCCESS_JIRA_STORE_WEBSITES,
      message: 'Projects saved successfully!' || response.message,
    });
    yield put({
      type: JIRA_GET_PROJECTS_SETTINGS,
      params: 4,
    });
  } else {
    yield put({
      // type: ERROR_ASANA_STORE_WORKSPACE,
      message: 'Projects saving failed' || response.message,
    });
  }
}

export function* jiraStoreWebsitesWatcher() {
  yield takeLatest(JIRA_STORE_WEBSITES, jiraStoreWebsites);
}

function* clickupGetFoldersWorker(action) {
  const { params } = action;
  const response = yield call(clickupGetFoldersSettings, params);

  if (response.status) {
    // select the first folder
    const check = response.data.filter((task) => task.selected === true);
    let folders = [];
    if (check.length === 0) {
      folders = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      folders = response.data;
    }

    yield put({
      type: SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS,
      folders: folders,
      message: 'Folders fetched successfully!' || response.message,
    });
    // fetch the task list
    if (response.data.length > 0) {
      yield put({
        type: CLICKUP_GET_TASK_LIST_SETTINGS,
        params: {
          folder_id: folders.filter((task) => task.selected === true)[0].id,
        },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* clickupGetFoldersSettingsWatcher() {
  yield takeLatest(CLICKUP_GET_FOLDERS_SETTINGS, clickupGetFoldersWorker);
}

function* clickupGetTaskListWorkerSetting(action) {
  const { params } = action;
  const response = yield call(clickupGetTaskListSetting, params);

  if (response.status) {
    // select the first list

    const check = response.data.filter((task) => task.selected === true);
    let lists = [];
    if (check.length === 0) {
      lists = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      lists = response.data;
    }

    yield put({
      type: SUCCESS_CLICKUP_GET_TASK_LIST_SETTING,
      taskList: lists,
      message: 'Task list fetched successfully!' || response.message,
    });

    yield put({
      type: UPDATE_FOLDER_SELECTED_SETTINGS,
      folderId: params.folder_id,
    });
  } else {
    console.error('ClickUp Get Task List failed!', response.message);
  }
}

export function* clickupGetTaskListSettingWatcher() {
  yield takeLatest(
    CLICKUP_GET_TASK_LIST_SETTINGS,
    clickupGetTaskListWorkerSetting
  );
}

function* storeIntegrationRequirementWorker(action) {
  const { params } = action;
  const response = yield call(storeIntegrationRequirement, params);

  if (response.status) {
    yield put({
      type: SUCCESS_STORE_INTEGRATION_REQUIREMENT,
      message: 'Integration saved successfully!' || response.message,
    });
  } else {
    yield put({
      // type: ERROR_ASANA_STORE_WORKSPACE,
      message: 'Integration saving failed' || response.message,
    });
  }
}

export function* storeIntegrationRequirementWatcher() {
  yield takeLatest(
    STORE_INTEGRATION_REQUIREMENT,
    storeIntegrationRequirementWorker
  );
}

function* asanaGetProjectssWorker(action) {
  const { params } = action;
  const response = yield call(asanaGetProjectsSettings, params);

  if (response.status) {
    // select the first folder
    const check = response.data.filter((task) => task.selected === true);
    let Projects = [];
    if (check.length === 0) {
      Projects = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      Projects = response.data;
    }

    yield put({
      type: SUCCESS_ASANA_GET_PROJECTS_SETTINGS,
      Projects: Projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    if (Projects.length > 0) {
      yield put({
        type: ASANA_GET_SECTION_SETTINGS,
        params: {
          project_id: Projects.filter((task) => task.selected === true)[0].id,
        },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* asanaGetProjectsSettingsWatcher() {
  yield takeLatest(ASANA_GET_PROJECTS_SETTINGS, asanaGetProjectssWorker);
}

function* asanaGetSectionWorkerSetting(action) {
  const { params } = action;
  const response = yield call(asanaGetSectionSetting, params);

  if (response.status) {
    // select the first list
    const check = response.data.filter((task) => task.selected === true);
    let lists = [];
    if (check.length === 0) {
      lists = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      lists = response.data;
    }

    yield put({
      type: SUCCESS_ASANA_GET_SECTION_SETTINGS,
      sections: lists,
      message: 'Sections fetched successfully!' || response.message,
    });

    yield put({
      type: UPDATE_PROJECT_SELECTED_SETTINGS,
      projectId: params.project_id,
    });
  } else {
    console.error('asana Get Task List failed!', response.message);
  }
}

export function* asanaGetSectionSettingWatcher() {
  yield takeLatest(ASANA_GET_SECTION_SETTINGS, asanaGetSectionWorkerSetting);
}

function* teamworkGetProjectsWorker(action) {
  const { params } = action;
  const response = yield call(teamworkGetProjectsSettings, params);

  if (response.status) {
    // select the first folder
    const check = response.data.filter((task) => task.selected === true);
    let Projects = [];
    if (check.length === 0) {
      Projects = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      Projects = response.data;
    }

    yield put({
      type: SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS,
      Projects: Projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    if (Projects.length > 0) {
      yield put({
        type: TEAMWORK_GET_TASKLIST_SETTINGS,
        params: {
          project_id: Projects.filter((task) => task.selected === true)[0].id,
        },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* teamworkGetProjectsSettingsWatcher() {
  yield takeLatest(TEAMWORK_GET_PROJECTS_SETTINGS, teamworkGetProjectsWorker);
}

function* teamworkGetTaskListWorkerSetting(action) {
  const { params } = action;
  const response = yield call(teamworkGetTaskListSetting, params.project_id, 5);

  if (response.status) {
    // select the first list
    const check = response.data.filter((task) => task.selected === true);
    let lists = [];
    if (check.length === 0) {
      lists = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      lists = response.data;
    }

    yield put({
      type: SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS,
      taskLists: lists,
      message: 'Tasklist fetched successfully!' || response.message,
    });

    // fetch the tasks
    // const obj = {
    //     task_list_id: lists.filter(l => l.selected)[0].id
    // }
    yield put({
      type: UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS,
      projectId: params.project_id,
    });
  } else {
    console.error('asana Get Task List failed!', response.message);
  }
}

export function* teamworkGetTaskListSettingWatcher() {
  yield takeLatest(
    TEAMWORK_GET_TASKLIST_SETTINGS,
    teamworkGetTaskListWorkerSetting
  );
}

function* jiraGetProjectsWorker(action) {
  const { params } = action;
  const response = yield call(jiraGetProjectsSettings, params);

  if (response.status) {
    // select the first folder
    const Projects = response.data.map((folder, index) =>
      index === 0
        ? {
            ...folder,
            selected: true,
          }
        : folder
    );
    yield put({
      type: SUCCESS_JIRA_GET_PROJECTS_SETTINGS,
      Projects: Projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    // if(Projects.length > 0) {
    //     yield put({
    //         type: TEAMWORK_GET_TASKLIST_SETTINGS,
    //         params: { "project_id": Projects[0].id }
    //     })
    // }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* jiraGetProjectsSettingsWatcher() {
  yield takeLatest(JIRA_GET_PROJECTS_SETTINGS, jiraGetProjectsWorker);
}

function* changeMainwpSettingWorker(action) {
  const { obj } = action;
  const data = yield call(changeMainwpSetting, obj);

  if (data.status === true) {
    yield put({
      type: SUCCESS_CHANGE_MAINWP_SETTINGS,
      message: data?.message || 'Main settings changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_MAINWP_SETTINGS,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeMainWpSettingWatcher() {
  yield takeLatest(START_CHANGE_MAINWP_SETTINGS, changeMainwpSettingWorker);
}

function* connectRocketWorker(action) {
  const { obj } = action;
  const data = yield call(connectRocketSetting, obj);

  if (data.status === true) {
    yield put({
      type: SUCCESS_CONNECT_ROCKET_SETTING,
      status: data.status,
      message: data?.message || 'Rocket settings connection was successful!',
    });
  } else {
    yield put({
      type: ERROR_CONNECT_ROCKET_SETTING,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* connectRocketWatcher() {
  yield takeLatest(START_CONNECT_ROCKET_SETTING, connectRocketWorker);
}

function* basecampGetProjectsWorker(action) {
  const { params } = action;
  const response = yield call(basecampGetProjectsSettings, params);

  if (response.status) {
    // select the first folder
    const check = response.data.filter((task) => task.selected === true);
    let Projects = [];

    if (check.length === 0) {
      Projects = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      Projects = response.data;
    }

    yield put({
      type: SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS,
      Projects: Projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    if (Projects.length > 0) {
      yield put({
        type: BASECAMP_GET_SECTION_SETTINGS,
        params: {
          project_id: Projects.filter(
            (task) => task.selected === true
          )[0].id.toString(),
          todo_set_id: Projects.filter(
            (task) => task.selected === true
          )[0].todo_set_id.toString(),
        },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* basecampGetProjectsSettingsWatcher() {
  yield takeLatest(BASECAMP_GET_PROJECTS_SETTINGS, basecampGetProjectsWorker);
}

function* basecampGetTaskWorkerSetting(action) {
  const { params } = action;
  const response = yield call(basecampGetTaskSetting, params);

  if (response.status) {
    // select the first list
    const check = response.data.filter((task) => task.selected === true);
    let lists = [];
    if (check.length === 0) {
      lists = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      lists = response.data;
    }

    yield put({
      type: SUCCESS_BASECAMP_GET_SECTION_SETTINGS,
      task: lists,
      message: 'Tasks fetched successfully!' || response.message,
    });

    yield put({
      type: UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS,
      projectId: params.project_id,
    });
  } else {
    console.error('basecamp Get Task List failed!', response.message);
  }
}

export function* basecampGetTaskSettingWatcher() {
  yield takeLatest(BASECAMP_GET_SECTION_SETTINGS, basecampGetTaskWorkerSetting);
}

function* trelloGetProjectssWorker(action) {
  const { params } = action;
  const response = yield call(trelloGetProjectsSettings, params);
  if (response.status) {
    // select the first folder
    const check = response.data.filter((task) => task.selected === true);
    let Projects = [];
    if (check.length === 0) {
      Projects = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      Projects = response.data;
    }

    yield put({
      type: SUCCESS_TRELLO_GET_PROJECTS_SETTINGS,
      Projects: Projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    if (Projects.length > 0) {
      yield put({
        type: TRELLO_GET_LIST_SETTINGS,
        params: {
          board_id: Projects.filter((task) => task.selected === true)[0].id,
        },
      });
    }
  } else {
    console.error('Trello Get Board failed!', response.message);
  }
}
export function* trelloGetProjectsSettingsWatcher() {
  yield takeLatest(TRELLO_GET_PROJECTS_SETTINGS, trelloGetProjectssWorker);
}
function* trelloGetListWorkerSetting(action) {
  const { params } = action;
  const response = yield call(trelloGetListSetting, params);
  if (response.status) {
    // select the first list
    const check = response.data.filter((task) => task.selected === true);
    let lists = [];
    if (check.length === 0) {
      lists = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      lists = response.data;
    }

    yield put({
      type: SUCCESS_TRELLO_GET_LIST_SETTINGS,
      sections: lists,
      message: 'List fetched successfully!' || response.message,
    });
    yield put({
      type: UPDATE_TRELLO_BOARD_SELECTED_SETTINGS,
      projectId: params.board_id,
    });
  } else {
    console.error('asana Get Task List failed!', response.message);
  }
}
export function* trelloGetListSettingWatcher() {
  yield takeLatest(TRELLO_GET_LIST_SETTINGS, trelloGetListWorkerSetting);
}

function* changeInstawpSettingWorker(action) {
  const { obj } = action;
  const data = yield call(changeInstawpSetting, obj);

  if (data.status === true) {
    yield put({
      type: SUCCESS_CHANGE_INSTAWP_SETTINGS,
      message: data?.message || 'InstaWP API key changed successfully!',
    });
  } else {
    yield put({
      type: ERROR_CHANGE_INSTAWP_SETTINGS,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeInstaWpSettingWatcher() {
  yield takeLatest(START_CHANGE_INSTAWP_SETTINGS, changeInstawpSettingWorker);
}

function* createSnippetWorker(action) {
  const { obj } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const data = yield call(createSnippetSetting, obj, selectedWorkspace?.id);

  if (data.status === 200) {
    yield put({
      type: SUCCESS_CREATE_SNIPPET,
      data: data.result,
      message: data?.message || 'New snippet has been created successfully!',
    });
    yield put({
      type: NEW_SNIPPET_SELECTED,
      snippetId: data.result.id,
    });
  } else {
    if (data?.data) {
      yield put({
        type: ERROR_CREATE_SNIPPET,
        message: data?.data?.message || ApiErrorFallbackMessage,
      });
    } else {
      yield put({
        type: ERROR_CREATE_SNIPPET,
        message: data?.message || ApiErrorFallbackMessage,
      });
    }
  }
}

export function* createSnippetWatcher() {
  yield takeLatest(START_CREATE_SNIPPET, createSnippetWorker);
}

function* fetchAllSnippetWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(fetchAllSnippetSetting, selectedWorkspace?.id);

  if (response.status === 200) {
    yield put({
      type: SUCCESS_FETCH_ALL_SNIPPET,
      message: response.message || 'Snippets fetched succesfully',
      data: response.result,
    });
    if (response.result.length > 0) {
      yield put({
        type: NEW_SNIPPET_SELECTED,
        snippetId: response.result[0]?.id,
      });
    }
  } else {
    yield put({
      type: ERROR_FETCH_ALL_SNIPPET,
      message: 'Error fetching Snippets' || response.message,
    });
  }
}
export function* fetchAllSnippetWatcher() {
  yield takeLatest(START_FETCH_ALL_SNIPPET, fetchAllSnippetWorker);
}

function* fetchSnippetDetailWorker(action) {
  const { snippetId } = action;
  const response = yield call(fetchSnippetDetailSetting, snippetId);
  if (response.status === 200) {
    yield put({
      type: SUCCESS_NEW_SNIPPET_SELECTED,
      message: response.message || 'Snippet detail fetched succesfully',
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_NEW_SNIPPET_SELECTED,
      message: 'Error fetching snippet detail' || response.message,
    });
  }
}
export function* fetchSnippetDetailWatcher() {
  yield takeLatest(NEW_SNIPPET_SELECTED, fetchSnippetDetailWorker);
}

function* deleteSnippetWorker(action) {
  const { obj } = action;
  const response = yield call(deleteSnippetSetting, obj);
  if (response.status === 200) {
    yield put({
      type: SUCCESS_SNIPPET_DELETE,
      message: response.message || 'The snippet has been deleted',
    });
    yield put({
      type: START_FETCH_ALL_SNIPPET,
    });
  } else {
    yield put({
      type: ERROR_SNIPPET_DELETE,
      message: 'Error fetching snippet detail' || response.message,
    });
  }
}
export function* deleteSnippetWatcher() {
  yield takeLatest(START_SNIPPET_DELETE, deleteSnippetWorker);
}

function* updateSnippetWorker(action) {
  const { obj } = action;
  const data = yield call(updateSnippetSetting, obj);

  if (data.status === 200) {
    yield put({
      type: SUCCESS_UPDATE_SNIPPET,
      data: data.result,
      message: data?.message || 'The snippet has been updated successfully!',
    });
  } else {
    yield put({
      type: ERROR_UPDATE_SNIPPET,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* updateSnippetWatcher() {
  yield takeLatest(START_UPDATE_SNIPPET, updateSnippetWorker);
}

function* createSnippetTemplateWorker(action) {
  const { obj } = action;
  const data = yield call(createSnippetTemplateSetting, obj);

  if (data.status === 200) {
    yield put({
      type: SUCCESS_CREATE_SNIPPET_TEMPLATE,
      data: data.result,
      message: data?.message || 'New snippet has been created successfully!',
    });
  } else {
    yield put({
      type: ERROR_CREATE_SNIPPET_TEMPLATE,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* createSnippetTemplateWatcher() {
  yield takeLatest(START_CREATE_SNIPPET_TEMPLATE, createSnippetTemplateWorker);
}

function* fetchAllSnippetTemplateWorker() {
  const response = yield call(fetchAllSnippetTemplateSetting);

  if (response.status === 200) {
    yield put({
      type: SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE,
      message: response.message || 'Snippets fetched succesfully',
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ALL_SNIPPET_TEMPLATE,
      message: 'Error fetching Snippets Template' || response.message,
    });
  }
}
export function* fetchAllSnippetTemplateWatcher() {
  yield takeLatest(
    START_FETCH_ALL_SNIPPET_TEMPLATE,
    fetchAllSnippetTemplateWorker
  );
}

function* importSnippetTemplateWorker(action) {
  const { obj } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const response = yield call(
    importSnippetTemplateSetting,
    obj,
    selectedWorkspace?.id
  );
  if (response.status === 200) {
    yield put({
      type: SUCCESS_IMPORT_SNIPPET_TEMPLATE,
      message: response.message || 'Snippet detail fetched succesfully',
      // data: response.result
    });
    yield put({
      type: START_FETCH_ALL_SNIPPET,
    });
  } else {
    yield put({
      type: ERROR_IMPORT_SNIPPET_TEMPLATE,
      message: 'Error fetching snippet detail' || response.message,
    });
  }
}
export function* importSnippetTemplateWatcher() {
  yield takeLatest(IMPORT_SNIPPET_TEMPLATE, importSnippetTemplateWorker);
}

function* mondayGetWorkspacesWorker() {
  const response = yield call(mondayGetWorkspace);

  if (response.status) {
    yield put({
      type: SUCCESS_MONDAY_GET_WORKSPACE,
      workspace: response.data,
      message: 'Workspace fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Workspaces failed!', response.message);
    // yield put({
    //     type: SUCCESS_MONDAY_GET_WORKSPACE,
    //     message: "Teams fetching failed" || response.message
    // });
  }
}

export function* mondayGetWorkspacesWatcher() {
  yield takeLatest(MONDAY_GET_WORKSPACE, mondayGetWorkspacesWorker);
}

function* mondayStoreWorkspace(action) {
  const { paramObj } = action;
  const response = yield call(mondayStorespace, paramObj);
  if (response.status) {
    yield put({
      type: SUCCESS_MONDAY_STORE_WORKSPACE,
      message: 'Workspace saved successfully!' || response.message,
    });
    yield put({
      type: MONDAY_GET_BOARD_SETTINGS,
      params: 4,
    });
  } else {
    yield put({
      type: ERROR_MONDAY_STORE_WORKSPACE,
      message: 'Workspace saving failed' || response.message,
    });
  }
}

export function* mondayStoreWorkspaceWatcher() {
  yield takeLatest(MONDAY_STORE_WORKSPACE, mondayStoreWorkspace);
}

function* changeMondaySettingWorker(action) {
  const { obj } = action;
  const data = yield call(changeMondaySetting, obj);

  if (data.status === true) {
    yield put({
      type: SUCCESS_CHANGE_MONDAY_SETTINGS,
      message: data?.message || 'Main settings changed successfully!',
    });
    yield put({
      type: MONDAY_GET_WORKSPACE,
    });
  } else {
    yield put({
      type: ERROR_CHANGE_MONDAY_SETTINGS,
      message: data?.message || ApiErrorFallbackMessage,
    });
  }
}

export function* changeMondaySettingWatcher() {
  yield takeLatest(START_CHANGE_MONDAY_SETTINGS, changeMondaySettingWorker);
}

function* mondayGetBoardsWorker(action) {
  const { params } = action;
  const response = yield call(mondayGetBoardsSettings, params);

  if (response.status) {
    // select the first folder

    const check = response.data.filter((task) => task.selected === true);
    let boards = [];
    if (check.length === 0) {
      boards = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      boards = response.data;
    }

    yield put({
      type: SUCCESS_MONDAY_GET_BOARD_SETTINGS,
      board: boards,
      message: 'Boards fetched successfully!' || response.message,
    });
    // fetch the task list
    if (boards.length > 0) {
      yield put({
        type: MONDAY_GET_GROUP_SETTINGS,
        params: {
          board_id: boards.filter((task) => task.selected === true)[0].id,
        },
      });
    }
  } else {
    console.error('Monday Get Board failed!', response.message);
  }
}

export function* mondayGetBoardsSettingWatcher() {
  yield takeLatest(MONDAY_GET_BOARD_SETTINGS, mondayGetBoardsWorker);
}

function* mondayGetGroupWorkerSetting(action) {
  const { params } = action;
  const response = yield call(mondayGetGroupSetting, params);
  if (response.status) {
    // select the first list
    const check = response.data.filter((task) => task.selected === true);
    let groups = [];
    if (check.length === 0) {
      groups = response.data.map((folder, index) =>
        index === 0
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
    } else {
      groups = response.data;
    }

    yield put({
      type: SUCCESS_MONDAY_GET_GROUP_SETTINGS,
      group: groups,
      message: 'Groups fetched successfully!' || response.message,
    });
    yield put({
      type: UPDATE_MONDAY_BOARD_SELECTED_SETTINGS,
      projectId: params.board_id,
    });
  } else {
    console.error('Monday Get Task List failed!', response.message);
  }
}
export function* mondayGetGroupWorkerWatcher() {
  yield takeLatest(MONDAY_GET_GROUP_SETTINGS, mondayGetGroupWorkerSetting);
}

function* validateEmailWorker(action) {
  const { data } = action;
  const response = yield call(validateEmailInbox, data);

  if (response.status) {
    yield put({
      type: SUCCESS_VALIDATE_EMAIL_INBOX,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_VALIDATE_EMAIL_INBOX,
    });
  }
}

export function* validateEmailWatcher() {
  yield takeLatest(VALIDATE_EMAIL_INBOX, validateEmailWorker);
}
