import React, { useEffect, useRef, useState } from 'react';
// Styling
import { Avatar, Button, Grid, makeStyles } from '@material-ui/core';
import AvatarUser from 'react-avatar';
import { BiLink } from 'react-icons/bi';
import SelectedIcon from 'media/svgs/marked-purple.svg?react';
// Utils
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import validUrl from 'valid-url';
import { urlBoxFetchImage } from 'utils/constants';
import { reactAvatarInitials } from 'utils/functions';
import GotoChromeWideWidget from '../GotoChromeWideWidget';

const makeClasses = makeStyles((theme) => ({
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  selectedCompanyTypeItem: {
    outline: '2px solid #6D5DF3',
  },
  webBoxPD: {
    padding: '10px !important',
    opacity: 0.9,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
    position: 'relative',
  },
  userAvatar: {
    display: 'inline-flex',
    top: '70%',
    fontSize: 12,
    fontFamily: theme.text.boldText.fontFamily,
    background: '#FFF',
    borderRadius: 8,
  },
  usersAvatar: {
    backgroundColor: '#FFF',
    width: '45px',
    height: '45px',
    borderRadius: 8,
    '& .sb-avatar__text ': {
      position: 'initial',
    },
  },
  summaryHeader: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#6D5DF3',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: '8px',
  },
  summaryCopy: {
    fontSize: 14,
    fontFamily: theme.text.normalText.fontFamily,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'left',
    marginTop: 15,
  },
  selectedIcon: {
    position: 'absolute',
    bottom: 5,
    right: 6,
    overflow: 'visible',
  },
  titleCopyTeam: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'left',
  },
}));

const CompleteUpgrade = (props) => {
  const classes = makeClasses();
  const {
    teamMemberData,
    websiteData,
    handleUpdateTrial,
    adminId,
    addNewSite,
    userId,
  } = props;

  const thumbnailRef = useRef();
  const { selectedWorkspace } = useSelector((state) => state.workspace);

  const [copied, setCopied] = useState(false);
  const [websites, setWebsites] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = React.useState('');

  const websitePlaceHolder = [
    {
      name: 'https://www.apple.com',
      src: 'https://atarim-us.s3.us-east-2.amazonaws.com/media/trial-wizard-assets/apple.png',
    },
    {
      name: 'https://www.teamwork.com',
      src: 'https://wpfeedback-image.s3.us-east-2.amazonaws.com/media/teamwork-wizard.png',
    },
    {
      name: 'https://www.slack.com',
      src: 'https://atarim-us.s3.us-east-2.amazonaws.com/media/trial-wizard-assets/slack.png',
    },
  ];

  useEffect(() => {
    setWebsites(websiteData);
  }, [websiteData]);

  useEffect(() => {
    if (teamMemberData.length > 0) {
      if (
        teamMemberData.length === 1 &&
        (teamMemberData[0]?.email === '' || !teamMemberData[0])
      ) {
        setTeams([]);
      } else {
        setTeams(teamMemberData);
      }
    }
  }, [teamMemberData]);

  const handleImageError = () => {
    // set the placeholder image
    thumbnailRef.current.src = '/assets/broken-image.png';
  };

  const handeCloseWizard = () => {
    handleUpdateTrial('status', 'active');
  };

  const handleCopyShareLink = (link, invitedUserId, value) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleSelect = (value) => {
    if (selectedCompanyType === '') {
      addNewSite({
        url: value,
        user_id: userId,
        fromWizard: true,
      });
      setSelectedCompanyType(value);
    }
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className="at-cs-trial-wizard-projects-header">
          {websites.length === 0 ? "Let's Add Some Websites" : 'Your Projects'}
        </div>
        {websites.length === 0 && (
          <div className={classes.titleCopy}>
            We noticed you didn't add any websites, it's super easy; click one
            below to quickly add it and experience the power of Atarim's visual
            collaboration.
          </div>
        )}
        <div className="at-cs-trial-wizard-projects-container">
          {websites.length === 0 ? (
            <>
              {websitePlaceHolder.map((item, index) => {
                return (
                  item && (
                    <Grid
                      item
                      className={classes.webBoxPD}
                      sm={4}
                      md={4}
                      xs={12}
                    >
                      <div
                        className={`at-cs-trial-wizard-modal-project-item-container ${
                          selectedCompanyType.includes(item.name)
                            ? classes.selectedCompanyTypeItem
                            : ''
                        }`}
                        onClick={() => handleSelect(item.name)}
                      >
                        <div className="at-cs-trial-wizard-modal-project-image">
                          {!validUrl.isUri(item.src) && (
                            <img
                              className={classes.websiteImage}
                              onError={handleImageError}
                              src={item.src}
                              alt=""
                            />
                          )}
                          {validUrl.isUri(item.src) && (
                            <img
                              ref={thumbnailRef}
                              className={classes.websiteImage}
                              onError={handleImageError}
                              src={item.src}
                              alt=""
                            />
                          )}
                          <div className="at-cs-trial-wizard-modal-project-url">
                            {' '}
                            {item.name}
                          </div>
                          {/* <img src={item.src} alt={item.alt} /> */}
                          {selectedCompanyType.includes(item.name) && (
                            <SelectedIcon className={classes.selectedIcon} />
                          )}
                        </div>
                      </div>
                    </Grid>
                  )
                );
              })}
            </>
          ) : (
            websites &&
            websites.map((item) => {
              return (
                item && (
                  <Grid item className={classes.webBoxPD} sm={4} md={4} xs={12}>
                    <div className="at-cs-trial-wizard-modal-project-item-container">
                      <div className="at-cs-trial-wizard-modal-project-image">
                        {!validUrl.isUri(urlBoxFetchImage + item) && (
                          <img
                            className={classes.websiteImage}
                            onError={handleImageError}
                            src={'/assets/BG-pattern.png'}
                            alt=""
                          />
                        )}
                        {validUrl.isUri(urlBoxFetchImage + item) && (
                          <img
                            ref={thumbnailRef}
                            className={classes.websiteImage}
                            onError={handleImageError}
                            src={urlBoxFetchImage + item}
                            alt=""
                          />
                        )}
                        <div className="at-cs-trial-wizard-modal-project-url">
                          {' '}
                          {item}
                        </div>
                      </div>
                    </div>
                  </Grid>
                )
              );
            })
          )}
        </div>
        <div className="at-cs-trial-wizard-team-member-header">
          {teams.length === 0
            ? "Let's Add Some Team Members"
            : "Let's Get Your Team In"}
        </div>
        <div className="at-cs-trial-wizard-team-member-container">
          {teams.length === 0 ? (
            <div className={classes.titleCopyTeam}>
              It looks like you haven't invited any team members yet - It's a
              breeze – just share the link below! Dive into the world of visual
              collaboration with your team and witness firsthand how it
              effortlessly enhances your work.
            </div>
          ) : (
            teams &&
            teams.map((user, index) => {
              return (
                user.email && (
                  <Grid item className={classes.webBoxPD} sm={4} md={4} xs={12}>
                    <div className="at-cs-trial-wizard-complete-user-box">
                      {user?.avatar ? (
                        <Avatar
                          key={user.email}
                          src={user?.avatar}
                          title={user.email}
                          className={classes.userAvatar}
                        />
                      ) : (
                        <AvatarUser
                          key={index}
                          title={user.email}
                          email={user.email}
                          name={reactAvatarInitials(user.email || '')}
                          size="45px"
                          className={classes.usersAvatar}
                        />
                      )}
                      <div className="at-cs-trial-wizard-complete-user-data">
                        <div className="at-cs-trial-wizard-complete-user-data-user">
                          {user.email}
                        </div>
                        <div className="at-cs-trial-wizard-complete-user-data-role">
                          {user.role}
                        </div>
                      </div>
                    </div>
                  </Grid>
                )
              );
            })
          )}
        </div>
        <div className="at-cs-trial-wizard-complete-share">
          You Can Share This Link with Your Team Members
        </div>
        <div className="copy_url_notification">
          <div className="at-cs-trial-wizard-complete-share-link-container">
            <div className="at-cs-trial-wizard-complete-share-link-section">
              <span className="at-cs-trial-wizard-complete-share-link-icon">
                <BiLink size={20} color={'#8791B2'} />
              </span>
              <div className="at-cs-trial-wizard-complete-share-link-button-container">
                <span className="at-cs-trial-wizard-complete-share-link-text-content">
                  {`${window.location.protocol}//${window.location.host}/register?admin_id=${adminId}&workspace_id=${selectedWorkspace?.id}`}
                </span>
                <span
                  className="at-cs-trial-wizard-complete-share-link-copy-button"
                  // add the current page id to this url
                  onClick={() =>
                    handleCopyShareLink(
                      `${window.location.protocol}//${window.location.host}/register?admin_id=${adminId}&workspace_id=${selectedWorkspace?.id}`,
                      undefined,
                      true
                    )
                  }
                >
                  {copied ? 'Copied' : 'Copy'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <GotoChromeWideWidget />
        {/* <div className="at-cs-trial-wizard-complete-summary-container">
                    <div className="at-cs-trial-wizard-complete-summary-copy-wrapper">
                        <div className={classes.summaryHeader}>You’ve unlocked a 14 day trial of Atarim Premium</div>
                        <div className={classes.summaryCopy}>
                            For 14 days you can try all of our premium features for free, including all integrations, time tracking, an advanced form system and more!
                        </div>
                    </div>
                    <img className="at-cs-trial-wizard-complete-summary-image" src={imgAtarimWorkflow} alt="" />
                </div> */}

        <div className="at-cs-trial-wizard-complete-button-container">
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.nextBtn, classes.boldText) }}
            onClick={() => handeCloseWizard()}
          >
            Start Collaborating Visually
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompleteUpgrade;
