import React, {
  Suspense,
  createContext,
  lazy,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ResponsiveDialog } from 'ui/components';
import ResponsiveDialogProvider from 'ui/components/ResponsiveDialog/responsive-dialog-provider';

const MODAL_COMPONENTS = {
  email_settings: lazy(
    () => import('../components/inbox-v2/InboxSettingsModal')
  ),
  export_tasks: lazy(() => import('../components/ExportTasksModal')),
  pull_monday: lazy(
    () => import('../components/integrations/pull/MondayModal')
  ),
  pull_trello: lazy(
    () => import('../components/integrations/pull/TrelloModal')
  ),
  pull_basecamp: lazy(
    () => import('../components/integrations/pull/BaseCampModal')
  ),
  pull_teamwork: lazy(
    () => import('../components/integrations/pull/TeamworkModal')
  ),
  pull_jira: lazy(() => import('../components/integrations/pull/JiraModal')),
  pull_asana: lazy(() => import('../components/integrations/pull/AsanaModal')),
  pull_clickup: lazy(
    () => import('../components/integrations/pull/ClickUpModal')
  ),
  log_time: lazy(() => import('../components/LogActiveTimeModal')),

  // Add other modals here as needed
} as const;

type ModalName = keyof typeof MODAL_COMPONENTS;

type ModalProps = {
  [K in ModalName]: Omit<
    React.ComponentProps<(typeof MODAL_COMPONENTS)[K]>,
    'onClose'
  >;
};

// Define the shape of our modal context
interface ModalContextType {
  openModal: <T extends ModalName>(name: T, props?: ModalProps[T]) => void;
  closeModal: (name: ModalName) => void;
  isOpen: (name: ModalName) => boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [openModals, setOpenModals] = useState<Partial<ModalProps>>({});

  const openModal = useCallback(
    <T extends ModalName>(name: T, props?: ModalProps[T]) => {
      setOpenModals((prev) => ({ ...prev, [name]: props }));
    },
    []
  );

  const closeModal = useCallback((name: ModalName) => {
    setOpenModals((prev) => {
      const newModals = { ...prev };
      delete newModals[name];
      return newModals;
    });
  }, []);

  const isOpen = useCallback(
    (name: ModalName) => !!openModals[name],
    [openModals]
  );

  return (
    <ModalContext.Provider value={{ openModal, closeModal, isOpen }}>
      <ResponsiveDialogProvider>
        {children}
        {(Object.entries(openModals) as [ModalName, any][]).map(
          ([name, props]) => {
            const ModalComponent = MODAL_COMPONENTS[name];
            return ModalComponent ? (
              <ResponsiveDialog
                key={name}
                open={true}
                onOpenChange={() => closeModal(name)}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <ModalComponent {...props} onClose={() => closeModal(name)} />
                </Suspense>
              </ResponsiveDialog>
            ) : null;
          }
        )}
      </ResponsiveDialogProvider>
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useModal = <T extends ModalName>(name: T) => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return {
    open: (props?: ModalProps[T]) => context.openModal(name, props),
    close: () => context.closeModal(name),
    isOpen: () => context.isOpen(name),
  };
};
