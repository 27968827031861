import React, { useEffect, useState } from 'react';
// Components
import PlusIcon from '../PlusIcon';
// Styling
import {
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
// Utils
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  BorderLinearProgress,
  InviteRoleLabelFrontendWizard,
} from 'utils/constants';
import GotoChromeWideWidget from '../GotoChromeWideWidget';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 5,
    alignItems: 'center',
  },
  formsContainer: {
    maxHeight: '40vh',
    overflow: 'auto',
  },
  form: {
    display: 'flex',
    padding: 5,
    border: '1px solid #EFF2F6',
    borderRadius: 5,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  fields: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formActions: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  textField: {
    margin: 5,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      '& label, input': {
        fontSize: 13,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E72D67' /* Set your desired error border color */,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#E72D67' /* Set your desired error label color */,
    },
  },
  fieldsGroup: {
    display: 'flex',
    alignItems: 'baseline',
  },
  actionButton: {
    minWidth: 36,
    width: 36,
    height: 36,
    borderRadius: '50%',
    color: '#8791B2',
  },
  removeActionButton: {
    '& path': {
      d: `path("M 19 13 H 5 v -3 h 14 v 2 Z")`,
    },
  },

  skipBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    border: 'none',
    color: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
  },
  margin: {
    '& .MuiSelect-select.MuiSelect-select': {
      display: 'flex',
    },
  },
  progressContainer: {
    marginBottom: 20,
  },
  progressBack: {
    '&:before': {
      width: '7px',
      height: '7px',
      border: '2px solid #6D5DF3',
      borderWidth: '2px 2px 0 0',
      color: '#6D5DF3',
      display: 'inline-block',
      marginRight: '2px',
      content: '""',
      marginBottom: '1px',
      '-moz-transform': '"rotate(-135deg)"',
      '-webkit-transform': 'rotate(-135deg)',
      '-o-transform': '"rotate(-135deg)"',
      '-ms-transform': '"rotate(-135deg)"',
      transform: 'rotate(-135deg)',
      fontSize: 14,
      fontWeight: 600,
    },
  },
  descCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '10px 0px 10px 0px !important',
  },
  shareTitleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'left',
    margin: '20px 0px 10px 0px',
  },
  copyLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  linkSection: {
    border: '1px solid #EFF2F6',
    borderRadius: '6px',
    display: 'flex',
    width: '100%',
  },
  copyLinkIcon: {
    borderRight: '1px solid #EFF2F6',
    width: '8%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyLinkButton: {
    alignSelf: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: theme.text.normalText.fontFamily,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    border: 'none',
    borderRadius: 8,
    height: 36,
    maxHeight: 36,
    padding: '0 10px',
    backgroundColor: '#3ED696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  linkButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 5px 5px 10px',
  },
  copyLinkTextContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#A4ABC5',
    fontSize: 14,
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 500,
    lineHeight: '140%',
  },
}));

const InviteTeamUgreade = (props) => {
  const {
    handleNext,
    handlePrev,
    teamMemberData,
    handleUpdateTrial,
    handlePostTrial,
    adminId,
  } = props;

  const { selectedWorkspace } = useSelector((state) => state.workspace);

  const classes = makeClasses();

  const singleFormValues = {
    email: '',
    role: InviteRoleLabelFrontendWizard[0].value,
  };

  const [formValue, setFormValue] = useState([singleFormValues]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (teamMemberData) {
      if (teamMemberData.length > 0) {
        setFormValue(teamMemberData);
      }
    }
  }, [teamMemberData]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [copied]);

  const showRemoveFormButton = formValue.length > 1;

  const handleChange = (key, index) => (e) => {
    const value = e.target.value;
    // for optimization purpose React uses a single "e"
    // directly using e.target.value sometimes can lead to "e" being null or undefined which will cause error
    setFormValue((prevFormValue) =>
      prevFormValue.map((f, i) => (i === index ? { ...f, [key]: value } : f))
    );
  };

  const handleAddForm = (addAfterIndex) => () => {
    setFormValue((prevFormValue) => {
      const temp = prevFormValue.concat([]);
      temp.splice(addAfterIndex + 1, 0, singleFormValues);
      return temp;
    });
  };

  const handleRemoveForm = (index) => () => {
    setFormValue((prevFormValue) =>
      prevFormValue.filter((fv, i) => index !== i)
    );
  };

  const handleCheckIsvalid = () => {
    let status = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // return emailRegex.test(email);
    formValue.map((each) => {
      if (
        each.email === '' ||
        each.role === '' ||
        !emailRegex.test(each.email)
      ) {
        status = true;
      } else {
        status = false;
      }
    });
    return status;
  };

  const handleInviteUsers = (e) => {
    // handleUpdateTrial("team_members", formValue)
    handlePostTrial({
      team_members: formValue,
    });
    handleNext();
  };

  const handleSkip = (e) => {
    handlePostTrial({
      team_members: [],
    });
    handleNext();
  };

  const handleBlur = (key) => {
    handleUpdateTrial('team_members', formValue);
  };

  const handleKeyDown = (event, addAfterIndex) => {
    if (event.key === 'Enter') {
      // Perform the action you want when the Enter key is pressed
      setFormValue((prevFormValue) => {
        const temp = prevFormValue.concat([]);
        temp.splice(addAfterIndex + 1, 0, singleFormValues);
        return temp;
      });
    }
  };

  const handleCopyShareLink = (link, invitedUserId, value) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={66} />
          <div className="at-cs-trial-wizard-progress-label">
            <span className={classes.progressBack} onClick={handlePrev}>
              Back
            </span>
          </div>
        </div>
        <div className={classes.titleCopy}>Invite Your Team Members</div>
        <div className={classes.descCopy}>
          Consider managers and team members who regularly engage with web-based
          content, whether on a daily or weekly basis. Administrators have
          access to enhanced tools, offering them a management edge over other
          roles.
        </div>
        <div className={classes.formsContainer}>
          <div className="invite_form_notification">
            {formValue.map((fv, i) => (
              <>
                {
                  <div className={classes.form} key={i}>
                    <div className={'setInputHeight ' + classes.fields}>
                      <div className={classes.fieldsGroup}>
                        <TextField
                          label="Email Address"
                          placeholder="Email Address"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={fv.email}
                          type="email"
                          required
                          onChange={handleChange('email', i)}
                          onBlur={handleBlur}
                          onKeyDown={(e) => handleKeyDown(e, i)}
                          autoFocus={i === 0}
                          // error={formValue[i].email === "" ? true : false}
                          // error={addErrorToFields.last_name ? addErrorToFields.last_name.indexOf(i) !== -1 : false}
                        />
                        <FormControl
                          className={classes.margin}
                          classes={{ root: classes.textField }}
                          margin="normal"
                        >
                          <Select
                            onChange={handleChange('role', i)}
                            input={<OutlinedInput id="user-role" />}
                            classes={{ root: classes.selectRoleForUserControl }}
                            value={fv.role}
                            onBlur={handleBlur}
                          >
                            {InviteRoleLabelFrontendWizard.map((k) => (
                              <MenuItem key={k.value} value={k.value}>
                                {k.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className={classes.fieldsGroup}>
                        <div></div>
                      </div>
                    </div>
                    <div className={classes.formActions}>
                      <PlusIcon
                        className={classes.actionButton}
                        clickAction={handleAddForm(i)}
                      />
                      {showRemoveFormButton ? (
                        <Button
                          className={`${classes.actionButton} ${classes.removeActionButton}`}
                          onClick={handleRemoveForm(i)}
                        >
                          <RemoveIcon />
                        </Button>
                      ) : (
                        <div className={classes.removeActionButtonPlaceholder}>
                          {' '}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </>
            ))}
          </div>
        </div>
        <div>
          <div className={classes.shareTitleCopy}>
            Or Share This Link with Your Team Members
          </div>
          <div className="copy_url_notification">
            <div className={classes.copyLinkWrapper}>
              <div className={classes.linkSection}>
                <div className={classes.linkButtonContainer}>
                  <span className={classes.copyLinkTextContent}>
                    {`${window.location.protocol}//${window.location.host}/register?admin_id=${adminId}&workspace_id=${selectedWorkspace?.id}`}
                  </span>
                  <span
                    className={classes.copyLinkButton}
                    // add the current page id to this url
                    onClick={() =>
                      handleCopyShareLink(
                        `${window.location.protocol}//${window.location.host}/register?admin_id=${adminId}&workspace_id=${selectedWorkspace?.id}`,
                        undefined,
                        true
                      )
                    }
                  >
                    {copied ? 'Copied' : 'Copy'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.skipBtn, classes.boldText) }}
            onClick={handleSkip}
          >
            Skip
          </Button>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.nextBtn, classes.boldText) }}
            onClick={handleInviteUsers}
            disabled={handleCheckIsvalid()}
          >
            Next
          </Button>
        </div>
        <GotoChromeWideWidget />
      </div>
    </>
  );
};

export default InviteTeamUgreade;
