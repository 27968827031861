import { FIXME } from '@src/types';
import { GlobalSettings } from '../../types/setting';
import { baseApi } from '../baseApi';

type UpdateGlobalSettingsParams = {
  workspace_id: number;
} & Partial<{
  from_name: string;
  email_signature: string;
  inbox_email_address: string;
  wpf_every_new_comment: number;
  wpf_every_new_complete: number;
  wpf_every_status_change: number;
  wpf_every_new_task: number;
}>;
export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // Queries
    fetchGlobalSettings: build.query<GlobalSettings, number>({
      query: (workspace_id) =>
        `/user/global-settings?workspace_id=${workspace_id}`,
      transformResponse: (response: { data: GlobalSettings }) => response.data,
      providesTags: (_, error, workspace_id) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
    }),
    // Mutations
    updateGlobalSettings: build.mutation<FIXME, UpdateGlobalSettingsParams>({
      query: (body) => {
        return {
          url: '/user/update-global-settings',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (_, error, { workspace_id }) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
      // TODO: Need to invalidate the user profile once we add the new slice
    }),
    verifyDomain: build.mutation<any, { email: string; workspace_id: number }>({
      query: ({ email, workspace_id }) => {
        return {
          url: `/user/verify-domain?workspace_id=${workspace_id}`,
          method: 'POST',
          body: {
            email,
          },
        };
      },
      invalidatesTags: (_, error, { workspace_id }) => {
        if (error) return [];
        return [{ type: 'Setting', id: workspace_id }];
      },
    }),
  }),
});

export const {
  //queries
  useFetchGlobalSettingsQuery,
  //mutations
  useUpdateGlobalSettingsMutation,
  useVerifyDomainMutation,
} = userApi;
