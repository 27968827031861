import React, { useEffect } from 'react';
// components
import GotoChromeBackdrop from './GotoChromeBackdrop';
// style
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AtarimChrome from 'media/svgs/atarim-chrome-icon.svg';
// Utils
import {
  isChromeBrowser,
  isChromeExtensionInstalled,
} from 'utils/chromeExtension';

const makeClasses = makeStyles((theme) => ({}));

const GotoChromeButton = (props) => {
  const {
    menuCollapse, // Assuming this is used somewhere not shown in the snippet
  } = props;
  const classes = makeClasses(); // Assuming this is a function that returns CSS classes, not shown in the snippet
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [extState, setExtState] = React.useState(false);

  const extInstalled = isChromeExtensionInstalled();
  const chromeBrowser = isChromeBrowser();
  // Threshold for small screens, e.g., less than 1200px
  const smallScreenThreshold = 1200;
  let features;
  useEffect(() => {
    if (!extInstalled && chromeBrowser) {
      setExtState(true);
    }
  }, [extInstalled, chromeBrowser]);

  if (window.innerWidth < smallScreenThreshold) {
    // For smaller screens, open the window in full screen or set minimum dimensions
    features = 'top=0,left=0,width=100,height=100';
  } else {
    // For larger screens, calculate dimensions as before
    let widthVW = 50; // 50% of the viewport width
    let heightVH = 50; // 50% of the viewport height
    let widthInPixels = window.innerWidth * (widthVW / 100);
    let heightInPixels = window.innerHeight * (heightVH / 100);
    let left = (window.innerWidth - widthInPixels) / 2;
    let top = 25;

    features = `width=${widthInPixels},height=${heightInPixels},top=${top},left=${left}`;
  }

  const handleOpenDownloadLink = () => {
    setOpenBackdrop(!openBackdrop);
    window.open(
      'https://chromewebstore.google.com/detail/atarim-visual-website-col/ekpmbeilnaahlccpgjchjjoonecikehf',
      '_blank',
      features
    );
  };

  return (
    <>
      {extState && (
        <div
          className={`at-cs-sidebar-chrome-callout ${menuCollapse ? 'collapsed' : ''}`}
        >
          {!menuCollapse && (
            <div
              className={`at-cs-sidebar-chrome-callout-header-copy ${menuCollapse ? 'collapsed' : ''}`}
            >
              Instantly Collaborate On Any Website
            </div>
          )}
          <Button
            className={`at-cs-btn w-100 m-t-10 btn-white ${menuCollapse ? 'collapsed' : ''}`}
            onClick={handleOpenDownloadLink}
          >
            <img
              alt="atarim chrome icon "
              src={AtarimChrome}
              className={
                menuCollapse ? classes.gotoIconCollasped : classes.gotoIcon
              }
            />
            {!menuCollapse && <span>Install Extension</span>}
          </Button>
          {openBackdrop && (
            <GotoChromeBackdrop
              open={openBackdrop}
              close={() => setOpenBackdrop(false)}
              closeWidgets={setExtState}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GotoChromeButton;
