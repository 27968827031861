import {
  Activity,
  ArrowLeftRight,
  BellPlus,
  CalendarPlus,
  Check,
  ChevronsRight,
  ChevronsUp,
  CirclePlus,
  Clock,
  EyeOff,
  FolderUp,
  ImagePlay,
  MessageSquarePlus,
  MessageSquareText,
  Milestone,
  NotebookPen,
  Pencil,
  Send,
  SeparatorVertical,
  ShieldPlus,
  Tag,
  Trash2,
  UserPlus,
  UserRoundPlus,
} from 'lucide-react';

const size = 16;

const notificationMap = {
  add_new_task: { icon: CirclePlus, text: 'New Task' },
  update_comment: { icon: MessageSquareText, text: 'Updated Comment' },
  task_due_date: { icon: CalendarPlus, text: 'Task Due Date Added' },
  delete_task: { icon: Trash2, text: 'Task Deleted' },
  trash_comment: { icon: Trash2, text: 'Comment Deleted' },
  internal_task: { icon: EyeOff, text: 'Task Marked as Internal' },
  invited_new_user: { icon: UserPlus, text: 'New User Has Been Invited' },
  task_moved: { icon: ArrowLeftRight, text: 'Task Has Been Moved' },
  change_task_tags: { icon: Tag, text: 'Task Tags Updated' },
  uploaded_file: { icon: FolderUp, text: 'New File Uploaded' },
  add_new_general_task: { icon: CirclePlus, text: 'New General Task' },
  change_notify_site_users: { icon: BellPlus, text: 'Added A User to Notify' },
  change_notify_wp_feedback_users: {
    icon: BellPlus,
    text: 'Added A User to Notify',
  },
  recenter_task: { icon: SeparatorVertical, text: 'Task Has Been Recentered' },
  account_upgraded: { icon: ShieldPlus, text: 'Account Has Been Upgraded' },
  change_notify_wordpress_users: {
    icon: BellPlus,
    text: 'Added A User to Notify',
  },
  new_note: { icon: NotebookPen, text: 'New Note' },
  page_approved: { icon: Check, text: 'Page Approved' },
  milestone: { icon: Milestone, text: 'New Milestone Added' },
  new_comment_image_png: { icon: ImagePlay, text: 'New Image Added' },
  new_comment_image_jpeg: { icon: ImagePlay, text: 'New Image Added' },
  new_comment_image_gif: { icon: ImagePlay, text: 'New GIF Added' },
  new_comment_file: { icon: ImagePlay, text: 'New File Added' },
  trash_note: { icon: Trash2, text: 'Note Deleted' },
  change_assignee: { icon: Trash2, text: 'Assignee Changed' },
  re_invited_new_user: { icon: Send, text: 'Re-invited A New User' },
  change_status: { icon: Activity, text: 'Status Change' },
  change_urgency: { icon: ChevronsUp, text: 'Urgency Change' },
  new_user_registered: { icon: UserRoundPlus, text: 'New User Added' },
  new_comment: { icon: MessageSquarePlus, text: 'New Comment' },
  new_comment_normal_text: { icon: MessageSquarePlus, text: 'New Comment' },
  update_estimation_time: { icon: Clock, text: 'Time Estimation Updated' },
  edit_task_comment: { icon: Pencil, text: 'Comment Edited' },
};

function NotificationTypes({ n, formattedStatus }) {
  const notification = notificationMap[n.type];

  if (!notification) return null;

  const IconComponent = notification.icon;

  return (
    <>
      <IconComponent size={size} />
      <span className="flex flex-row items-center text-sm">
        {notification.text}
        {n.type === 'change_status' && (
          <>
            <ChevronsRight size={size} />
            <span className="text-sm capitalize">{formattedStatus}</span>
          </>
        )}
        {n.type === 'change_urgency' && (
          <>
            <ChevronsRight size={size} />
            <span className="text-sm capitalize">{n.type_status}</span>
          </>
        )}
      </span>
    </>
  );
}

export default NotificationTypes;
