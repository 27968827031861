import API from '../api';

export function updateNotification(paramObj, selectedWorkspaceId) {
  return API.post(`/user/update-notification-preference?workspace_id=${selectedWorkspaceId}`, paramObj);
}

export function inviteUser(formValues, selectedWorkspaceId) {
  return API.post(
    `/api-registration-request?workspace_id=${selectedWorkspaceId}`,
    formValues
  );
}

export function fetchAllUsersList(projectId, pageNo = 1, selectedWorkspaceId) {
  return API.get(
    `/user/contributors?project_id=${projectId}&page_no=${pageNo}&limit=20&workspace_id=${selectedWorkspaceId}`
  );
}

export function fetchFeed(userId, pageNumber, status, selectedWorkspaceId) {
  return API.get(
    `/user/get-activity-logs/${userId}?page=${pageNumber}&status=${status}&workspace_id=${selectedWorkspaceId}`
  );
}

export function fetchSiteForUser(
  userId,
  pageNo,
  limit,
  projectId,
  selectedWorkspaceId
) {
  return API.get(
    `/user/site-listing/${userId}?project_id=${projectId}&page_no=${pageNo}&limit=${limit}&workspace_id=${selectedWorkspaceId}`
  );
}

export function addSiteToUser(params) {
  return API.post(`/user/add-site`, params);
}

export function updateUserProfile(userId, params, selectedWorkspaceId) {
  return API.post(
    `/user/update-profile/${userId}?workspace_id=${selectedWorkspaceId}`,
    params
  );
}

export function deleteUser(userId) {
  return API.get(`/user/delete/${userId}`);
}

export function reInviteUser(userId) {
  return API.get(`/user/re-invite/${userId}`);
}

export function fetchProjectForUser(userId, selectedWorkspaceId) {
  return API.get(
    `/user/get-projects/${userId}?workspace_id=${selectedWorkspaceId}`
  );
}

export function addProjectToUser(params) {
  return API.post(`/user/add-project`, params);
}

export function invitedUserlist() {
  return API.get(`/user/invited`);
}

export const removeInvitedTeam = (params) => {
  return API.post(`/collaborate/site/remove-invited-user`, params);
};
