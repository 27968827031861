import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PageLoading from '../components/PageLoading';

const ProtectedRoute = () => {
  const { userData } = useSelector((state) => state.user);

  const location = useLocation();

  if (!userData) {
    return (
      <Navigate
        to={`/login${location.search}`}
        replace
        state={{
          from: location.pathname,
          search: location.search,
        }}
      />
    );
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Outlet />
    </Suspense>
  );
};

export default ProtectedRoute;
