import {
  START_FETCH_HOME_DATA,
  SUCCESS_FETCH_HOME_DATA,
  ERROR_FETCH_HOME_DATA,
  START_FETCH_HOME_WEEKS_DATA,
  SUCCESS_FETCH_HOME_WEEKS_DATA,
  ERROR_FETCH_HOME_WEEKS_DATA,
} from 'actions/home';

const initState = {
  dashboardData: [],
  dataIsLoading: false,
  error: '',
  weeksDataIsLoading: false,
  weeksDashboardData: [],
};

const home = (previousState = initState, action) => {
  switch (action.type) {
    case START_FETCH_HOME_DATA:
      return {
        ...previousState,
        dataIsLoading: true,
      };
    case SUCCESS_FETCH_HOME_DATA:
      return {
        ...previousState,
        dataIsLoading: false,
        dashboardData: action.homeData,
      };
    case ERROR_FETCH_HOME_DATA:
      return {
        ...previousState,
        error: action.message,
      };
    case START_FETCH_HOME_WEEKS_DATA:
      return {
        ...previousState,
        weeksDataIsLoading: true,
      };
    case SUCCESS_FETCH_HOME_WEEKS_DATA:
      return {
        ...previousState,
        weeksDataIsLoading: false,
        weeksDashboardData: action.data,
      };
    case ERROR_FETCH_HOME_WEEKS_DATA:
      return {
        ...previousState,
        error: action.message,
      };
    default:
      return previousState;
  }
};

export default home;
