import type {
  GlobalOptions as ConfettiGlobalOptions,
  CreateTypes as ConfettiInstance,
  Options as ConfettiOptions,
} from 'canvas-confetti';
import confetti from 'canvas-confetti';
import type { ReactNode } from 'react';
import React, {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import Button, { ButtonProps } from '../Button';

type Api = {
  fire: (options?: ConfettiOptions) => void;
};

type Props = React.ComponentPropsWithRef<'canvas'> & {
  options?: ConfettiOptions;
  globalOptions?: ConfettiGlobalOptions;
  manualstart?: boolean;
  children?: ReactNode;
};

export type ConfettiRef = Api | null;

const ConfettiContext = createContext<Api>({} as Api);

const Confetti = forwardRef<ConfettiRef, Props>((props, ref) => {
  const {
    options,
    globalOptions = { resize: true, useWorker: true },
    manualstart = false,
    children,
    ...rest
  } = props;
  const instanceRef = useRef<ConfettiInstance | null>(null); // confetti instance

  const canvasRef = useCallback(
    // https://react.dev/reference/react-dom/components/common#ref-callback
    // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    (node: HTMLCanvasElement) => {
      if (node !== null) {
        // <canvas> is mounted => create the confetti instance
        if (instanceRef.current) return; // if not already created
        instanceRef.current = confetti.create(node, {
          ...globalOptions,
          resize: true,
        });
      } else {
        // <canvas> is unmounted => reset and destroy instanceRef
        if (instanceRef.current) {
          instanceRef.current.reset();
          instanceRef.current = null;
        }
      }
    },
    [globalOptions]
  );

  // `fire` is a function that calls the instance() with `opts` merged with `options`
  const fire = useCallback(
    (opts = {}) => instanceRef.current?.({ ...options, ...opts }),
    [options]
  );

  const api = useMemo(
    () => ({
      fire,
    }),
    [fire]
  );

  useImperativeHandle(ref, () => api, [api]);

  useEffect(() => {
    if (!manualstart) {
      fire();
    }
  }, [manualstart, fire]);

  return (
    <ConfettiContext.Provider value={api}>
      <canvas ref={canvasRef} {...rest} />
      {children}
    </ConfettiContext.Provider>
  );
});

interface ConfettiButtonProps extends Omit<ButtonProps, 'onClick'> {
  children?: React.ReactNode;
  options?: ConfettiOptions &
    ConfettiGlobalOptions & { canvas?: HTMLCanvasElement };
  onClick?: () => void | Promise<void>;
}

function ConfettiButton({
  options,
  children,
  onClick,
  ...buttonProps
}: ConfettiButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = async () => {
    try {
      // Call the original onClick handler if it exists
      if (onClick) {
        await onClick();
      }

      const atarim = confetti.shapeFromPath({
        path: `
        M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z
           M20 89L32 66L89.2084 43.7949L20 89Z
           M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z`,
      });

      // Trigger confetti effect after the onClick handler completes
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;
        confetti({
          ...options,
          origin: {
            x: x / window.innerWidth,
            y: y / window.innerHeight,
          },
          shapes: [atarim],
          disableForReducedMotion: true,
          scalar: 2,
        });
      }
    } catch (error) {
      console.error('Error in onClick handler:', error);
      // You might want to decide whether to still trigger confetti on error
    }
  };

  return (
    <Button ref={buttonRef} onClick={handleClick} {...buttonProps}>
      {children}
    </Button>
  );
}

export { Confetti, ConfettiButton };

export default Confetti;
