import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from '@src/store';

export enum FeatureFlags {
  COLLABORATE_V2 = "COLLABORATE_V2"
}

type FeatureFlagState = {
  [flag in FeatureFlags]: boolean
}

const initialState: FeatureFlagState = {
  [FeatureFlags.COLLABORATE_V2]: false
}

const featureFlagSlice = createSlice({
  initialState,
  name: "featureFlags",
  reducers: {
    bulkUpdateFeatureFlags: (state, action: PayloadAction<Partial<Record<FeatureFlags, boolean>>>) => {
      Object.assign(state, action.payload)
    },
    updateFeatureFlag: (state, action: PayloadAction<{ flag: FeatureFlags, enabled: boolean }>) => {
      state[action.payload.flag] = action.payload.enabled
    }
  }
})

export const { bulkUpdateFeatureFlags, updateFeatureFlag } = featureFlagSlice.actions
export const getFeatureFlags = (state: RootState) => state.featureFlags
export default featureFlagSlice.reducer