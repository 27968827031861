import {
  INIT_INVITE_USER,
  INVITE_USER,
  INVITE_USER_NOT_SENT,
  INVITE_USER_SENT,
  SUCCESS_UPDATE_NOTIFICATION,
  SUCCESS_UPDATE_PROFILE,
  SUCCESS_UPLOAD_IMAGE,
} from 'actions/activity';
import {
  BILLING_INFO,
  CLEAR_PLAN_SEARCH,
  CLOSE_TRIAL_WIZARD,
  COLLAPSE_COLLABORATE_SIDE_NAV,
  COLLAPSE_SIDE_NAV,
  CONFIRM_SINGLE_USER_TWO_FA,
  CUSTOMER_INVOICES,
  ELIGIBLE_TO_INACTIVE,
  EMPTY_BILLING_MESSAGE,
  EMPTY_MESSAGE,
  EMPTY_PLANS_MESSAGE,
  ERROR_BILLING_INFO,
  ERROR_CHANGE_PASSWORD,
  ERROR_CONFIRM_SINGLE_USER_TWO_FA,
  ERROR_CUSTOMER_INVOICES,
  ERROR_FORGOT_PASSWORD,
  ERROR_GET_READ_NOTIFICATIONS,
  ERROR_GET_UNREAD_NOTIFICATIONS,
  ERROR_INVOICE_DOWNLOAD_URL,
  ERROR_LICENSE_ACTIVATION,
  ERROR_OPEN_CHECKOUT,
  ERROR_PORTAL_SESSION,
  ERROR_PROFILE_FETCH,
  ERROR_RESET_PASSWORD,
  ERROR_SEND_TWO_FACTOR_CODE,
  // ERROR_GET_NOTIFICATIONS_DETAILS,
  ERROR_UPDATE_USER_NOTIFICATION,
  ERROR_UPGRADE_PLANS,
  ERROR_USER_AUTHENTICATE,
  ERROR_USER_REGISTRATION,
  ERROR_VERIFY_CODE,
  GET_READ_NOTIFICATIONS,
  // ERROR_CHECK_ALL_NOTIFICATIONS,
  // CHECK_ALL_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  HANDLE_PLANS_DIALOG,
  // ERROR_MUTE_UNMUTE_NOTIFICATIONS,
  INCREASE_READ_COUNTER,
  INCREASE_UNREAD_COUNTER,
  INVOICE_DOWNLOAD_URL,
  IS_NEW_USER,
  LICENSE_ACTIVATION,
  LOGOUT,
  OPEN_CHECKOUT,
  OPEN_CLOSE_NOTIFICATIONS_MODAL,
  PORTAL_SESSION,
  REJECT_TRIAL,
  REMOVE_HOSTED_PAGE_DATA,
  REMOVE_INVOICE_LINK,
  REMOVE_PORTAL_SESSION_DATA,
  SAVE_PLAN_SEARCH,
  SAVE_URL_SEARCH_HISTORY,
  SET_2FA_STATUS,
  SET_MAINTENANCE_FLAG,
  SET_REDIRECT,
  SET_REGISTERED_FROM_DASHBOARD,
  SET_TOKEN_IN_REDUX,
  SOCIAL_USER_AUTHENTICATE,
  START_CHANGE_PASSWORD,
  START_FORGOT_PASSWORD,
  START_PROFILE_FETCH,
  START_RESET_PASSWORD,
  START_SOCIAL_USER_REGISTRATION,
  START_USER_AUTHENTICATE,
  SUCCESS_ACTIVATE_TRIAL,
  SUCCESS_BILLING_INFO,
  SUCCESS_CHANGE_PASSWORD,
  SUCCESS_CHECK_ALL_NOTIFICATIONS,
  SUCCESS_CLOSE_TRIAL_WIZARD,
  SUCCESS_CONFIRM_SINGLE_USER_TWO_FA,
  SUCCESS_CUSTOMER_INVOICES,
  SUCCESS_FETCH_EXISTING_SITE_URL,
  SUCCESS_FORGOT_PASSWORD,
  // GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS_COUNT,
  // ERROR_GET_NOTIFICATIONS,
  // GET_NOTIFICATIONS_DETAILS,
  SUCCESS_GET_NOTIFICATIONS_DETAILS,
  SUCCESS_GET_READ_NOTIFICATIONS,
  SUCCESS_GET_UNREAD_NOTIFICATIONS,
  SUCCESS_INVOICE_DOWNLOAD_URL,
  SUCCESS_LICENSE_ACTIVATION,
  // ERROR_GET_NOTIFICATIONS_COUNT,
  // MUTE_UNMUTE_NOTIFICATIONS,
  SUCCESS_MUTE_UNMUTE_NOTIFICATIONS,
  SUCCESS_OPEN_CHECKOUT,
  SUCCESS_PORTAL_SESSION,
  SUCCESS_PROFILE_FETCH,
  SUCCESS_REJECT_TRIAL,
  SUCCESS_RESET_PASSWORD,
  SUCCESS_SEND_TWO_FACTOR_CODE,
  SUCCESS_SUBSCRIPTION_DETAILS,
  SUCCESS_UPDATE_USER_NOTIFICATION,
  SUCCESS_UPGRADE_PLANS,
  SUCCESS_USER_AUTHENTICATE,
  SUCCESS_USER_REGISTRATION,
  SUCCESS_VERIFY_CODE,
  UPDATE_REDIRECTING_TO_ATARIM,
  UPDATE_SELECTED_INDEX,
  // HANDLE_START_WIZARD_DIALOG,
  UPDATE_TRAIL_DETAIL,
  UPDATE_USER_NOTIFICATION,
  UPGRADE_PLANS,
  VERIFY_CODE,
} from 'actions/user';
import {
  SUCCESS_CREATE_WORKSPACE,
  SUCCESS_DELETE_WORKSPACE,
} from 'actions/workspace';
// Utils
import { InviteStatus } from 'utils/constants';
import { sortingNotifications } from 'utils/functions';

const initState = {
  token: null,
  isLoading: false,
  error: false,
  message: '',
  passwordResetSuccess: false,
  isLogout: false,
  userData: null,
  inviteStatus: InviteStatus.WAITING,
  inviteUserMessage: '', // saves success or error message
  mapInviteErrorMessagesToInputFields: [],
  isUnderMaintenance: false,
  successChangePasswordMessage: '',
  errorChangePasswordMessage: '',
  redirecting: false,
  urlSearchHistory: '',
  userRegistrationLoading: false,
  userRegistrationSuccess: '',
  userRegistrationError: '',
  selectedIndex: 10,
  activateLicenseData: [],
  licenseActivationLoading: false,
  licenseActivationSuccess: null,
  licenseActivationMessage:
    'An error occured while checking for license status',
  registeredFromDashboardNotCollaborate: false,
  pageRedirect: null,
  notifications: [],
  readNotifications: [],
  unreadNotifications: [],
  loadingNotifications: false,
  notificationDetails: {},
  moreUnreadWebRecords: false,
  moreReadWebRecords: false,
  totalUnreadNotifications: null,
  unreadCount: null,
  muteState: false,
  readNotificationsOffsetCounter: 1,
  unreadNotificationsOffsetCounter: 1,
  isNotificationsModalOpen: false,
  updatingNotification: false,
  selectProjectOnInbox: false,
  upgradePlansLoading: false,
  pricingPlans: [],
  isPlansDialogOpened: false,
  hostedPagedata: {},
  startingCheckout: false,
  billingInfo: {},
  loadingBillingInfo: true,
  invoiceUrl: '',
  portalSessionObject: {},
  userInvoices: [],
  loadingInvoices: false,
  plansSuccessMessage: '',
  plansErrorMessage: '',
  billingSuccessMessage: '',
  billingErrorMessage: '',
  downloadingInvoiceUrl: false,
  popupType: '',
  menuCollapse: false,
  userTrialData: {},
  trialStatus: '',
  existingWebsites: [],
  codeData: '',
  resetPassMessage: '',
  is2FAEnabled: false,
  verificationError: '',
  verificationStatus: false,
  planSearch: {},
};

const user = (previousState = initState, action) => {
  switch (action.type) {
    case START_USER_AUTHENTICATE:
      return {
        ...previousState,
        isLoading: true,
        error: false,
        message: '',
      };
    case SOCIAL_USER_AUTHENTICATE:
      return {
        ...previousState,
        isLoading: true,
        error: false,
        message: '',
      };
    case SUCCESS_USER_AUTHENTICATE:
      return {
        ...previousState,
        error: false,
        isLoading: false,
        message: '',
        token: action.token,
      };
    case ERROR_USER_AUTHENTICATE:
      return {
        ...previousState,
        error: true,
        message: action.message,
        isLoading: false,
      };
    case START_RESET_PASSWORD:
      return {
        ...previousState,
        isLoading: true,
        passwordResetSuccess: false,
        resetPassMessage: '',
      };
    case SUCCESS_RESET_PASSWORD:
      return {
        ...previousState,
        isLoading: false,
        error: false,
        successMessage: action.messageSuccess,
        token: action.token,
        passwordResetSuccess: true,
      };
    case ERROR_RESET_PASSWORD:
      return {
        ...previousState,
        isLoading: false,
        error: true,
        errorMessage: action.messageError,
        passwordResetSuccess: false,
      };
    case EMPTY_MESSAGE:
      return {
        ...previousState,
        [action.key]: '',
        message: '',
        error: false,
        passwordResetSuccess: false,
        userRegistrationError: '',
        verificationError: '',
      };
    case START_FORGOT_PASSWORD:
      return {
        ...previousState,
        isLoading: true,
        error: false,
        message: '',
      };
    case ERROR_FORGOT_PASSWORD:
      return {
        ...previousState,
        message: action.message,
        error: true,
        isLoading: false,
      };
    case SUCCESS_FORGOT_PASSWORD:
      return {
        ...previousState,
        message: action.message,
        error: false,
        isLoading: false,
      };
    case SET_TOKEN_IN_REDUX:
      return {
        ...previousState,
        token: action.token,
      };
    case START_PROFILE_FETCH:
      return {
        ...previousState,
        userData: action?.switchWorkspace ? previousState?.userData : null,
        userTrialData: {},
        isLoading: true,
        message: '',
        error: false,
      };
    case SUCCESS_PROFILE_FETCH:
      return {
        ...previousState,
        userData: action.userData,
        userTrialData: action.userData?.trial,
        isLoading: false,
        error: false,
        message: '',
        trialStatus: action.userData?.trial?.status,
      };
    case ERROR_PROFILE_FETCH:
      return {
        ...previousState,
        userData: {},
        userTrialData: {},
        isLoading: false,
        error: true,
        message: action.message,
      };
    case INVITE_USER:
      return {
        ...previousState,
        inviteStatus: InviteStatus.SENDING,
        inviteUserMessage: '',
        mapInviteErrorMessagesToInputFields: [],
      };
    case INVITE_USER_SENT:
      return {
        ...previousState,
        inviteStatus: InviteStatus.SENT,
        inviteUserMessage: action.message,
        mapInviteErrorMessagesToInputFields: [],
      };
    case INVITE_USER_NOT_SENT:
      return {
        ...previousState,
        inviteStatus: InviteStatus.NOT_SENT,
        inviteUserMessage: action.message,
        mapInviteErrorMessagesToInputFields: action.mapErrorToInputFields,
      };
    case INIT_INVITE_USER:
      return {
        ...previousState,
        inviteStatus: InviteStatus.WAITING,
        inviteUserMessage: '',
      };
    case LOGOUT:
      // remove this once we add an AuthProvider
      return {
        ...initState,
        isLogout: true,
        message: action.message || initState.message,
        error: action.error || initState.error,
      };
    case SUCCESS_UPLOAD_IMAGE:
      const { userId, avatar } = action;
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          avatar:
            previousState.userData.id === userId
              ? avatar
              : previousState.userData.avatar || '',
        },
      };
    case SUCCESS_UPDATE_PROFILE:
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          [action.key]:
            previousState.userData.id === action.userId
              ? action.value
              : previousState.userData[action.key],
        },
      };
    case SUCCESS_UPDATE_NOTIFICATION:
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          notification_preference:
            previousState.userData.notification_preference.map((n) =>
              n.value === action.paramObj.value
                ? { ...n, selected: action.paramObj.selected }
                : n
            ),
        },
      };
    case SET_MAINTENANCE_FLAG:
      return {
        ...previousState,
        isUnderMaintenance: action.isUnderMaintenance,
      };
    case START_CHANGE_PASSWORD:
      return {
        ...previousState,
        isLoading: true,
      };
    case SUCCESS_CHANGE_PASSWORD:
      return {
        ...previousState,
        successChangePasswordMessage: action.message,
        errorChangePasswordMessage: '',
        isLoading: false,
      };
    case ERROR_CHANGE_PASSWORD:
      return {
        ...previousState,
        successChangePasswordMessage: '',
        errorChangePasswordMessage: action.message,
        isLoading: false,
      };
    case UPDATE_REDIRECTING_TO_ATARIM:
      return {
        ...previousState,
        redirecting: action.redirecting,
      };
    case SAVE_URL_SEARCH_HISTORY:
      return {
        ...previousState,
        urlSearchHistory: action.url,
      };
    case START_SOCIAL_USER_REGISTRATION:
      return {
        ...previousState,
        userRegistrationLoading: true,
        userRegistrationSuccess: '',
        userRegistrationError: '',
      };
    case SUCCESS_USER_REGISTRATION:
      return {
        ...previousState,
        token: action.token,
        userRegistrationLoading: false,
        userRegistrationSuccess: action.message,
        userRegistrationError: '',
      };
    case ERROR_USER_REGISTRATION:
      return {
        ...previousState,
        userRegistrationLoading: false,
        userRegistrationSuccess: '',
        userRegistrationError: action.message,
      };
    case IS_NEW_USER:
      return {
        ...previousState,
        isNewUser: action.isNewUser,
      };
    //Update Selected Nav Icon
    case UPDATE_SELECTED_INDEX: {
      const { value } = action;

      return {
        ...previousState,
        selectedIndex: value,
      };
    }
    case LICENSE_ACTIVATION:
      return {
        ...previousState,
        activateLicenseData: [],
        licenseActivationLoading: true,
        licenseActivationSuccess: null,
      };
    case SUCCESS_LICENSE_ACTIVATION:
      return {
        ...previousState,
        activateLicenseData: action.data,
        licenseActivationLoading: false,
        licenseActivationSuccess: true,
      };
    case ERROR_LICENSE_ACTIVATION:
      return {
        ...previousState,
        activateLicenseData: [],
        licenseActivationLoading: false,
        licenseActivationSuccess: false,
        licenseActivationMessage: action.message,
      };
    case SET_REGISTERED_FROM_DASHBOARD:
      return {
        ...previousState,
        registeredFromDashboardNotCollaborate: action.value,
      };
    case SET_REDIRECT:
      return {
        ...previousState,
        pageRedirect: action.param,
      };
    // Notifications Reducers
    case GET_READ_NOTIFICATIONS:
      return {
        ...previousState,
        loadingNotifications: true,
      };
    case SUCCESS_GET_READ_NOTIFICATIONS:
      let noti = [];
      // not to push duplicate notification
      for (let i = 0; i < action.data.notifications.length; i++) {
        if (
          !previousState.readNotifications.includes(
            action.data.notifications[i]
          )
        ) {
          noti.push(action.data.notifications[i]);
        }
      }
      // sorting the notifications according to Created_at value of notifications
      const moreReadNotifications =
        previousState.readNotifications.concat(noti);
      const sortedRead = moreReadNotifications.sort(sortingNotifications);
      return {
        ...previousState,
        loadingNotifications: false,
        readNotifications: sortedRead,
        moreReadWebRecords: action.data.more_web_records,
        totalUnreadNotifications: action.data.total_unread_notifications,
      };
    case ERROR_GET_READ_NOTIFICATIONS:
      return {
        ...previousState,
        loadingNotifications: false,
      };
    case GET_UNREAD_NOTIFICATIONS:
      return {
        ...previousState,
        loadingNotifications: true,
      };
    case SUCCESS_GET_UNREAD_NOTIFICATIONS:
      let noti2 = [];
      // not to push duplicate notification
      for (let i = 0; i < action.data.notifications.length; i++) {
        if (
          !previousState.unreadNotifications.includes(
            action.data.notifications[i]
          )
        ) {
          noti2.push(action.data.notifications[i]);
        }
      }
      // sorting the notifications according to Created_at value of notifications
      const moreUnreadNotifications =
        previousState.unreadNotifications.concat(noti2);
      const sortedUnread = moreUnreadNotifications.sort(sortingNotifications);
      return {
        ...previousState,
        loadingNotifications: false,
        unreadNotifications: sortedUnread,
        moreUnreadWebRecords: action.data.more_web_records,
        totalUnreadNotifications: action.data.total_unread_notifications,
      };
    case ERROR_GET_UNREAD_NOTIFICATIONS:
      return {
        ...previousState,
        loadingNotifications: false,
      };
    case INCREASE_READ_COUNTER:
      const inc1 = previousState.readNotificationsOffsetCounter + 1;
      return {
        ...previousState,
        readNotificationsOffsetCounter: inc1,
      };
    case INCREASE_UNREAD_COUNTER:
      const inc2 = previousState.unreadNotificationsOffsetCounter + 1;
      return {
        ...previousState,
        unreadNotificationsOffsetCounter: inc2,
      };
    case SUCCESS_GET_NOTIFICATIONS:
      return {
        ...previousState,
        muteState: action.data.is_notification_muted,
      };
    case SUCCESS_GET_NOTIFICATIONS_DETAILS:
      return {
        ...previousState,
        notificationDetails: action.data,
      };
    case UPDATE_USER_NOTIFICATION:
      return {
        ...previousState,
        updatingNotification: true,
      };
    case SUCCESS_UPDATE_USER_NOTIFICATION:
      if (action.data.is_read) {
        const inserted = [action.data, ...previousState.readNotifications];
        const filtred = previousState.unreadNotifications.filter(
          (n) => n.id !== action.data.id
        );
        // sorting the notifications according to Created_at value of notifications
        const sorted = inserted.sort(sortingNotifications);
        return {
          ...previousState,
          unreadCount: action.data.update_count,
          unreadNotifications: filtred,
          readNotifications: sorted,
          updatingNotification: false,
        };
      }
      if (!action.data.is_read) {
        const inserted = [action.data, ...previousState.unreadNotifications];
        const filtred = previousState.readNotifications.filter(
          (n) => n.id !== action.data.id
        );
        // sorting the notifications according to Created_at value of notifications
        const sorted = inserted.sort(sortingNotifications);

        return {
          ...previousState,
          unreadCount: action.data.update_count,
          unreadNotifications: sorted,
          readNotifications: filtred,
          updatingNotification: false,
        };
      }
    case ERROR_UPDATE_USER_NOTIFICATION:
      return {
        ...previousState,
        updatingNotification: false,
      };
    case SUCCESS_CHECK_ALL_NOTIFICATIONS:
      const updated = previousState.unreadNotifications.map((n) => ({
        ...n,
        is_read: true,
      }));
      // sorting the notifications according to Created_at value of notifications
      const readed = previousState.readNotifications.concat(updated);
      const sorted = readed.sort(sortingNotifications);
      return {
        ...previousState,
        unreadNotifications: [],
        readNotifications: sorted,
        unreadCount: 0,
      };
    case SUCCESS_GET_NOTIFICATIONS_COUNT:
      return {
        ...previousState,
        unreadCount: action.data,
      };
    case SUCCESS_MUTE_UNMUTE_NOTIFICATIONS:
      return {
        ...previousState,
        muteState: action.data.is_muted,
      };
    case OPEN_CLOSE_NOTIFICATIONS_MODAL:
      return {
        ...previousState,
        isNotificationsModalOpen: action.param,
      };
    case UPGRADE_PLANS:
      return {
        ...previousState,
        upgradePlansLoading: true,
      };
    case SUCCESS_UPGRADE_PLANS:
      const sortedPlans = action.data.slice().sort((a, b) => a.rank - b.rank);
      return {
        ...previousState,
        pricingPlans: sortedPlans,
        upgradePlansLoading: false,
      };
    case ERROR_UPGRADE_PLANS:
      return {
        ...previousState,
        upgradePlansLoading: false,
        plansErrorMessage: action.message,
      };
    case INVOICE_DOWNLOAD_URL:
      return {
        ...previousState,
        downloadingInvoiceUrl: true,
      };
    case SUCCESS_INVOICE_DOWNLOAD_URL:
      return {
        ...previousState,
        invoiceUrl: action.data.download_url,
        downloadingInvoiceUrl: false,
      };
    case ERROR_INVOICE_DOWNLOAD_URL:
      return {
        ...previousState,
        billingErrorMessage: action.message,
        downloadingInvoiceUrl: false,
      };
    case REMOVE_INVOICE_LINK:
      return {
        ...previousState,
        invoiceUrl: '',
      };
    case OPEN_CHECKOUT:
      return {
        ...previousState,
        startingCheckout: true,
      };
    case SUCCESS_OPEN_CHECKOUT:
      return {
        ...previousState,
        hostedPagedata: action.data,
        startingCheckout: false,
      };
    case ERROR_OPEN_CHECKOUT:
      return {
        ...previousState,
        startingCheckout: false,
        plansErrorMessage: action.message,
      };
    case HANDLE_PLANS_DIALOG:
      return {
        ...previousState,
        isPlansDialogOpened: action.params,
      };
    case REMOVE_HOSTED_PAGE_DATA:
      return {
        ...previousState,
        hostedPagedata: {},
      };
    case SUCCESS_SUBSCRIPTION_DETAILS:
      return {
        ...previousState,
        userData: action.data,
      };
    case BILLING_INFO:
      return {
        ...previousState,
        loadingBillingInfo: true,
      };
    case SUCCESS_BILLING_INFO:
      return {
        ...previousState,
        billingInfo: action.data,
        loadingBillingInfo: false,
      };
    case ERROR_BILLING_INFO:
      return {
        ...previousState,
        loadingBillingInfo: false,
        billingErrorMessage: action.message,
      };
    case CUSTOMER_INVOICES:
      return {
        ...previousState,
        loadingInvoices: true,
      };
    case SUCCESS_CUSTOMER_INVOICES:
      return {
        ...previousState,
        userInvoices: action.data,
        loadingInvoices: false,
      };
    case ERROR_CUSTOMER_INVOICES:
      return {
        ...previousState,
        loadingInvoices: false,
        billingErrorMessage: action.message,
      };
    case PORTAL_SESSION:
      return {
        ...previousState,
        portalSessionObject: {},
        popupType: '',
      };
    case SUCCESS_PORTAL_SESSION:
      return {
        ...previousState,
        portalSessionObject: action.data,
        popupType: action.popupType,
      };
    case ERROR_PORTAL_SESSION:
      return {
        ...previousState,
      };
    case REMOVE_PORTAL_SESSION_DATA:
      return {
        ...previousState,
        portalSessionObject: {},
      };
    case EMPTY_PLANS_MESSAGE:
      return {
        ...previousState,
        plansSuccessMessage: '',
        plansErrorMessage: '',
      };
    case EMPTY_BILLING_MESSAGE:
      return {
        ...previousState,
        billingErrorMessage: '',
        billingSuccessMessage: '',
      };
    case COLLAPSE_SIDE_NAV:
      return {
        ...previousState,
        menuCollapse: !previousState.menuCollapse,
      };
    case COLLAPSE_COLLABORATE_SIDE_NAV:
      return {
        ...previousState,
        menuCollapse: action.param,
      };
    case UPDATE_TRAIL_DETAIL:
      const { key, value } = action;
      return {
        ...previousState,
        userTrialData: {
          ...previousState.userTrialData,
          [key]: value,
        },
        isLoading: false,
        error: false,
        message: '',
      };
    case SUCCESS_ACTIVATE_TRIAL:
      return {
        ...previousState,
        userData: action.data,
      };

    case REJECT_TRIAL:
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          trial: {
            ...previousState.userData?.trial,
            trial_rejected_at: new Date().toISOString(),
          },
        },
      };
    case SUCCESS_REJECT_TRIAL:
      return {
        ...previousState,
        userData: {
          ...previousState.userData,
          trial: {
            ...previousState.userData.trial,
            trial_rejected_at: action.data,
          },
        },
      };
    case ELIGIBLE_TO_INACTIVE:
      return {
        ...previousState,
        userTrialData: {
          ...previousState.userTrialData,
          status: action.params,
        },
      };
    case SUCCESS_FETCH_EXISTING_SITE_URL:
      return {
        ...previousState,
        existingWebsites: action.data,
      };
    case CLOSE_TRIAL_WIZARD:
      return {
        ...previousState,
        userTrialData: {
          ...previousState.userTrialData,
          status: 'active',
        },
        userData: {
          ...previousState.userData,
          trial: {
            ...previousState.userData.trial,
            status: 'active',
          },
        },
        trialStatus: 'active',
      };
    case SUCCESS_CLOSE_TRIAL_WIZARD:
      return {
        ...previousState,
        userTrialData: action.data,
        userData: {
          ...previousState.userData,
          trial: action.data,
        },
      };
    case SUCCESS_SEND_TWO_FACTOR_CODE:
      return {
        ...previousState,
        // error: false,
        message: action.message,
        codeData: action.data,
      };
    case ERROR_SEND_TWO_FACTOR_CODE:
      return {
        ...previousState,
        // error: true,
        message: '',
      };
    case SET_2FA_STATUS:
      return {
        ...previousState,
        is2FAEnabled: action.status,
      };
    case VERIFY_CODE:
      return {
        ...previousState,
        verificationError: '',
        message: '',
        verificationStatus: false,
      };
    case ERROR_VERIFY_CODE:
      return {
        ...previousState,
        verificationError: action.message,
        message: '',
        verificationStatus: false,
      };
    case CONFIRM_SINGLE_USER_TWO_FA:
      return {
        ...previousState,
        verificationError: '',
        message: '',
        verificationStatus: false,
      };
    case SUCCESS_CONFIRM_SINGLE_USER_TWO_FA:
      return {
        ...previousState,
        verificationError: '',
        message: action.message,
        verificationStatus: action.data,
      };
    case ERROR_CONFIRM_SINGLE_USER_TWO_FA:
      return {
        ...previousState,
        verificationError: action.message,
        message: '',
        verificationStatus: false,
      };
    case SUCCESS_VERIFY_CODE:
      return {
        ...previousState,
        verificationError: '',
        message: action.message,
        verificationStatus: true,
      };
    case SUCCESS_CREATE_WORKSPACE:
      return {
        ...previousState,
        // update limit of workspace in userData
        userData: {
          ...previousState.userData,
          limit: {
            ...previousState.userData.limit,
            workspaces: previousState.userData.limit.workspaces - 1,
          },
        },
      };
    case SUCCESS_DELETE_WORKSPACE:
      return {
        ...previousState,
        // update limit of workspace in userData
        userData: {
          ...previousState.userData,
          limit: {
            ...previousState.userData.limit,
            workspaces: previousState.userData.limit.workspaces + 1,
          },
        },
      };

    case SAVE_PLAN_SEARCH:
      return {
        ...previousState,
        planSearch: action.planSearch,
      };
    case CLEAR_PLAN_SEARCH:
      return {
        ...previousState,
        planSearch: {},
      };
    default:
      return previousState;
  }
};

export default user;
