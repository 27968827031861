import {
  DELETE_BOARD_COMMENT_FILE,
  DELETE_TASK,
  ERROR_BOARD_FILTER_FETCHING,
  ERROR_BOARD_STATUS_TASKS,
  ERROR_BOARD_URGENCY_TASKS,
  ERROR_DELETE_BOARD_COMMENT_FILE,
  ERROR_FETCH_ALL_BOARD_TASKS,
  ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK,
  ERROR_SAVE_NEW_BOARD_GENERAL_TASK,
  ERROR_SEARCH_BOARD,
  ERROR_UPDATE_BOARD_TASK,
  ERROR_UPDATE_BOARD_TASK_ORDER,
  ERROR_UPDATE_TASK_ATTRIBUTES,
  FETCH_ALL_BOARD_TASKS,
  FETCH_BOARD_COLUMN,
  FETCH_BOARD_URGENCY_TASKS,
  FETCH_NEXT_BOARD_STATUS_TASKS,
  FETCH_NEXT_BOARD_URGENCY_TASKS,
  MESSAGE_SENT_NEW_BOARD_TASK,
  NEW_BOARD_GENERAL_TASK_SAVED_WITH_MESSAGE,
  NEW_BOARD_WEBSITE_SELECTED,
  SAVE_NEW_BOARD_GENERAL_TASK,
  START_SEARCH_BOARD,
  START_UPDATE_BOARD_TASK_ORDER,
  SUCCESS_BOARD_FILTER_FETCHING,
  SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS,
  SUCCESS_BOARD_NEXT_URGENCY_TASKS,
  SUCCESS_BOARD_STATUS_COMPLETE_TASKS,
  SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS,
  SUCCESS_BOARD_STATUS_OPEN_TASKS,
  SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS,
  SUCCESS_BOARD_URGENCY_CRITICAL_TASKS,
  SUCCESS_BOARD_URGENCY_HIGH_TASKS,
  SUCCESS_BOARD_URGENCY_MEDIUM_TASKS,
  SUCCESS_BOARD_URGENCY_TASKS,
  SUCCESS_DELETE_BOARD_COMMENT_FILE,
  SUCCESS_DELETE_TASK,
  SUCCESS_FETCH_ALL_BOARD_TASKS,
  SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK,
  SUCCESS_SEARCH_BOARD,
  SUCCESS_UPDATE_BOARD_TASK,
  SUCCESS_UPDATE_BOARD_TASK_ORDER,
  SUCCESS_UPDATE_TASK_ATTRIBUTES,
  UPDATE_BOARD_TASK,
  UPDATE_BOARD_TASK_ATTRIBUTES,
  UPDATE_TASK_ATTRIBUTES,
} from 'actions/board';
import { SET_EMAIL_SELECTED } from 'actions/email';
import { SUCCESS_UPDATE_TASK_ATTRIBUTES as SUCCESS_UPDATE_TASK_ATTRIBUTES_OF_TASKS } from 'actions/tasks';
import { LOGOUT } from 'actions/user';
import {
  call,
  fork,
  join,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// APIs
import { LogoutObjectForPutEffect } from 'utils/constants';
import {
  fetchInitFilterObject,
  getSelectedWebsiteId,
} from 'utils/functions';
import { getFilterUrl } from 'utils/urls.js';
import {
  deleteCommentFiles,
  deleteMultiTasks,
  fetchAllBoardTasks,
  fetchBoardColumnTask,
  fetchBoardTaskLowUrgency,
  fetchNextBoardTaskStatus,
  fetchNextBoardTaskUrgency,
  fetchTaskFilters,
  filterTasks,
  saveGeneralTask,
  sendMessage,
  updateBoardTaskOrders,
  updateSingleTaskAttributes,
  updateTaskAttributes,
} from '../api/tasks';

export function* fetchAllBoardTasksWatcher() {
  while (true) {
    yield take(FETCH_ALL_BOARD_TASKS);
    yield fork(fetchAllTaskWorker);
    yield take([
      SUCCESS_FETCH_ALL_BOARD_TASKS,
      LOGOUT,
      ERROR_FETCH_ALL_BOARD_TASKS,
    ]);
  }
}

function* fetchAllTaskWorker() {
  const data = yield call(fetchAllBoardTasks, 0, 4000);

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_BOARD_TASKS,
      listIsLoading: data.more_records ? true : false,
      allTasks: data.data,
      moreRecords: data.more_records,
    });
    if (data.more_records) {
      const moreData = yield call(fetchAllBoardTasks, data.offset, 4000);
      if (moreData.status) {
        yield put({
          type: SUCCESS_FETCH_ALL_BOARD_TASKS,
          allTasks: moreData.data,
          moreRecords: moreData.more_records,
        });

        if (moreData.more_records) {
          const anotherData = yield call(
            fetchAllBoardTasks,
            moreData.offset,
            4000
          );
          if (anotherData.status) {
            yield put({
              type: SUCCESS_FETCH_ALL_BOARD_TASKS,
              allTasks: anotherData.data,
              moreRecords: anotherData.more_records,
            });
          }
        }
      }
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_BOARD_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

function* updateBoardTaskWorker({ updateBoardTaskParams }) {
  const data = yield call(updateSingleTaskAttributes, updateBoardTaskParams);
  if (data.status) {
    // task_event.task_event.sortable('cancel');
    yield put({
      type: SUCCESS_UPDATE_BOARD_TASK,
      // data:data.tasks,
      params: updateBoardTaskParams,
      // emailSelected:task_event.email_selected,
      url: '/site',
      filterWp: localStorage.getItem('wpFilter'),
      filterCollab: localStorage.getItem('urlFilter'),
      filterImg: localStorage.getItem('imageFilter'),
      site_order: localStorage.getItem('siteSortingBoard'),
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_BOARD_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateBoardTaskSagas() {
  yield takeEvery(UPDATE_BOARD_TASK, updateBoardTaskWorker);
}

function* getBoardTasksFilters(websiteId) {
  const data = yield call(fetchTaskFilters, websiteId);
  if (data.status) {
    yield put({
      type: SUCCESS_BOARD_FILTER_FETCHING,
      filters: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_BOARD_FILTER_FETCHING,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

function* fetchBoardasksAndFilters(action) {
  const { websiteId } = action;
  if (websiteId !== '') {
    yield put({
      type: SET_EMAIL_SELECTED,
      value: false,
    });
    const fetchTaskFiltersJob = yield fork(getBoardTasksFilters, websiteId);

    // Fetch Regular Status tasks
    const fetchOpenTasks = yield fork(fetchBoardTaskStatusWorker, {
      websiteId,
      task_category: 'task_status',
      column_type: 'open',
    });
    const fetchInProgressTasks = yield fork(fetchBoardTaskStatusWorker, {
      websiteId,
      task_category: 'task_status',
      column_type: 'in-progress',
    });
    const fetchPendingReviewTasks = yield fork(fetchBoardTaskStatusWorker, {
      websiteId,
      task_category: 'task_status',
      column_type: 'pending-review',
    });
    const fetchCompleteTasks = yield fork(fetchBoardTaskStatusWorker, {
      websiteId,
      task_category: 'task_status',
      column_type: 'complete',
    });
    // Fetch regular urgency task
    const fetchBoardTaskUrgency = yield fork(fetchBoardTaskUrgencyWorker, {
      websiteId,
      task_category: 'task_priority',
      column_type: 'low',
    });
    const fetchBoardTaskMediumUrgency = yield fork(
      fetchBoardTaskUrgencyWorker,
      { websiteId, task_category: 'task_priority', column_type: 'medium' }
    );
    const fetchBoardTaskHighUrgency = yield fork(fetchBoardTaskUrgencyWorker, {
      websiteId,
      task_category: 'task_priority',
      column_type: 'high',
    });
    const fetchBoardTaskCriticalUrgency = yield fork(
      fetchBoardTaskUrgencyWorker,
      { websiteId, task_category: 'task_priority', column_type: 'critical' }
    );

    yield join([
      fetchTaskFiltersJob,
      fetchOpenTasks,
      fetchPendingReviewTasks,
      fetchInProgressTasks,
      fetchCompleteTasks,
      fetchBoardTaskUrgency,
      fetchBoardTaskMediumUrgency,
      fetchBoardTaskHighUrgency,
      fetchBoardTaskCriticalUrgency,
      fetchTaskFiltersJob,
    ]);
  }
}

export function* manageBoardTaskFlow() {
  yield takeLatest(NEW_BOARD_WEBSITE_SELECTED, fetchBoardasksAndFilters);
}

function* postTaskAttributes({
  keyOfAttributeUpdated,
  updateTaskAttributeParams,
  emailSelected,
  movedCards,
}) {
  yield put({
    type: UPDATE_BOARD_TASK_ATTRIBUTES,
    keyOfAttributeUpdated,
    updateTaskAttributeParams,
    emailSelected,
  });
  const data = yield call(updateTaskAttributes, updateTaskAttributeParams);
  const newTagId =
    keyOfAttributeUpdated === 'task_tags' &&
    !updateTaskAttributeParams.is_delete
      ? { newTagId: data.tag_id }
      : {};

  if (data.status) {
    if (emailSelected.email_selected) {
      yield put({
        //the emails are in task sagas
        type: SUCCESS_UPDATE_TASK_ATTRIBUTES_OF_TASKS,
        data: data.tasks,
        emailSelected: emailSelected.email_selected,
        params: {
          ...updateTaskAttributeParams,
          tasksCount: data.tasks_count,
          ...newTagId,
        }, // if params is task status we'll have to update task icon as well
        url: '/site',
        movedCards: movedCards,
        method: updateTaskAttributeParams.method,
        status: updateTaskAttributeParams.value,
        filterWp: localStorage.getItem('wpFilter'),
        filterCollab: localStorage.getItem('urlFilter'),
        filterImg: localStorage.getItem('imageFilter'),
        site_order: localStorage.getItem('siteSortingBoard'),
      });
    } else {
      yield put({
        type: SUCCESS_UPDATE_TASK_ATTRIBUTES,
        data: data.tasks,
        emailSelected: emailSelected.email_selected,
        params: {
          ...updateTaskAttributeParams,
          tasksCount: data.tasks_count,
          ...newTagId,
        }, // if params is task status we'll have to update task icon as well
        url: '/site',
        filterWp: localStorage.getItem('wpFilter'),
        filterCollab: localStorage.getItem('urlFilter'),
        filterImg: localStorage.getItem('imageFilter'),
        site_order: localStorage.getItem('siteSortingBoard'),
      });
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_TASK_ATTRIBUTES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateTaskAttributesBoardSagas() {
  yield takeEvery(UPDATE_TASK_ATTRIBUTES, postTaskAttributes);
}

function* deleteTaskWorker({ taskIds, websiteId }) {
  const payload = {
    task_id: taskIds,
  };
  const response = yield call(deleteMultiTasks, payload);
  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_TASK,
      message: response.message || 'Task deleted!',
      websiteId,
      data: response.data.id,
      remainingTasks: response.data.remaining_tasks,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_TASK_ATTRIBUTES,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteTaskInBoardWatcher() {
  yield takeEvery(DELETE_TASK, deleteTaskWorker);
}

export function* saveNewBoardTaskWorker(
  urgency,
  status,
  users,
  page,
  message,
  screenWidth,
  screenHeight,
  browserName,
  browserVersion,
  deviceName,
  internal,
  responsible_user,
  site_id
) {
  const data = yield call(saveGeneralTask, {
    users,
    urgency,
    status,
    page,
    message,
    screenWidth,
    screenHeight,
    browserName,
    browserVersion,
    deviceName,
    internal,
    responsible_user,
    site_id,
  });
  if (data.status) {
    const websiteId = yield call(getSelectedWebsiteId);
    yield put({
      type: SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK,
      task_id: data.data.result,
      websiteId,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SAVE_NEW_BOARD_GENERAL_TASK,
      message:
        Object.keys(data.message)
          .map((key) => data.message[key])
          .join('\n') || 'Something went wrong, try again later!',
    });
  }
}

export function* saveNewBoardTaskWatcher() {
  while (true) {
    const {
      urgency,
      status,
      users,
      page,
      message,
      screenWidth,
      screenHeight,
      browserName,
      browserVersion,
      deviceName,
      internal,
      responsible_user,
      site_id,
    } = yield take(SAVE_NEW_BOARD_GENERAL_TASK);
    yield fork(
      saveNewBoardTaskWorker,
      urgency,
      status,
      users,
      page,
      message,
      screenWidth,
      screenHeight,
      browserName,
      browserVersion,
      deviceName,
      internal,
      responsible_user,
      site_id
    );
    const action = yield take([
      SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK,
      LOGOUT,
      ERROR_SAVE_NEW_BOARD_GENERAL_TASK,
    ]);
    if (action.type === SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK) {
      yield fork(sendMessageNewBoardTaskWorker, {
        data: {
          task_id: action.task_id,
          comment_content: message,
          is_note: false,
          comment_type: 'normal_text',
        },
        boardParams: {
          urgency: urgency,
          status: status,
        },
      });

      const messageStatus = yield take([
        MESSAGE_SENT_NEW_BOARD_TASK,
        LOGOUT,
        ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK,
      ]);
      if (messageStatus.type === MESSAGE_SENT_NEW_BOARD_TASK) {
        const websiteId = yield call(getSelectedWebsiteId);
        yield put({
          type: NEW_BOARD_GENERAL_TASK_SAVED_WITH_MESSAGE,
          websiteId,
        });
      } else {
        // yield put();
      }
    }
  }
}

function* sendMessageNewBoardTaskWorker(params) {
  const data = yield call(sendMessage, params.data);
  if (data.status) {
    yield put({
      type: MESSAGE_SENT_NEW_BOARD_TASK,
      data: data,
      boardParams: params.boardParams,
      message: 'New task created!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* searchBoardTasksWatcher() {
  yield takeLatest(START_SEARCH_BOARD, searchTasksWorker);
}

export function* searchTasksWorker(action) {
  const { searchText, searchType } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const url = getFilterUrl(true); // will give me all tasks URL
  const response = yield call(
    filterTasks,
    url,
    {
      ...fetchInitFilterObject(),
      task_title_keyword: searchText,
      search_type: searchType,
    },
    selectedWorkspace?.id
  );
  if (response.status) {
    yield put({
      type: SUCCESS_SEARCH_BOARD,
      tasks: response.data,
      moreRecords: response.more_records,
      totalRecords: response.total_records,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SEARCH_BOARD,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateBoardTasksOrderWatcher() {
  yield takeLatest(START_UPDATE_BOARD_TASK_ORDER, updateBoardTasksOrderWorker);
}

function* updateBoardTasksOrderWorker({ updateTaskOrderParams }) {
  const response = yield call(updateBoardTaskOrders, updateTaskOrderParams);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_BOARD_TASK_ORDER,
      message: 'task order updated successfully',
    });
    // yield put({
    //     type: "FETCH_ALL_BOARD_TASKS"
    // });
  } else if (response.unauthenticated) {
    // earlier I did not test for this condition,  maybe I had forgotten
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_BOARD_TASK_ORDER,
      message: 'Something went wrong, try again later!',
    });
  }
}

function* fetchBoardTaskStatusWorker(params) {
  const mapColumnsToActions = {
    open: SUCCESS_BOARD_STATUS_OPEN_TASKS,
    'in-progress': SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS,
    'pending-review': SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS,
    complete: SUCCESS_BOARD_STATUS_COMPLETE_TASKS,
  };
  const data = yield call(fetchBoardColumnTask, params);
  if (data.status) {
    yield put({
      type: mapColumnsToActions[params.column_type],
      tasks: data.data,
      moreRecords: data.more_records,
      totalRecords: data.total_records,
      totalColumnRecords: data.total_column_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    if (params.column_type === 'open') {
      yield put({
        type: ERROR_BOARD_STATUS_TASKS, //Change this error
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  }
}
function* fetchNextBoardTaskStatusWorker(action) {
  const { params } = action;
  const mapColumnsToActions = {
    open: SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS,
    'in-progress': SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS,
    'pending-review': SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS,
    complete: SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS,
  };
  const data = yield call(fetchNextBoardTaskStatus, params);
  if (data.status) {
    yield put({
      type: mapColumnsToActions[params.columnType],
      tasks: data.data,
      moreRecords: data.more_records,
      totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_BOARD_STATUS_TASKS, //Change this error
      // message: data.message || "Something went wrong, try again later!"
    });
  }
}
export function* fetchNextBoardTaskStatusWatcher() {
  yield takeLatest(
    FETCH_NEXT_BOARD_STATUS_TASKS,
    fetchNextBoardTaskStatusWorker
  );
}
function* fetchBoardTaskUrgencyWorker(params) {
  const mapUrgencyActionToBoard = {
    low: SUCCESS_BOARD_URGENCY_TASKS,
    medium: SUCCESS_BOARD_URGENCY_MEDIUM_TASKS,
    high: SUCCESS_BOARD_URGENCY_HIGH_TASKS,
    critical: SUCCESS_BOARD_URGENCY_CRITICAL_TASKS,
  };

  const data = yield call(fetchBoardTaskLowUrgency, params);
  if (data.status) {
    yield put({
      type: mapUrgencyActionToBoard[params.column_type],
      tasks: data.data,
      moreRecords: data.more_records,
      totalRecords: data.total_records,
      totalColumnRecords: data.total_column_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_BOARD_URGENCY_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}
export function* fetchBoardTaskUrgencyWatcher() {
  yield takeLatest(FETCH_BOARD_URGENCY_TASKS, fetchBoardTaskUrgencyWorker);
}
function* fetchNextBoardTaskUrgencyWorker(action) {
  const { params } = action;
  const mapUrgencyActionToBoard = {
    low: SUCCESS_BOARD_NEXT_URGENCY_TASKS,
    medium: SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS,
    high: SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS,
    critical: SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS,
  };
  const data = yield call(fetchNextBoardTaskUrgency, params);
  if (data.status) {
    yield put({
      type: mapUrgencyActionToBoard[params.columnType],
      tasks: data.data,
      moreRecords: data.more_records,
      totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_BOARD_URGENCY_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}
export function* fetchNextBoardTaskUrgencyWatcher() {
  yield takeLatest(
    FETCH_NEXT_BOARD_URGENCY_TASKS,
    fetchNextBoardTaskUrgencyWorker
  );
}

function* fetchBoardColumnWorker(action) {
  const { params } = action;

  const isStatusTask = {
    open: true,
    'in-progress': true,
    'pending-review': true,
    complete: true,
  };

  const isUrgencyTask = {
    low: true,
    medium: true,
    high: true,
    critical: true,
  };

  const taskYields = [];
  for (let datum of params.data) {
    if (!params.emailSelected) {
      if (isStatusTask[datum.column_type]) {
        const task = yield fork(fetchBoardTaskStatusWorker, {
          websiteId: datum.websiteId,
          task_category: 'task_status',
          column_type: datum.column_type,
        });

        taskYields.push(task);
      }

      if (isUrgencyTask[datum.column_type]) {
        const task = yield fork(fetchBoardTaskUrgencyWorker, {
          websiteId: datum.websiteId,
          task_category: 'task_priority',
          column_type: datum.column_type,
        });
        taskYields.push(task);
      }
    }
    yield join(taskYields);
  }
}

export function* fetchBoardColumnWatcher() {
  yield takeLatest(FETCH_BOARD_COLUMN, fetchBoardColumnWorker);
}

// Delete Board comment Files
function* deleteBoardCommentFilesWorker(action) {
  const { data } = action;
  const response = yield call(deleteCommentFiles, data);
  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_BOARD_COMMENT_FILE,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_DELETE_BOARD_COMMENT_FILE,
      error: response.message,
    });
  }
}
export function* deleteBoardCommentFilesWatcher() {
  yield takeLatest(DELETE_BOARD_COMMENT_FILE, deleteBoardCommentFilesWorker);
}
