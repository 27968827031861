import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// Actions
import { START_FETCH_DASHBOARD_DATA } from 'actions/dashboard';
import {
  ACTIVATE_TRIAL,
  BILLING_INFO,
  CHECK_ALL_NOTIFICATIONS,
  CLOSE_TRIAL_WIZARD,
  CONFIRM_SINGLE_USER_TWO_FA,
  CUSTOMER_INVOICES,
  ERROR_ACTIVATE_TRIAL,
  ERROR_BILLING_INFO,
  ERROR_CHANGE_PASSWORD,
  ERROR_CHECK_ALL_NOTIFICATIONS,
  ERROR_CONFIRM_SINGLE_USER_TWO_FA,
  ERROR_CUSTOMER_INVOICES,
  ERROR_FETCH_EXISTING_SITE_URL,
  ERROR_FORGOT_PASSWORD,
  ERROR_GET_NOTIFICATIONS,
  ERROR_GET_NOTIFICATIONS_COUNT,
  ERROR_GET_NOTIFICATIONS_DETAILS,
  ERROR_GET_READ_NOTIFICATIONS,
  ERROR_GET_UNREAD_NOTIFICATIONS,
  ERROR_INVOICE_DOWNLOAD_URL,
  ERROR_LICENSE_ACTIVATION,
  ERROR_MUTE_UNMUTE_NOTIFICATIONS,
  ERROR_OPEN_CHECKOUT,
  ERROR_PORTAL_SESSION,
  ERROR_POST_TRAIL_DETAIL,
  ERROR_PROFILE_FETCH,
  ERROR_REJECT_TRIAL,
  ERROR_REVOKE_USER_ACCESS,
  ERROR_SEND_TWO_FACTOR_CODE,
  ERROR_SUBSCRIPTION_DETAILS,
  ERROR_UPDATE_USER_NOTIFICATION,
  ERROR_UPGRADE_PLANS,
  ERROR_VERIFY_CODE,
  FETCH_EXISTING_SITE_URL,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
  GET_NOTIFICATIONS_DETAILS,
  GET_READ_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  INVOICE_DOWNLOAD_URL,
  IS_NEW_USER,
  LICENSE_ACTIVATION,
  LOGOUT,
  MUTE_UNMUTE_NOTIFICATIONS,
  OPEN_CHECKOUT,
  PORTAL_SESSION,
  POST_TRAIL_DETAIL,
  REJECT_TRIAL,
  REVOKE_USER_ACCESS,
  SEND_TWO_FACTOR_CODE,
  START_CHANGE_PASSWORD,
  START_FORGOT_PASSWORD,
  START_PROFILE_FETCH,
  SUBSCRIPTION_DETAILS,
  SUCCESS_ACTIVATE_TRIAL,
  SUCCESS_BILLING_INFO,
  SUCCESS_CHANGE_PASSWORD,
  SUCCESS_CHECK_ALL_NOTIFICATIONS,
  SUCCESS_CLOSE_TRIAL_WIZARD,
  SUCCESS_CONFIRM_SINGLE_USER_TWO_FA,
  SUCCESS_CUSTOMER_INVOICES,
  SUCCESS_FETCH_EXISTING_SITE_URL,
  SUCCESS_FORGOT_PASSWORD,
  SUCCESS_GET_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS_COUNT,
  // SUCCESS_GET_FILTRED_NOTIFICATIONS,
  // ERROR_GET_FILTRED_NOTIFICATIONS,
  SUCCESS_GET_NOTIFICATIONS_DETAILS,
  SUCCESS_GET_READ_NOTIFICATIONS,
  SUCCESS_GET_UNREAD_NOTIFICATIONS,
  SUCCESS_INVOICE_DOWNLOAD_URL,
  SUCCESS_LICENSE_ACTIVATION,
  SUCCESS_MUTE_UNMUTE_NOTIFICATIONS,
  SUCCESS_OPEN_CHECKOUT,
  SUCCESS_PORTAL_SESSION,
  SUCCESS_POST_TRAIL_DETAIL,
  SUCCESS_PROFILE_FETCH,
  SUCCESS_REJECT_TRIAL,
  SUCCESS_REVOKE_USER_ACCESS,
  SUCCESS_SEND_TWO_FACTOR_CODE,
  SUCCESS_SUBSCRIPTION_DETAILS,
  SUCCESS_UPDATE_USER_NOTIFICATION,
  SUCCESS_UPGRADE_PLANS,
  SUCCESS_USER_AUTHENTICATE,
  SUCCESS_VERIFY_CODE,
  UPDATE_USER_NOTIFICATION,
  UPGRADE_PLANS,
  VERIFY_CODE,
} from 'actions/user';
import {
  SET_SELECTED_WORKSPACE,
  SUCCESS_FETCH_WORKSPACES,
} from 'actions/workspace';
import {
  activateTrial,
  changePassword,
  checkAllNotifications,
  confrimSingleUserTwoFa,
  createPortalSession,
  deleteUserAccess,
  fetchExistingUrl,
  fetchProfile,
  forgotPasswordRequest,
  getAllnotifications,
  getBillingInfo,
  getInvoiceUrl,
  getInvoices,
  getNotificationDetails,
  getSubscriptionDetails,
  getUpgradePlans,
  licenseActivate,
  muteUnmuteNotifications,
  notifications,
  openCheckout,
  // gettrailDetails,
  postTrailDetails,
  rejectTrial,
  sendCode,
  unReadNotificationsCount,
  updateNotification,
  verifyCode,
} from '../api/user';
import { getWorkspaces } from '../api/workspace';
// Utils
import API from '../api';
import { sendMessageToExtension } from 'utils/chromeExtension';
import { LogoutObjectForPutEffect } from 'utils/constants';
import { getSelectedWorkspace } from 'utils/functions';

// export function* resetPasswordSaga() {
//   while (true) {
//     const action = yield take(START_RESET_PASSWORD);
//     const data = yield call(resetPassword, action.url, action.paramsObject);
//     if (typeof data !== 'undefined' && data.status) {
//       yield call(API.setUserLoggedIn);
//       // check if the user has multiple workspaces or not and redirect accordingly
//       const workspacesResponse = yield call(getWorkspaces);

//       if (workspacesResponse.status || workspacesResponse?.data?.length > 0) {
//         yield put({
//           type: SUCCESS_FETCH_WORKSPACES,
//           data: workspacesResponse?.data,
//         });
//       }

//       if (workspacesResponse?.data.length > 1) {
//         yield put({
//           type: SUCCESS_RESET_PASSWORD,
//           message: 'Password successfully reset! Redirecting to Dashoard',
//           token: data.access_token,
//         });
//         // if the user has multiple workspaces then redirect user to the workspace page
//         redirect('/workspaces-login');
//       } else {
//         // log user out because API sent no workspaces
//         if (workspacesResponse?.data.length === 0) {
//           yield put({
//             type: LOGOUT,
//             error: true,
//             message:
//               'No workspace found for this user, please contact support!',
//           });
//           localStorage.removeItem('token');

//           return;
//         }

//         // means the user has only one workspace so save it
//         localStorage.setItem(
//           'selectedWorkspace',
//           JSON.stringify(workspacesResponse?.data[0])
//         );
//         yield put({
//           type: SET_SELECTED_WORKSPACE,
//           workspace: workspacesResponse?.data[0],
//         });
//       }
//       yield put({
//         type: SUCCESS_RESET_PASSWORD,
//         message: 'Password successfully reset! Redirecting to Dashoard',
//         token: data.access_token,
//       });
//     } else {
//       yield put({
//         type: ERROR_RESET_PASSWORD,
//         message: data.message || 'Password not reset, something went wrong!',
//       });
//     }
//   }
// }

function* execForgotPassword(email) {
  const response = yield call(forgotPasswordRequest, email);
  if (response.status) {
    yield put({
      type: SUCCESS_FORGOT_PASSWORD,
      message: response.message || 'Email sent, please check your email!',
    });
  } else {
    yield put({
      type: ERROR_FORGOT_PASSWORD,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* forgotPassword() {
  while (true) {
    const { email } = yield take(START_FORGOT_PASSWORD);
    yield fork(execForgotPassword, email);
  }
}

/* Below sagas deal with user's profile page */
function* profileAPI() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(fetchProfile, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: SUCCESS_PROFILE_FETCH,
      userData: response.data,
    });
    yield put({
      type: IS_NEW_USER,
      isNewUser: true,
    });
  } else {
    yield put({
      type: ERROR_PROFILE_FETCH,
      message: response.message,
    });
  }
}

export function* fetchProfileData() {
  while (true) {
    yield take(START_PROFILE_FETCH);
    yield fork(profileAPI); //calling API
    yield take([LOGOUT, ERROR_PROFILE_FETCH, SUCCESS_PROFILE_FETCH]);
  }
}

export function* userChangePasswordWatcher() {
  yield takeEvery(START_CHANGE_PASSWORD, userChangePasswordWorker);
}

function* userChangePasswordWorker(action) {
  const { passwordData } = action;
  const response = yield call(changePassword, passwordData);
  if (response.status) {
    yield put({
      type: SUCCESS_CHANGE_PASSWORD,
      message: response.message,
    });
  } else if (response.unauthenticated) {
    // earlier I did not test for this condition,  maybe I had forgotten
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_CHANGE_PASSWORD,
      message: response.message,
    });
  }
}

function* licenseActivationWorker(action) {
  const { params } = action;

  const response = yield call(licenseActivate, params);
  if (response.status) {
    yield put({
      type: SUCCESS_LICENSE_ACTIVATION,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_LICENSE_ACTIVATION,
      message: response.message,
    });
  }
}

export function* licenseActivationWatcher() {
  yield takeLatest(LICENSE_ACTIVATION, licenseActivationWorker);
}

// Notifications Sagas
function* getReadNotificationsWorker(action) {
  const { offset } = action;
  const response = yield call(getAllnotifications, true, offset);
  if (response.status) {
    yield put({
      type: SUCCESS_GET_READ_NOTIFICATIONS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_GET_READ_NOTIFICATIONS,
      message: response.message
        ? response.message
        : 'Can not get Notifications',
    });
  }
}

export function* getReadNotificationsWatcher() {
  yield takeLatest(GET_READ_NOTIFICATIONS, getReadNotificationsWorker);
}

function* getUnreadNotifications(action) {
  const { offset } = action;
  const response = yield call(getAllnotifications, false, offset);
  if (response.status) {
    yield put({
      type: SUCCESS_GET_UNREAD_NOTIFICATIONS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_GET_UNREAD_NOTIFICATIONS,
      message: response.message
        ? response.message
        : 'Can not get Notifications',
    });
  }
}

export function* getUnreadNotificationsWatcher() {
  yield takeLatest(GET_UNREAD_NOTIFICATIONS, getUnreadNotifications);
}

function* getFiltredNotificationsWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(notifications, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: SUCCESS_GET_NOTIFICATIONS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_GET_NOTIFICATIONS,
      message: response.message
        ? response.message
        : 'Can not get Filtred Notifications',
    });
  }
}

export function* getFiltredNotificationsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS, getFiltredNotificationsWorker);
}

function* getNotificaionDetailsWorker(action) {
  const { params } = action;
  const response = yield call(getNotificationDetails, params);
  if (response.status) {
    yield put({
      type: SUCCESS_GET_NOTIFICATIONS_DETAILS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_GET_NOTIFICATIONS_DETAILS,
      message: response.message
        ? response.message
        : 'Can not get Notification Details',
    });
  }
}

export function* getNotificaionDetailsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS_DETAILS, getNotificaionDetailsWorker);
}

function* updateNotificationWorker(action) {
  const { id, markAllAsRead } = action;
  const markAsRead = { mark_as_read: markAllAsRead };
  const response = yield call(updateNotification, id, markAsRead);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_USER_NOTIFICATION,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_USER_NOTIFICATION,
      message: response.message
        ? response.message
        : 'Can not update notification',
    });
  }
}

export function* updateNotificationWatcher() {
  yield takeLatest(UPDATE_USER_NOTIFICATION, updateNotificationWorker);
}

function* checkAllNotificationsWorker(action) {
  const { param } = action;
  const response = yield call(checkAllNotifications, param);
  if (response.status) {
    yield put({
      type: SUCCESS_CHECK_ALL_NOTIFICATIONS,
      data: param,
    });
  } else {
    yield put({
      type: ERROR_CHECK_ALL_NOTIFICATIONS,
      message: response.message
        ? response.message
        : 'Can not update notification',
    });
  }
}

export function* checkAllNotificationsWatcher() {
  yield takeLatest(CHECK_ALL_NOTIFICATIONS, checkAllNotificationsWorker);
}

function* unReadNotificationsCountWorker() {
  const response = yield call(unReadNotificationsCount);
  if (response.status) {
    yield put({
      type: SUCCESS_GET_NOTIFICATIONS_COUNT,
      data: response.total,
    });
  } else {
    yield put({
      type: ERROR_GET_NOTIFICATIONS_COUNT,
      message: response.message
        ? response.message
        : 'Can not update notification',
    });
  }
}

export function* unReadNotificationsCountWatcher() {
  yield takeLatest(GET_NOTIFICATIONS_COUNT, unReadNotificationsCountWorker);
}

function* muteUnmuteNotificationsWorker(action) {
  const { param } = action;
  const response = yield call(muteUnmuteNotifications, param);
  if (response.status) {
    yield put({
      type: SUCCESS_MUTE_UNMUTE_NOTIFICATIONS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_MUTE_UNMUTE_NOTIFICATIONS,
      message: response.message
        ? response.message
        : 'Can not update notification',
    });
  }
}

export function* muteUnmuteNotificationsWatcher() {
  yield takeLatest(MUTE_UNMUTE_NOTIFICATIONS, muteUnmuteNotificationsWorker);
}

// Plans Sagas
function* getUpgradePlansWorker(action) {
  const { params } = action;
  const response = yield call(getUpgradePlans, params);
  if (response.status) {
    yield put({
      type: SUCCESS_UPGRADE_PLANS,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_UPGRADE_PLANS,
      message: response.message,
    });
  }
}

export function* getUpgradePlansWatcher() {
  yield takeLatest(UPGRADE_PLANS, getUpgradePlansWorker);
}

function* portalSessionWorker(action) {
  const { id } = action;
  const response = yield call(getInvoiceUrl, id);
  if (response.status) {
    yield put({
      type: SUCCESS_INVOICE_DOWNLOAD_URL,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_INVOICE_DOWNLOAD_URL,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* portalSessionWatcher() {
  yield takeLatest(INVOICE_DOWNLOAD_URL, portalSessionWorker);
}

function* openCheckoutWorker(action) {
  const { params } = action;
  const response = yield call(openCheckout, params);
  if (response) {
    yield put({
      type: SUCCESS_OPEN_CHECKOUT,
      data: response,
    });
  } else {
    yield put({
      type: ERROR_OPEN_CHECKOUT,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* revokeAccessWatcher() {
  yield takeLatest(REVOKE_USER_ACCESS, revokeAccessWorker);
}

function* revokeAccessWorker(action) {
  const { user_id, workspace_id } = action.payload;
  const response = yield call(deleteUserAccess, { user_id, workspace_id });
  if (response.status) {
    yield put({
      type: SUCCESS_REVOKE_USER_ACCESS,
      payload: { user_id, workspace_id },
    });
  } else {
    yield put({
      type: ERROR_REVOKE_USER_ACCESS,
    });
  }
}

export function* openCheckoutWatcher() {
  yield takeLatest(OPEN_CHECKOUT, openCheckoutWorker);
}

function* getSubscriptionDetailsWorker(action) {
  const { id, rawId, users } = action;
  const response = yield call(getSubscriptionDetails, id, rawId, users);
  if (response) {
    yield put({
      type: SUCCESS_SUBSCRIPTION_DETAILS,
      data: response,
    });
    // to update the invoices data
    yield put({
      type: CUSTOMER_INVOICES,
    });
    yield put({
      type: BILLING_INFO,
    });
  } else {
    yield put({
      type: ERROR_SUBSCRIPTION_DETAILS,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* getSubscriptionDetailsWatcher() {
  yield takeLatest(SUBSCRIPTION_DETAILS, getSubscriptionDetailsWorker);
}

function* getBillingInfoWorker(action) {
  const { id } = action;
  const response = yield call(getBillingInfo, id);
  if (response.status) {
    yield put({
      type: SUCCESS_BILLING_INFO,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_BILLING_INFO,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* getBillingInfoWatcher() {
  yield takeLatest(BILLING_INFO, getBillingInfoWorker);
}

function* getInvoicesWorker() {
  const response = yield call(getInvoices);
  if (response.status) {
    yield put({
      type: SUCCESS_CUSTOMER_INVOICES,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_CUSTOMER_INVOICES,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* getInvoicesWatcher() {
  yield takeLatest(CUSTOMER_INVOICES, getInvoicesWorker);
}

function* createPortalSessionWorker(action) {
  const { params } = action;
  const response = yield call(createPortalSession);
  if (response) {
    yield put({
      type: SUCCESS_PORTAL_SESSION,
      data: response,
      popupType: params,
    });
  } else {
    yield put({
      type: ERROR_PORTAL_SESSION,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* createPortalSessionWatcher() {
  yield takeLatest(PORTAL_SESSION, createPortalSessionWorker);
}

function* postTrailWorker(action) {
  const { params } = action;
  const response = yield call(postTrailDetails, params);
  if (response) {
    yield put({
      type: SUCCESS_POST_TRAIL_DETAIL,
      data: response,
    });
  } else {
    yield put({
      type: ERROR_POST_TRAIL_DETAIL,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* postTrailWatcher() {
  yield takeLatest(POST_TRAIL_DETAIL, postTrailWorker);
}

function* activateTrialWorker(action) {
  const { params } = action;
  const response = yield call(activateTrial, params);
  yield put({
    type: START_FETCH_DASHBOARD_DATA,
    selectedWebsite: '',
    url: '/site',
  });
  if (response.status) {
    yield put({
      type: SUCCESS_ACTIVATE_TRIAL,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_ACTIVATE_TRIAL,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* activateTrialWatcher() {
  yield takeLatest(ACTIVATE_TRIAL, activateTrialWorker);
}

function* rejectTrialWorker(action) {
  const { params } = action;
  const response = yield call(rejectTrial, params);
  if (response.status) {
    yield put({
      type: SUCCESS_REJECT_TRIAL,
      data: response.trial_rejected_at,
    });
  } else {
    yield put({
      type: ERROR_REJECT_TRIAL,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* rejectTrialWatcher() {
  yield takeLatest(REJECT_TRIAL, rejectTrialWorker);
}

function* fetchExistingSiteUrlWorker() {
  const response = yield call(fetchExistingUrl);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_EXISTING_SITE_URL,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_EXISTING_SITE_URL,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchExistingSiteUrlWatcher() {
  yield takeLatest(FETCH_EXISTING_SITE_URL, fetchExistingSiteUrlWorker);
}
function* closeWizardWorker(action) {
  const { params } = action;
  const response = yield call(rejectTrial, params);
  if (response.status) {
    yield put({
      type: SUCCESS_CLOSE_TRIAL_WIZARD,
      data: response,
    });
  } else {
    // yield put({
    //     type: ERROR_REJECT_TRIAL,
    //     message: response.message || "Something went wrong, try again later!",
    // });
  }
}
export function* closeWizardWatcher() {
  yield takeLatest(CLOSE_TRIAL_WIZARD, closeWizardWorker);
}

function* sendTwoFactorWorker(action) {
  const { params } = action;
  const response = yield call(sendCode, params);
  if (response.status) {
    yield put({
      type: SUCCESS_SEND_TWO_FACTOR_CODE,
      data: response.data,
      message: response.result,
    });
  } else {
    yield put({
      type: ERROR_SEND_TWO_FACTOR_CODE,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}
export function* sendTwoFactorWatcher() {
  yield takeLatest(SEND_TWO_FACTOR_CODE, sendTwoFactorWorker);
}

function* verifyCodeWorker(action) {
  const { params, customType, customFrom, customUrl, planSearch, history } =
    action;
  const response = yield call(verifyCode, params);
  if (response.status) {
    yield put({
      type: SUCCESS_VERIFY_CODE,
      data: response.data,
      message: response.result,
    });
    if (history) {
      yield call(API.setUserLoggedIn);
      if (customType === 'one-click' || customType === 'standard_flow') {
        window.location.href = `/fetching?_from=${customFrom}&url=${customUrl}`;
      } else if (customType === 'whmcs' || customType === 'chrome') {
        window.location.href = `/fetching?_from=${customType}`;
      } else {
        // send token to extension to login
        sendMessageToExtension({
          apiToken: yield call(API.getAccessTokenFromLocalStorage),
        });

        // check if the user has multiple workspaces
        const workspacesResponse = yield call(getWorkspaces);

        if (workspacesResponse.status || workspacesResponse?.data?.length > 0) {
          yield put({
            type: SUCCESS_USER_AUTHENTICATE,
            token: yield call(API.getAccessTokenFromLocalStorage),
          });
          yield put({
            type: SUCCESS_FETCH_WORKSPACES,
            data: workspacesResponse?.data,
          });
        }

        if (workspacesResponse?.data.length > 1) {
          yield put({
            type: SUCCESS_USER_AUTHENTICATE,
            token: yield call(API.getAccessTokenFromLocalStorage),
          });
          // if the user has multiple workspaces then redirect user to the workspace page
          history.push(`/workspaces-login${planSearch ? planSearch : ''}`);
        } else {
          // log user out because API sent no workspaces
          if (workspacesResponse?.data.length === 0) {
            // history.push("/logout");
            yield put({
              type: LOGOUT,
              error: true,
              message:
                'No workspace found for this user, please contact support!',
            });
            return;
          }

          // means the user has only one workspace so save it
          localStorage.setItem(
            'selectedWorkspace',
            JSON.stringify(workspacesResponse?.data[0])
          );
          yield put({
            type: SET_SELECTED_WORKSPACE,
            workspace: workspacesResponse?.data[0],
          });

          yield put({
            type: SUCCESS_USER_AUTHENTICATE,
            token: yield call(API.getAccessTokenFromLocalStorage),
          });
          if (planSearch) {
            // if the user came from pricing page so navigate user to dashboard
            history.push(`/dashboard${planSearch}`);
          }
        }
      }
    }
  } else {
    yield put({
      type: ERROR_VERIFY_CODE,
      message:
        response?.result ||
        response?.data?.message ||
        'Something went wrong, try again later!',
    });
  }
}
export function* verifyCodeWatcher() {
  yield takeLatest(VERIFY_CODE, verifyCodeWorker);
}

function* authenticateWorker() {
  // remove guest token
  localStorage.removeItem('token');
}

export function* authenticateWatcher() {
  yield takeLatest(SUCCESS_USER_AUTHENTICATE, authenticateWorker);
}

function* confirmUserTwoFaWorker(action) {
  const { params } = action;
  const response = yield call(confrimSingleUserTwoFa, params);
  if (response.status === 200) {
    yield put({
      type: SUCCESS_CONFIRM_SINGLE_USER_TWO_FA,
      data: true,
      message: response?.result,
    });
  } else {
    yield put({
      type: ERROR_CONFIRM_SINGLE_USER_TWO_FA,
      message:
        response?.data?.message || 'Something went wrong, try again later!',
    });
  }
}

export function* confirmUserTwoFaWatcher() {
  yield takeLatest(CONFIRM_SINGLE_USER_TWO_FA, confirmUserTwoFaWorker);
}
