import { LOGOUT } from 'actions/user';
import {
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  EDIT_WORKSPACE,
  EMPTY_WORKSPACE_MESSAGE,
  ERROR_CREATE_WORKSPACE,
  ERROR_DELETE_WORKSPACE,
  ERROR_EDIT_WORKSPACE,
  ERROR_FETCH_ACCOUNT_USERS,
  ERROR_FETCH_NEXT_ACCOUNT_USERS,
  ERROR_FETCH_WORKSPACES,
  FETCH_ACCOUNT_USERS,
  FETCH_NEXT_ACCOUNT_USERS,
  FETCH_WORKSPACES,
  REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT,
  SET_SELECTED_WORKSPACE,
  SET_SELECTED_WORKSPACE_SETTINGS,
  SUCCESS_CREATE_WORKSPACE,
  SUCCESS_DELETE_WORKSPACE,
  SUCCESS_EDIT_WORKSPACE,
  SUCCESS_FETCH_ACCOUNT_USERS,
  SUCCESS_FETCH_NEXT_ACCOUNT_USERS,
  SUCCESS_FETCH_WORKSPACES,
  SUCCESS_FETCH_WORKSPACE_OWNER,
  UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT,
} from 'actions/workspace';

const initState = {
  workspaceData: [],
  dataIsLoading: false,
  errorMessage: '',
  createSuccess: '',
  workspaceSuccess: '',
  selectedWorkspace: '',
  accountUsers: [],
  errorUsersListMessage: '',
  isFetchingUsers: false,
  moreUsersRecord: '',
  selectedWorkspaceSetting: '',
  highlightedId: '',
  accessToggleSuccess: '',
  ownerList: [],
  upgradeModal: false,
};

const workspace = (previousState = initState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACES:
      return {
        ...previousState,
        dataIsLoading: true,
        workspaceData: [],
      };
    case SUCCESS_FETCH_WORKSPACES:
      return {
        ...previousState,
        dataIsLoading: false,
        workspaceData: action.data,
      };
    case ERROR_FETCH_WORKSPACES:
      return {
        ...previousState,
        dataIsLoading: false,
        workspaceData: [],
        // errorMessage: action.message,
      };
    case CREATE_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: true,
        workspaceSuccess: '',
      };
    case SUCCESS_CREATE_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: false,
        workspaceData: previousState.workspaceData.concat(action.data),
        workspaceSuccess: action.message,
      };
    case ERROR_CREATE_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: false,
        errorMessage: action.message,
      };
    case DELETE_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: true,
        createSuccess: '',
      };
    case SUCCESS_DELETE_WORKSPACE:
      let currentWorkspace = previousState.selectedWorkspace;
      let newSelected = {};
      // if the selected workspace is deleted, set the first workspace as selected
      if (currentWorkspace?.id === action.workspaceId) {
        // currentWorkspace = ""
        let findDefaultSpace = previousState.workspaceData.find(
          (each) => each?.pivot?.is_default
        );
        // Check if there is a default workspace
        if (findDefaultSpace) {
          // save the selected workspace in the local storage
          localStorage.setItem(
            'selectedWorkspace',
            JSON.stringify(findDefaultSpace)
          );
          newSelected = findDefaultSpace;
        } else {
          // Pick the first workspace if there is no default
          // save the selected workspace in the local storage
          localStorage.setItem(
            'selectedWorkspace',
            JSON.stringify(previousState.workspaceData[0])
          );
          newSelected = previousState.workspaceData[0];
        }
      } else {
        newSelected = currentWorkspace;
      }
      return {
        ...previousState,
        dataIsLoading: false,
        workspaceData: previousState.workspaceData.filter(
          (w) => action.workspaceId !== w.id
        ),
        createSuccess: action.message,
        selectedWorkspace: newSelected,
        selectedWorkspaceSetting: newSelected,
        highlightedId: newSelected?.id,
      };
    case ERROR_DELETE_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: false,
        errorMessage: action.message,
      };
    case EMPTY_WORKSPACE_MESSAGE:
      return {
        ...previousState,
        [action.keyName]: '',
      };
    case EDIT_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: true,
        createSuccess: '',
        upgradeModal: false,
      };
    case SUCCESS_EDIT_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: false,
        upgradeModal: false,
        workspaceData: previousState.workspaceData.map((w) =>
          action.data?.id === w.id
            ? {
                ...w,
                ...action?.data,
              }
            : w
        ),
        createSuccess: action.inviteRequests && 'Invitation sent successfully',
        accessToggleSuccess: action.userToggle && 'Access changed successfully',
        selectedWorkspace:
          action.data?.id === previousState?.selectedWorkspace?.id
            ? {
                ...previousState?.selectedWorkspace,
                ...action.data,
              }
            : previousState.selectedWorkspace,
        selectedWorkspaceSetting:
          action.data?.id === previousState?.selectedWorkspaceSetting?.id
            ? {
                ...previousState?.selectedWorkspaceSetting,
                ...action.data,
              }
            : previousState.selectedWorkspaceSetting,
      };
    case ERROR_EDIT_WORKSPACE:
      return {
        ...previousState,
        dataIsLoading: false,
        createSuccess: '',
        errorMessage: action?.upgradeUser ? null : action.message,
        upgradeModal: action?.upgradeUser,
      };
    case SET_SELECTED_WORKSPACE:
      return {
        ...previousState,
        selectedWorkspace: action.workspace,
      };
    case FETCH_ACCOUNT_USERS:
      return {
        ...previousState,
        accountUsers: [],
        errorUsersListMessage: '',
        isFetchingUsers: true,
      };
    case SUCCESS_FETCH_ACCOUNT_USERS:
      return {
        ...previousState,
        accountUsers: action.usersList,
        errorUsersListMessage: '',
        isFetchingUsers: false,
        moreUsersRecord: action.moreUsersRecord,
      };
    case ERROR_FETCH_ACCOUNT_USERS:
      return {
        ...previousState,
        accountUsers: [],
        errorUsersListMessage: action.message,
        isFetchingUsers: false,
      };

    case FETCH_NEXT_ACCOUNT_USERS:
      return {
        ...previousState,
        errorUsersListMessage: '',
        isFetchingUsers: true,
      };
    case SUCCESS_FETCH_NEXT_ACCOUNT_USERS:
      return {
        ...previousState,
        accountUsers: previousState.accountUsers.concat(action.usersList),
        errorUsersListMessage: '',
        isFetchingUsers: false,
        moreUsersRecord: action.moreUsersRecord,
      };
    case ERROR_FETCH_NEXT_ACCOUNT_USERS:
      return {
        ...previousState,
        errorUsersListMessage: action.message,
        isFetchingUsers: false,
      };
    case SET_SELECTED_WORKSPACE_SETTINGS:
      return {
        ...previousState,
        selectedWorkspaceSetting: action.workspace,
        highlightedId: action?.id,
      };
    case UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT:
      let selectedWorkspaceId = previousState?.selectedWorkspace?.id;
      let newWorkspaceList = previousState?.workspaceData.map((w) =>
        selectedWorkspaceId === w.id
          ? {
              ...w,
              project_count: w?.project_count + 1,
            }
          : w
      );
      return {
        ...previousState,
        workspaceData: newWorkspaceList,
      };
    case REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT:
      let selectedId = previousState?.selectedWorkspace?.id;
      let newList = previousState?.workspaceData.map((w) =>
        selectedId === w.id
          ? {
              ...w,
              project_count: w?.project_count - 1,
            }
          : w
      );
      return {
        ...previousState,
        workspaceData: newList,
      };
    case SUCCESS_FETCH_WORKSPACE_OWNER:
      return {
        ...previousState,
        ownerList: action.data,
      };
    case LOGOUT:
      return initState;
    default:
      return previousState;
  }
};

export default workspace;
