import React, { useEffect, useState } from 'react';
// Styling
import { makeStyles, Button, TextField } from '@material-ui/core';
import PlusIcon from '../PlusIcon';
import RemoveIcon from '@material-ui/icons/Remove';
import { FiGlobe } from 'react-icons/fi';
// Utils
import clsx from 'clsx';
import { BorderLinearProgress } from 'utils/constants';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
    marginTop: 20,
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    border: 'none',
    color: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  skipBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    border: 'none',
    color: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 5,
    alignItems: 'center',
  },
  formsContainer: {
    maxHeight: '30vh',
    overflow: 'auto',
  },
  form: {
    display: 'flex',
    padding: 5,
    border: '1px solid #EFF2F6',
    borderRadius: 8,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  fields: {
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formActions: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  textField: {
    margin: 5,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      '& label, input': {
        fontSize: 13,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E72D67' /* Set your desired error border color */,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#E72D67' /* Set your desired error label color */,
    },
    // '& .MuiInputBase-input::placeholder': {
    //     color: '#E72D67', /* Set your desired error placeholder color */
    // },
  },
  fieldsGroup: {
    display: 'flex',
    alignItems: 'baseline',
  },
  actionButton: {
    minWidth: 36,
    width: 36,
    height: 36,
    borderRadius: '50%',
    color: '#8791B2',
  },
  removeActionButton: {
    '& path': {
      d: `path("M 19 13 H 5 v -3 h 14 v 2 Z")`,
    },
  },
  existingWebsitesContent: {
    display: 'flex',
    border: '1px solid #EFF2F6',
    padding: '5px',
    height: '36px',
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  existingWebsitesSitename: {
    fontSize: 14,
    color: '#272D3C',
    fontWeight: '500',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  existingWebsitesHeader: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
    marginTop: 20,
  },
  existingWebsitesContainer: {
    display: 'grid',
    marginTop: '10px',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 10,
  },
  existingWebsitesType: {
    height: 27,
    width: 27,
    background: '#C3D1E3',
    borderRadius: 3,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: 17,
      width: 17,
    },
  },
  removeActionButtonPlaceholder: {
    minWidth: 45,
  },
  descCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '10px 0px 10px 0px !important',
  },
  progressContainer: {
    margin: '20px 0px',
  },
  progressBack: {
    '&:before': {
      width: '7px',
      height: '7px',
      border: '2px solid #6D5DF3',
      borderWidth: '2px 2px 0 0',
      color: '#6D5DF3',
      display: 'inline-block',
      marginRight: '2px',
      content: '""',
      marginBottom: '1px',
      '-moz-transform': '"rotate(-135deg)"',
      '-webkit-transform': 'rotate(-135deg)',
      '-o-transform': '"rotate(-135deg)"',
      '-ms-transform': '"rotate(-135deg)"',
      transform: 'rotate(-135deg)',
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

const AddWebsiteUpgrade = (props) => {
  const {
    handleNext,
    handlePrev,
    websiteData,
    handleUpdateTrial,
    handlePostTrial,
    existingWebsites,
  } = props;

  const classes = makeClasses();

  const singleFormValues = ['', '', '', '', ''];

  const [formValue, setFormValue] = useState(singleFormValues);

  const showRemoveFormButton = formValue.length > 1;

  useEffect(() => {
    if (websiteData) {
      if (websiteData.length > 0) {
        setFormValue(websiteData);
      }
    }
  }, [websiteData]);

  const handleChange = (key, index) => (e) => {
    const value = e.target.value;
    // for optimization purpose React uses a single "e"
    // directly using e.target.value sometimes can lead to "e" being null or undefined which will cause error
    setFormValue((prevFormValue) =>
      prevFormValue.map((f, i) => (i === index ? value : f))
    );
  };

  const handleAddForm = (addAfterIndex) => () => {
    setFormValue((prevFormValue) => {
      const temp = prevFormValue.concat([]);
      temp.splice(addAfterIndex + 1, 0, '');
      return temp;
    });
  };

  const handleRemoveForm = (index) => () => {
    setFormValue((prevFormValue) =>
      prevFormValue.filter((fv, i) => index !== i)
    );
  };

  const handleCheckIsvalid = () => {
    return formValue.some((value) => value !== '');
  };

  const handleInviteUsers = (e) => {
    handlePostTrial({
      websites: formValue,
    });
    handleNext();
  };

  const handleSkip = (e) => {
    handlePostTrial({
      websites: [],
    });
    handleNext();
  };

  const handleBlur = (key) => {
    handleUpdateTrial('websites', formValue);
  };

  const handleKeyDown = (event, addAfterIndex) => {
    if (event.key === 'Enter') {
      // Perform the action you want when the Enter key is pressed
      setFormValue((prevFormValue) => {
        const temp = prevFormValue.concat([]);
        temp.splice(addAfterIndex + 1, 0, '');
        return temp;
      });
    }
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        {/* <a href="https://atarim.io/team-management-for-internal-team/blog/archive-septeme" className={classes.descCopy}>
                    https://atarim.io/team-management-for-internal-team/blog/archive-septeme
                </a> */}
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={50} />
          <div className="at-cs-trial-wizard-progress-label">
            <span
              className={classes.progressBack}
              onClick={() => handlePrev(1)}
            >
              Back
            </span>
          </div>
        </div>
        <div className={classes.titleCopy}>
          Add websites to your Atarim dashboard to collaborate on
        </div>
        <div className={classes.descCopy}>
          Add all websites you're managing, whether they're one-time projects or
          ongoing collaborations, to streamline your team's workflow and enhance
          efficiency.
        </div>
        <div className={classes.formsContainer}>
          <div className="invite_form_notification">
            {formValue.map((fv, i) => (
              <>
                {
                  <div className={classes.form} key={i}>
                    <div className={'setInputHeight ' + classes.fields}>
                      <div className={classes.fieldsGroup}>
                        <TextField
                          placeholder="https://www.example.com"
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          value={fv}
                          onChange={handleChange('website', i)}
                          onBlur={() => handleBlur('website', i)}
                          onKeyDown={(e) => handleKeyDown(e, i)}
                          autoFocus={i === 0}
                        />
                      </div>
                    </div>
                    <div className={classes.formActions}>
                      <PlusIcon
                        className={classes.actionButton}
                        clickAction={handleAddForm(i)}
                      />
                      {showRemoveFormButton ? (
                        <Button
                          className="at-cs-btn square no-bg"
                          onClick={handleRemoveForm(i)}
                        >
                          <RemoveIcon />
                        </Button>
                      ) : (
                        <div className={classes.removeActionButtonPlaceholder}>
                          {' '}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </>
            ))}
          </div>
        </div>
        {existingWebsites.length > 0 && (
          <>
            <div className={classes.existingWebsitesHeader}>
              Your previously added projects
            </div>
            <div className={classes.existingWebsitesContainer}>
              {existingWebsites.slice(0, 3).map((each, id) => (
                <div className={classes.existingWebsitesContent} key={id}>
                  <div className={classes.existingWebsitesType}>
                    <FiGlobe />
                  </div>
                  <div className={classes.existingWebsitesSitename}>{each}</div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.skipBtn, classes.boldText) }}
            onClick={handleSkip}
          >
            Skip
          </Button>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.nextBtn, classes.boldText) }}
            onClick={handleInviteUsers}
            disabled={!handleCheckIsvalid()}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddWebsiteUpgrade;
