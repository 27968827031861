import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import activity from '../reducers/ActivityReducer';
import board from '../reducers/BoardReducer';
import collaborate from '../reducers/CollaborateReducer';
import dashboard from '../reducers/DashboardReducer';
import discovery_flow from '../reducers/DiscoveryFlowReducer';
import email from '../reducers/EmailReducer';
import global_settings from '../reducers/GlobalSettingsReducer';
import home from '../reducers/HomeReducer';
import projects from '../reducers/ProjectReducer';
import tasks from '../reducers/TasksReducer';
import time_entries from '../reducers/TimeEntriesReducer';
import user from '../reducers/UserReducer';
import workspace from '../reducers/WorkspaceReducer';
import rootSaga from '../sagas/index';
import { baseApi } from './api/baseApi';
// new slices
import { urlPreviewApi } from './api/urlPreviewApi';
import collaborate_new from './slices/collaborate';
import featureFlags from './slices/featureflags';
import global from './slices/global';
import inbox_new from './slices/mailbox';
import settings_new from './slices/setting';
import tasks_new from './slices/task';
import time_entries_new from './slices/time-entries';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [urlPreviewApi.reducerPath]: urlPreviewApi.reducer,
    collaborate_new,
    tasks_new,
    settings_new,
    inbox_new,
    time_entries_new,
    featureFlags,
    global,
    // Older reducers
    tasks,
    dashboard,
    user,
    activity,
    global_settings,
    board,
    home,
    time_entries,
    email,
    projects,
    collaborate,
    discovery_flow,
    workspace,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // TODO: remove these options when we have immutable state
      // or migrate all reducers to slices.
    }).concat(sagaMiddleware, baseApi.middleware, urlPreviewApi.middleware),
  devTools: {
    name: 'Atarim App',
    trace: true,
    traceLimit: 25,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

export default store;
