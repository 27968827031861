// Actions
import { EMPTY_TASKS_MESSAGE } from 'actions/tasks';
import {
  ADD_NEW_ENTRY,
  CUSTOM_DATE_FILTER,
  EMPTY_TIME_ENTRY_MESSAGE,
  ERROR_ADD_NEW_ENTRY,
  ERROR_CLICKUP_CREATE_TIME_ENTRIES,
  ERROR_CLICKUP_CREATE_TIME_ENTRY,
  ERROR_DELETE_ENTRY,
  ERROR_FETCH_ALL_ENTRIES,
  ERROR_FETCH_NEXT_ENTRIES,
  ERROR_NEXT_FILTERING_ENTRIES,
  ERROR_TEAMWORK_CREATE_TIME_ENTRIES,
  ERROR_TEAMWORK_CREATE_TIME_ENTRY,
  ERROR_UPDATE_ENTRY,
  FETCH_ALL_ENTRIES,
  FETCH_NEXT_ENTRIES,
  SELECT_TIMER_ENTRY,
  SET_CURRENT_TIMER_TASK_ID,
  SET_TIMER_PLAY,
  START_FILTERING_ENTRIES,
  START_NEXT_FILTERING_ENTRIES,
  SUCCESS_ADD_NEW_ENTRY,
  SUCCESS_CLICKUP_CREATE_TIME_ENTRIES,
  SUCCESS_CLICKUP_CREATE_TIME_ENTRY,
  SUCCESS_CLICKUP_GET_TASKS,
  SUCCESS_DELETE_ENTRY,
  SUCCESS_FETCH_ALL_ENTRIES,
  SUCCESS_FETCH_NEXT_ENTRIES,
  SUCCESS_FETCH_NOT_LOGGED_ENTRIES,
  SUCCESS_NEXT_FILTERING_ENTRIES,
  SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES,
  SUCCESS_TEAMWORK_CREATE_TIME_ENTRY,
  SUCCESS_UPDATE_ENTRY,
} from 'actions/time_entries';

const initState = {
  entriesList: [],
  loading: false,
  errorMessage: '',
  filters: {},
  successAddEntry: '',
  errorAddEntry: '',
  notLoggedEntries: [],
  selectedTimerEntry: '',
  currentTimerTaskId: '',
  timerPlay: false,
  clickupTasks: [],
  successCreateEntryOnClickup: '',
  errorCreateEntryOnClickup: '',
  successCreateEntriesOnClickup: '',
  errorCreateEntriesOnClickup: '',
  addingNewEntry: false,
  isLoadingNext: false,
  nextFiltering: false,
  customFilterData: {},
};

const time_entries = (previousState = initState, action) => {
  switch (action.type) {
    case FETCH_ALL_ENTRIES:
      return {
        ...previousState,
        loading: true,
      };
    case SUCCESS_FETCH_ALL_ENTRIES:
      return {
        ...previousState,
        loading: false,
        entriesList: action.allEntries,
        filters: action.filters,
        errorMessage: '',
        moreRecords: action.more_web_records,
        totalRecords: action.totalRecords,
        nextFiltering: action.filtering,
      };
    case ERROR_FETCH_ALL_ENTRIES:
      return {
        ...previousState,
        errorMessage: action.message,
        loading: false,
      };
    case FETCH_NEXT_ENTRIES:
      return {
        ...previousState,
        isLoadingNext: true,
        errorNextEntriesMessage: '',
      };
    case SUCCESS_FETCH_NEXT_ENTRIES:
      let newEntries = action.allEntries;
      let combinedEntries = { ...previousState.entriesList, ...newEntries };
      return {
        ...previousState,
        isLoadingNext: false,
        entriesList: combinedEntries,
        filters: action.filters,
        errorNextEntriesMessage: '',
        moreRecords: action.more_web_records,
        totalRecords: action.totalRecords,
        nextFiltering: action.filtering,
      };
    case ERROR_FETCH_NEXT_ENTRIES:
      return {
        ...previousState,
        errorNextEntriesMessage: action.message,
        isLoadingNext: false,
      };
    case START_FILTERING_ENTRIES:
      const { filterKey, filterValue } = action.filterObj;

      return {
        ...previousState,
        entriesList: [],
        loading: true,
        filters: {
          ...previousState.filters,
          [filterKey]:
            filterKey === 'users' || filterKey === 'projects'
              ? // multiple users could be checked
                previousState.filters[filterKey].map((t) =>
                  t.value === filterValue.value
                    ? { ...t, checked: filterValue.checked }
                    : t
                )
              : // only one value is checked
                previousState.filters[filterKey].map((t) =>
                  t.value === filterValue.value
                    ? { ...t, checked: filterValue.checked }
                    : { ...t, checked: false }
                ),
        },
        nextFiltering: true,
      };
    // case SUCCESS_UPDATE_ENTRY:
    //     return {
    // ...previousState,
    // notLoggedEntries: previousState.notLoggedEntries.filter(entry => entry.id !== action.entryObjId),
    // successAddEntry: action.message
    // }
    case SUCCESS_UPDATE_ENTRY:
      let newEntry = previousState.entriesList[action.selectedDate].map(
        (each) =>
          each.id === action.entryObjId
            ? {
                ...each,
                description: action?.data?.description,
                start_time: action?.data?.start_time,
                end_time: action?.data?.end_time,
                billable: action?.data?.billable,
                spent_time: action?.data?.spent_time,
              }
            : each
      );
      return {
        ...previousState,
        entriesList: {
          ...previousState.entriesList,
          [action.selectedDate]: newEntry,
        },
        successAddEntry: action.message,
      };
    case START_NEXT_FILTERING_ENTRIES:
      return {
        ...previousState,
        isLoadingNext: true,
        errorNextEntriesMessage: '',
      };
    case SUCCESS_NEXT_FILTERING_ENTRIES:
      return {
        ...previousState,
        isLoadingNext: false,
        entriesList: action.allEntries,
        filters: action.filters,
        errorNextEntriesMessage: '',
        moreRecords: action.more_web_records,
        totalRecords: action.totalRecords,
        nextFiltering: true,
      };
    case ERROR_NEXT_FILTERING_ENTRIES:
      return {
        ...previousState,
        errorNextEntriesMessage: action.message,
        isLoadingNext: false,
      };
    case CUSTOM_DATE_FILTER:
      return {
        ...previousState,
        customFilterData: action.dateObj,
      };
    case ADD_NEW_ENTRY:
      return {
        ...previousState,
        addingNewEntry: true,
      };
    case SUCCESS_ADD_NEW_ENTRY:
      return {
        ...previousState,
        successAddEntry: action.message,
        addingNewEntry: false,
      };
    case SUCCESS_DELETE_ENTRY:
      return {
        ...previousState,
        successAddEntry: action.message,
        addingNewEntry: false,
      };
    case EMPTY_TIME_ENTRY_MESSAGE:
      return {
        ...previousState,
        [action.key]: '',
      };
    case ERROR_ADD_NEW_ENTRY:
    case ERROR_UPDATE_ENTRY:
    case ERROR_DELETE_ENTRY:
      return {
        ...previousState,
        errorAddEntry: action.message,
        addingNewEntry: false,
      };
    case SUCCESS_FETCH_NOT_LOGGED_ENTRIES:
      return {
        ...previousState,
        notLoggedEntries: action.notLoggedEntries,
      };
    case EMPTY_TASKS_MESSAGE:
      return {
        ...previousState,
        [action.keyName]: '',
        taskUpdateMessage: '',
      };
    case SELECT_TIMER_ENTRY:
      return {
        ...previousState,
        selectedTimerEntry: action.id,
      };
    case SET_CURRENT_TIMER_TASK_ID:
      return {
        ...previousState,
        currentTimerTaskId: action.id,
      };
    case SET_TIMER_PLAY:
      return {
        ...previousState,
        timerPlay: action.value,
      };
    case SUCCESS_CLICKUP_CREATE_TIME_ENTRIES:
      return {
        ...previousState,
        successCreateEntriesOnClickup: action.message,
      };
    case ERROR_CLICKUP_CREATE_TIME_ENTRIES:
      return {
        ...previousState,
        errorCreateEntriesOnClickup: action.message,
      };
    case SUCCESS_TEAMWORK_CREATE_TIME_ENTRIES:
    case SUCCESS_TEAMWORK_CREATE_TIME_ENTRY:
    case SUCCESS_CLICKUP_CREATE_TIME_ENTRY:
      return {
        ...previousState,
        successCreateEntryOnClickup: action.message,
      };
    case ERROR_TEAMWORK_CREATE_TIME_ENTRIES:
    case ERROR_TEAMWORK_CREATE_TIME_ENTRY:
    case ERROR_CLICKUP_CREATE_TIME_ENTRY:
      return {
        ...previousState,
        errorCreateEntryOnClickup: action.message,
      };
    case SUCCESS_CLICKUP_GET_TASKS:
      return {
        ...previousState,
        clickupTasks: action.tasks,
      };
    default:
      return previousState;
  }
};

export default time_entries;
