export const START_USER_AUTHENTICATE = 'START_USER_AUTHENTICATE';
export const SOCIAL_USER_AUTHENTICATE = 'SOCIAL_USER_AUTHENTICATE';
export const SUCCESS_USER_AUTHENTICATE = 'SUCCESS_USER_AUTHENTICATE';
export const ERROR_USER_AUTHENTICATE = 'ERROR_USER_AUTHENTICATE';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD';
export const ERROR_RESET_PASSWORD = 'ERROR_RESET_PASSWORD';
export const EMPTY_MESSAGE = 'EMPTY_MESSAGE';
export const START_FORGOT_PASSWORD = 'START_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'SUCCESS_FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN_IN_REDUX = 'SET_TOKEN_IN_REDUX';
export const START_PROFILE_FETCH = 'START_PROFILE_FETCH';
export const SUCCESS_PROFILE_FETCH = 'SUCCESS_PROFILE_FETCH';
export const ERROR_PROFILE_FETCH = 'ERROR_PROFILE_FETCH';

export const SET_MAINTENANCE_FLAG = 'SET_MAINTENANCE_FLAG';

export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';

export const UPDATE_REDIRECTING_TO_ATARIM = 'UPDATE_REDIRECTING_TO_ATARIM';

export const SAVE_URL_SEARCH_HISTORY = 'SAVE_URL_SEARCH_HISTORY';

export const START_SOCIAL_USER_REGISTRATION = 'START_SOCIAL_USER_REGISTRATION';
export const SUCCESS_USER_REGISTRATION = 'SUCCESS_USER_REGISTRATION';
export const ERROR_USER_REGISTRATION = 'ERROR_USER_REGISTRATION';

export const IS_NEW_USER = 'IS_NEW_USER';

export const UPDATE_SELECTED_INDEX = 'UPDATE_SELECTED_INDEX';

export const LICENSE_ACTIVATION = 'LICENSE_ACTIVATION';
export const SUCCESS_LICENSE_ACTIVATION = 'SUCCESS_LICENSE_ACTIVATION';
export const ERROR_LICENSE_ACTIVATION = 'ERROR_LICENSE_ACTIVATION';

export const SET_REGISTERED_FROM_DASHBOARD = 'SET_REGISTERED_FROM_DASHBOARD';

export const SET_REDIRECT = 'SET_REDIRECT';

export const GET_READ_NOTIFICATIONS = 'GET_READ_NOTIFICATIONS';
export const SUCCESS_GET_READ_NOTIFICATIONS = 'SUCCESS_GET_READ_NOTIFICATIONS';
export const ERROR_GET_READ_NOTIFICATIONS = 'ERROR_GET_READ_NOTIFICATIONS';

export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS';
export const SUCCESS_GET_UNREAD_NOTIFICATIONS =
  'SUCCESS_GET_UNREAD_NOTIFICATIONS';
export const ERROR_GET_UNREAD_NOTIFICATIONS = 'ERROR_GET_UNREAD_NOTIFICATIONS';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SUCCESS_GET_NOTIFICATIONS = 'SUCCESS_GET_NOTIFICATIONS';
export const ERROR_GET_NOTIFICATIONS = 'ERROR_GET_NOTIFICATIONS';

export const GET_NOTIFICATIONS_DETAILS = 'GET_NOTIFICATIONS_DETAILS';
export const SUCCESS_GET_NOTIFICATIONS_DETAILS =
  'SUCCESS_GET_NOTIFICATIONS_DETAILS';
export const ERROR_GET_NOTIFICATIONS_DETAILS =
  'ERROR_GET_NOTIFICATIONS_DETAILS';

export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';
export const SUCCESS_UPDATE_USER_NOTIFICATION =
  'SUCCESS_UPDATE_USER_NOTIFICATION';
export const ERROR_UPDATE_USER_NOTIFICATION = 'ERROR_UPDATE_USER_NOTIFICATION';

export const CHECK_ALL_NOTIFICATIONS = 'CHECK_ALL_NOTIFICATIONS';
export const SUCCESS_CHECK_ALL_NOTIFICATIONS =
  'SUCCESS_CHECK_ALL_NOTIFICATIONS';
export const ERROR_CHECK_ALL_NOTIFICATIONS = 'ERROR_CHECK_ALL_NOTIFICATIONS';

export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const SUCCESS_GET_NOTIFICATIONS_COUNT =
  'SUCCESS_GET_NOTIFICATIONS_COUNT';
export const ERROR_GET_NOTIFICATIONS_COUNT = 'ERROR_GET_NOTIFICATIONS_COUNT';

export const MUTE_UNMUTE_NOTIFICATIONS = 'MUTE_UNMUTE_NOTIFICATIONS';
export const SUCCESS_MUTE_UNMUTE_NOTIFICATIONS =
  'SUCCESS_MUTE_UNMUTE_NOTIFICATIONS';
export const ERROR_MUTE_UNMUTE_NOTIFICATIONS =
  'ERROR_MUTE_UNMUTE_NOTIFICATIONS';
export const INCREASE_READ_COUNTER = 'INCREASE_READ_COUNTER';
export const INCREASE_UNREAD_COUNTER = 'INCREASE_UNREAD_COUNTER';
export const OPEN_CLOSE_NOTIFICATIONS_MODAL = 'OPEN_CLOSE_NOTIFICATIONS_MODAL';

export const UPGRADE_PLANS = 'UPGRADE_PLANS';
export const SUCCESS_UPGRADE_PLANS = 'SUCCESS_UPGRADE_PLANS';
export const ERROR_UPGRADE_PLANS = 'ERROR_UPGRADE_PLANS';

export const INVOICE_DOWNLOAD_URL = 'INVOICE_DOWNLOAD_URL';
export const SUCCESS_INVOICE_DOWNLOAD_URL = 'SUCCESS_INVOICE_DOWNLOAD_URL';
export const ERROR_INVOICE_DOWNLOAD_URL = 'ERROR_INVOICE_DOWNLOAD_URL';

export const REMOVE_INVOICE_LINK = 'REMOVE_INVOICE_LINK';

export const REVOKE_USER_ACCESS = 'REVOKE_USER_ACCESS';
export const SUCCESS_REVOKE_USER_ACCESS = 'SUCCESS_REVOKE_USER_ACCESS';
export const ERROR_REVOKE_USER_ACCESS = 'ERROR_REVOKE_USER_ACCESS';

export const OPEN_CHECKOUT = 'OPEN_CHECKOUT';
export const SUCCESS_OPEN_CHECKOUT = 'SUCCESS_OPEN_CHECKOUT';
export const ERROR_OPEN_CHECKOUT = 'ERROR_OPEN_CHECKOUT';

export const HANDLE_PLANS_DIALOG = 'HANDLE_PLANS_DIALOG';

export const REMOVE_HOSTED_PAGE_DATA = 'REMOVE_HOSTED_PAGE_DATA';

export const SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS';
export const SUCCESS_SUBSCRIPTION_DETAILS = 'SUCCESS_SUBSCRIPTION_DETAILS';
export const ERROR_SUBSCRIPTION_DETAILS = 'ERROR_SUBSCRIPTION_DETAILS';

export const BILLING_INFO = 'BILLING_INFO';
export const SUCCESS_BILLING_INFO = 'SUCCESS_BILLING_INFO';
export const ERROR_BILLING_INFO = 'ERROR_BILLING_INFO';

export const CUSTOMER_INVOICES = 'CUSTOMER_INVOICES';
export const SUCCESS_CUSTOMER_INVOICES = 'SUCCESS_CUSTOMER_INVOICES';
export const ERROR_CUSTOMER_INVOICES = 'ERROR_CUSTOMER_INVOICES';

export const PORTAL_SESSION = 'PORTAL_SESSION';
export const SUCCESS_PORTAL_SESSION = 'SUCCESS_PORTAL_SESSION';
export const ERROR_PORTAL_SESSION = 'ERROR_PORTAL_SESSION';

export const REMOVE_PORTAL_SESSION_DATA = 'REMOVE_PORTAL_SESSION_DATA';

export const EMPTY_PLANS_MESSAGE = 'EMPTY_PLANS_MESSAGE';
export const EMPTY_BILLING_MESSAGE = 'EMPTY_BILLING_MESSAGE';

export const SUCCESS_ADDRESS_SESSION = 'SUCCESS_ADDRESS_SESSION';

export const COLLAPSE_SIDE_NAV = 'COLLAPSE_SIDE_NAV';
export const COLLAPSE_COLLABORATE_SIDE_NAV = 'COLLAPSE_COLLABORATE_SIDE_NAV';

export const UPDATE_TRAIL_DETAIL = 'UPDATE_TRAIL_DETAIL';

export const POST_TRAIL_DETAIL = 'POST_TRAIL_DETAIL';
export const SUCCESS_POST_TRAIL_DETAIL = 'SUCCESS_POST_TRAIL_DETAIL';
export const ERROR_POST_TRAIL_DETAIL = 'ERROR_POST_TRAIL_DETAIL';

export const ACTIVATE_TRIAL = 'ACTIVATE_TRIAL';
export const SUCCESS_ACTIVATE_TRIAL = 'SUCCESS_ACTIVATE_TRIAL';
export const ERROR_ACTIVATE_TRIAL = 'ERROR_ACTIVATE_TRIAL';

export const REJECT_TRIAL = 'REJECT_TRIAL';
export const SUCCESS_REJECT_TRIAL = 'SUCCESS_REJECT_TRIAL';
export const ERROR_REJECT_TRIAL = 'ERROR_REJECT_TRIAL';

export const ELIGIBLE_TO_INACTIVE = 'ELIGIBLE_TO_INACTIVE';

export const FETCH_EXISTING_SITE_URL = 'FETCH_EXISTING_SITE_URL';
export const SUCCESS_FETCH_EXISTING_SITE_URL =
  'SUCCESS_FETCH_EXISTING_SITE_URL';
export const ERROR_FETCH_EXISTING_SITE_URL = 'ERROR_FETCH_EXISTING_SITE_URL';

export const CLOSE_TRIAL_WIZARD = 'CLOSE_TRIAL_WIZARD';
export const SUCCESS_CLOSE_TRIAL_WIZARD = 'SUCCESS_CLOSE_TRIAL_WIZARD';

export const SEND_TWO_FACTOR_CODE = 'SEND_TWO_FACTOR_CODE';
export const SUCCESS_SEND_TWO_FACTOR_CODE = 'SUCCESS_SEND_TWO_FACTOR_CODE';
export const ERROR_SEND_TWO_FACTOR_CODE = 'ERROR_SEND_TWO_FACTOR_CODE';

export const VERIFY_CODE = 'VERIFY_CODE';
export const SUCCESS_VERIFY_CODE = 'SUCCESS_VERIFY_CODE';
export const ERROR_VERIFY_CODE = 'ERROR_VERIFY_CODE';

export const SET_2FA_STATUS = 'SET_2FA_STATUS';
export const SUCCESS_SET_2FA_STATUS = 'SUCCESS_SET_2FA_STATUS';
export const ERROR_SET_2FA_STATUS = 'ERROR_SET_2FA_STATUS';

export const CONFIRM_SINGLE_USER_TWO_FA = 'CONFIRM_SINGLE_USER_TWO_FA';
export const SUCCESS_CONFIRM_SINGLE_USER_TWO_FA =
  'SUCCESS_CONFIRM_SINGLE_USER_TWO_FA';
export const ERROR_CONFIRM_SINGLE_USER_TWO_FA =
  'ERROR_CONFIRM_SINGLE_USER_TWO_FA';

export const SAVE_PLAN_SEARCH = 'SAVE_PLAN_SEARCH';
export const CLEAR_PLAN_SEARCH = 'CLEAR_PLAN_SEARCH';
