import React from 'react';
// Styling
import Lottie from 'react-lottie';
import animationData from 'media/jsons/spinner';

export default function PageLoading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <Lottie
        options={{
          animationData: animationData,
        }}
        height={150}
        width={150}
      />
    </div>
  );
}
