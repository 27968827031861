import { motion } from 'framer-motion';

const outlineVariants = {
  hidden: { pathLength: 0 },
  visible: {
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
      repeatDelay: 1, // Wait for fill to complete before restarting
    },
  },
};

const fillVariants = {
  hidden: { fillOpacity: 0 },
  visible: {
    fillOpacity: [0, 1, 1, 0],
    transition: {
      duration: 1,
      delay: 0.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 2, // Wait for outline to almost complete before starting
    },
  },
};

function AnimatedLoader({ size = 90 }) {
  return (
    <div className="flex h-auto items-center justify-center">
      <motion.svg
        width={size}
        height={(size * 90) / 93}
        viewBox="0 0 93 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z"
          variants={outlineVariants}
          initial="hidden"
          animate="visible"
          stroke="#052055"
        />
        <motion.path
          d="M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z"
          variants={fillVariants}
          initial="hidden"
          animate="visible"
          fill="#052055"
        />
        <motion.path
          d="M20 89L32 66L89.2084 43.7949L20 89Z"
          variants={outlineVariants}
          initial="hidden"
          animate="visible"
          stroke="#6D5DF3"
          strokeLinejoin="round"
        />
        <motion.path
          d="M20 89L32 66L89.2084 43.7949L20 89Z"
          variants={fillVariants}
          initial="hidden"
          animate="visible"
          fill="#6D5DF3"
        />
        <motion.path
          d="M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z"
          variants={outlineVariants}
          initial="hidden"
          animate="visible"
          stroke="#052055"
        />
        <motion.path
          d="M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z"
          variants={fillVariants}
          initial="hidden"
          animate="visible"
          fill="#052055"
        />
      </motion.svg>
    </div>
  );
}

export default AnimatedLoader;
