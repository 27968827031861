import * as React from 'react';

import { cn } from 'utils/cn';

const Textarea = React.forwardRef(({ className, disabled, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'ring-offset-background focus-visible:ring-ring flex min-h-[80px] w-full rounded-md border border-input bg-white px-3 py-2 text-sm placeholder:text-secondary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-300 dark:bg-transparent dark:text-white',
        className
      )}
      ref={ref}
      disabled={disabled}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';

export default Textarea;
