import { LayoutGrid, LucideIcon, Mail, SquareKanban } from 'lucide-react';
type Submenu = {
  href: string;
  label: string;
  active: boolean;
};

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: LucideIcon;
  submenus: Submenu[];
};

type Group = {
  groupLabel: string;
  menus: Menu[];
};

export function getMenuList(pathname: string): Group[] {
  return [
    {
      groupLabel: '',
      menus: [
        {
          href: '/',
          label: 'Projects',
          icon: LayoutGrid,
          active: pathname === '/',
          submenus: [],
        },
        {
          href: '/tasks',
          label: 'Inbox',
          icon: Mail,
          active: pathname.includes('/tasks'),
          submenus: [],
        },
        {
          href: '/board',
          label: 'Boards',
          icon: SquareKanban,
          active: pathname === '/board',
          submenus: [],
        },
      ],
    },
  ];
}
