import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'utils/cn';
import Tooltip from '../Tooltip';

const toggleVariants = cva(
  'inline-flex whitespace-nowrap items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors hover:bg-muted hover:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-accent data-[state=on]:text-accent-foreground',
  {
    variants: {
      variant: {
        primary:
          'bg-primary text-primary-foreground hover:brightness-90 border border-primary',
        muted:
          'bg-primary/10 text-primary-foreground hover:bg-primary/50 border border-primary',
        secondary:
          'bg-white border border-colour-secondary-border text-secondary-foreground hover:bg-tertiary/[0.06]',
        destructive:
          'bg-destructive text-destructive-foreground text-white hover:bg-destructive/90',
        'muted-destructive':
          'bg-destructive/10 text-destructive-foreground hover:bg-destructive/50 border border-destructive',
        outline:
          'border text-primary-foreground border-input bg-background hover:bg-tertiary/10 hover:text-accent-foreground data-[state=on]:bg-tertiary/10 data-[state=on]:text-secondary ',
        note: 'border text-primary-foreground border-input bg-background hover:bg-ata-gold hover:text-accent-foreground data-[state=on]:bg-ata-gold data-[state=on]:text-accent-foreground',
        tertiary:
          'border text-primary-foreground border-input bg-background data-[state=on]:bg-tertiary data-[state=on]:text-white',
        ghost:
          'hover:bg-accent hover:text-accent-foreground hover:bg-tertiary/[0.06]',
        link: 'px-0 relative after:absolute font-base text-tertiary after:bg-tertiary after:bottom-0 after:h-[1px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300',
      },
      size: {
        sm: 'h-9 rounded-lg px-3 text-sm gap-x-1 leading-tight',
        md: 'h-10 rounded-lg px-4 py-2 text-base gap-x-2',
        lg: 'h-11 rounded-lg px-8 py-3 text-lg gap-x-3',
        xl: 'h-12 rounded-lg px-12 py-4 text-xl gap-x-4',
        icon: 'h-9 w-9 p-2 rounded-lg overflow-hidden text-secondary min-w-9',
        link: 'px-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

interface ToggleProps
  extends React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root>,
    VariantProps<typeof toggleVariants> {
  tooltip?: string | React.ReactNode;
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
  tooltipArrow?: boolean;
  toolTipPortal?: boolean;
}

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  ToggleProps
>(
  (
    {
      className,
      variant,
      size,
      tooltip,
      tooltipSide,
      tooltipArrow,
      toolTipPortal = false,
      ...props
    },
    ref
  ) => {
    const toggleElement = (
      <TogglePrimitive.Root
        ref={ref}
        className={cn(toggleVariants({ variant, size, className }))}
        {...props}
      />
    );

    if (tooltip) {
      return (
        <Tooltip
          content={tooltip}
          side={tooltipSide}
          arrow={tooltipArrow}
          portal={toolTipPortal}
        >
          <span className="inline-flex items-center justify-center">
            {toggleElement}
          </span>
        </Tooltip>
      );
    }

    return toggleElement;
  }
);

Toggle.displayName = TogglePrimitive.Root.displayName;

export default Toggle;
export { toggleVariants };
