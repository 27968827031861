import 'react-notifications/lib/notifications.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './browserRouter';
import PageLoading from './components/PageLoading';

function Main() {
  return <RouterProvider router={router} fallbackElement={<PageLoading />} />;
}

export default Main;
