import React from 'react';
// Styling
import AtarimLogo from 'media/svgs/atarim-logo-white-dark-accent.svg?react';
import StepperComplete from 'media/svgs/mark-complete-stepper.svg?react';
import GotoChromeWizardButton from '../GotoChromeWizardButton';

const UpgradeStepperWizard = ({ detailSteps }) => {
  const steps = [
    'Register',
    'More about you',
    'Setup your account',
    'Complete',
  ];

  return (
    <>
      <nav className="hidden w-fit flex-col items-center justify-around rounded-xl bg-gradient-to-t from-[#8F5DF3] to-[#6D5DF3] px-3 text-white md:flex lg:min-w-[400px]">
        <div className="w-fit md:w-[240px]">
          <AtarimLogo className="mb-4" width={147} height={40} />
          <h2 className="text-[16px] font-bold md:text-[18px] lg:text-[24px] lg:leading-tight">
            Setup your Atarim account to visually collaborate on your projects,
            seamlessly
          </h2>
          <ul className="mt-8 flex flex-col uppercase">
            {steps.map((step, index) => (
              <li key={index} className="relative">
                <span className="flex items-center gap-2 font-bold">
                  {index > detailSteps ? (
                    <span className="flex size-7 items-center justify-center rounded-full border-2">
                      {index + 1}
                    </span>
                  ) : (
                    <StepperComplete className="size-7" />
                  )}
                  {step}
                </span>
                {/* dividers */}
                {index < steps.length - 1 && (
                  <div
                    className={`my-1 ml-3 h-4 min-h-[1em] w-0.5 self-stretch ${index > detailSteps ? 'bg-white' : 'bg-green-500'}`}
                  ></div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <GotoChromeWizardButton />
      </nav>
    </>
  );
};

export default UpgradeStepperWizard;
