import API from '../api';
import cleanApi from '../services/axiosClient';

export function resetPassword(url, paramObj) {
  return cleanApi.post(url, paramObj);
}

export function login(email, password) {
  return API.post('/api-login', {
    email_or_username: email,
    password,
  });
}

export function forgotPasswordRequest(email) {
  return API.post('/api-reset-password-request', {
    email_or_username: email,
  });
}

export function logout() {
  return API.get('/api-logout');
}

export function fetchProfile(selectedWorkspaceId) {
  return API.get(
    `/user${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function changePassword(passwordData) {
  return API.post('/api-change-password', passwordData);
}

export function register({
  name,
  email,
  password,
  src,
  admin_id,
  workspace_id,
}) {
  return cleanApi.post('/collaborate/user/create-account', {
    name,
    email,
    password,
    src,
    admin_id,
    workspace_id,
  });
}

export function registerSocialUser(
  codeResponse,
  provider,
  collab = false,
  site_id
) {
  return API.post('/social/register', {
    codeResponse,
    provider,
    collab,
    site_id,
  });
}

export function loginSocialUser(
  codeResponse,
  provider,
  collab = false,
  site_id
) {
  return cleanApi.post('/social/login', {
    codeResponse,
    provider,
    collab,
    site_id,
  });
}

export function authenticate() {
  return cleanApi.get('/authenticate');
}

export function licenseActivate(params) {
  return API.post('/site/activate', params);
}

export function getAllnotifications(boolean, offset) {
  return API.get(
    `/user/notifications?mark_as_read=${boolean}&offset=${offset}`
  );
}

export function notifications(selectedWorkspaceId) {
  return API.get(
    `/user/notifications${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function getNotificationDetails(id) {
  return API.get(`/user/notifications/${id}`);
}

export function updateNotification(id, mark_as_read) {
  return API.post(`/user/notifications/${id}/update`, mark_as_read);
}

export function checkAllNotifications(mark_as_read) {
  return API.post(`/user/notifications/update`, mark_as_read);
}

export function unReadNotificationsCount() {
  return API.get(`/user/notifications/unread-count`);
}

export function muteUnmuteNotifications(muteState) {
  return API.post(`/user/notifications/update`, muteState);
}

export function getUpgradePlans() {
  return API.get('/plans/upgrade-plans');
}

export function getInvoiceUrl(id) {
  return API.get(`/billing/invoice/?invoice_id=${id}`);
}

export function openCheckout(params) {
  return API.post('/billing/generate-hosted-page', params);
}

export function getSubscriptionDetails(id, rawId, users) {
  return API.get(
    `/billing/get-hosted-page?hosted_page_id=${id}&plan_id=${rawId}&users=${users}`
  );
}

export function getBillingInfo() {
  return API.get('/billing/billing-info');
}

export function getInvoices() {
  return API.get('/billing/customer-invoices');
}

export function createPortalSession() {
  return API.post('/billing/create-portal-session');
}

export function postTrailDetails(params) {
  return API.post('/trial', params);
}

export function activateTrial(params) {
  return API.post('/activate-trial', params);
}

export function rejectTrial() {
  return API.post('/reject-trial');
}

export function fetchExistingUrl() {
  return API.get('/site?pluck=url');
}

export function sendCode(params) {
  return cleanApi.post('/two-factor-auth/send-code', params);
}

export function verifyCode(params) {
  return cleanApi.post('/two-factor-auth/verify-code', params);
}
export function activateTwoFa() {
  return cleanApi.post('/two-factor-auth/activate');
}

export function confrimSingleUserTwoFa(params) {
  return API.post('/user/update-setting', params);
}

export function deleteUserAccess({ user_id, workspace_id }) {
  return API.trash(`/workspaces/user/${user_id}?workspace_id=${workspace_id}`);
}

export function impersonateUser(email) {
  return API.post('/admin/impersonate', {
    email,
  });
}
