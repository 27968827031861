import { call, put, take, fork, takeLatest } from 'redux-saga/effects';
// Actions
import {
  START_FETCH_HOME_DATA,
  SUCCESS_FETCH_HOME_DATA,
  ERROR_FETCH_HOME_DATA,
  START_FETCH_HOME_WEEKS_DATA,
  SUCCESS_FETCH_HOME_WEEKS_DATA,
  ERROR_FETCH_HOME_WEEKS_DATA,
} from 'actions/home';
// API
import { fetchHomeData, fetchHomeLastWeekData } from '../api/home';

export function* fetchHomeDataWatcher() {
  while (true) {
    const { userId } = yield take(START_FETCH_HOME_DATA);
    yield fork(fetchHomeDataWorker, userId);
    yield take([SUCCESS_FETCH_HOME_DATA, ERROR_FETCH_HOME_DATA]);
  }
}

function* fetchHomeDataWorker(userId) {
  const data = yield call(fetchHomeData, userId);

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_HOME_DATA,
      homeData: data.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_HOME_DATA,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

function* fetchHomeDataWeeksWorker() {
  const response = yield call(fetchHomeLastWeekData);

  if (response) {
    yield put({
      type: SUCCESS_FETCH_HOME_WEEKS_DATA,
      data: response,
    });
  } else {
    yield put({
      type: ERROR_FETCH_HOME_WEEKS_DATA,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchHomeDataWeeksWatcher() {
  yield takeLatest(START_FETCH_HOME_WEEKS_DATA, fetchHomeDataWeeksWorker);
}
