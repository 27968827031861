import API from '../api';
import { CONSTANT_TASK_LISTING_PAGE_LIMIT } from 'utils/constants';

export function fetchAllEmails({
  id,
  offset = 0,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT,
  workspace_id,
}) {
  return API.get(
    `/mailbox/tasks/${id}?offset=${offset}&limit=${limit}&workspace_id=${workspace_id}`
  );
}

// this api dont have offset and return all tasks at onece for boards
export function fetchAllEmailsForBoards(obj, screen) {
  return API.get(`/mailbox/tasks/${obj.id}?offset=&screen=${screen}`);
}

export function fetchEmailForBoardsColumn(params, workspaceId) {
  const {
    id,
    offset = 0,
    limit = CONSTANT_TASK_LISTING_PAGE_LIMIT,
    taskCategory,
    columnType,
  } = params;
  return API.get(
    `mailbox/tasks/${id}?offset=${offset}&limit=${limit}&screen=board&type=board&task_category=${taskCategory}&column_type=${columnType}&workspace_id=${workspaceId}`
  );
}

export function verifyMailboxEmail(emailObj, workspaceId) {
  return API.post(`/mailbox/verify?workspace_id=${workspaceId}`, emailObj);
}

export function assignEmailToSite(emailObj) {
  return API.post(`/mailbox/assign-task-to-site`, emailObj);
}

export function emailVerification(workspaceId) {
  return API.get(`/mailbox/verification?workspace_id=${workspaceId}`);
}

export function replyToEmail(emailObj, workspaceId) {
  return API.post(`/mailbox/reply?workspace_id=${workspaceId}`, emailObj);
}

export function compose(emailObj, workspaceId) {
  return API.post(`/mailbox/compose?workspace_id=${workspaceId}`, emailObj);
}
