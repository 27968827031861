import { createSlice } from '@reduxjs/toolkit';

type AppState = {
  isInMaintenance: boolean;
};

const initialState: AppState = {
  isInMaintenance: false,
};

const appSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    showMaintenancePage: (state) => {
      state.isInMaintenance = true;
    },
    hideMaintenancePage: (state) => {
      state.isInMaintenance = false;
    },
  },
});

export const { showMaintenancePage, hideMaintenancePage } = appSlice.actions;
export default appSlice.reducer;
