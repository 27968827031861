import { Suspense, lazy } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import PageLoading from './components/PageLoading';
import AdminRoute from './containers/AdminRoute';
import ErrorBoundary from './containers/ErrorBoundary';
import Layout from './containers/Layout';
import ProtectedRoute from './containers/ProtectedRoute';
const Home = lazy(() => import('./containers/Home'));
const DiscoveryFlow = lazy(() => import('./containers/DiscoveryFlow'));
const Dashboard = lazy(() => import('./containers/Dashboard'));
const Tasks = lazy(() => import('./containers/Tasks'));
const TimeEntries = lazy(() => import('./containers/TimeEntries'));
const Board = lazy(() => import('./containers/Board'));
const ResetPassword = lazy(() => import('./containers/ResetPassword'));
const User = lazy(() => import('./containers/User'));
const Login = lazy(() => import('./containers/Login'));
const ForgotPassword = lazy(() => import('./containers/ForgotPassword'));
const ActivityFeed = lazy(() => import('./containers/ActivityFeed'));
const Settings = lazy(() => import('./containers/Settings'));
const Collaborate = lazy(() => import('./containers/Collaborate'));
const Register = lazy(() => import('./containers/Register'));
const InviteUsers = lazy(() => import('./containers/InviteUsers'));
const AddSite = lazy(() => import('./containers/AddWebsite'));
const ShowForms = lazy(() => import('./containers/ShowForms'));
const ResetOneClickPassword = lazy(
  () => import('./containers/ResetOneClickPassword')
);
const WorkspaceLogin = lazy(() => import('./containers/workspace-login'));
const Billing = lazy(() => import('./containers/Billing'));
const Workflow = lazy(() => import('./containers/Workflow'));
const Impersonate = lazy(() => import('./containers/ImpersonateUser'));
const Inbox = lazy(() => import('./pages/Inbox'));
const BoardSelect = lazy(() => import('./pages/Board'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          { index: true, element: <Dashboard /> },
          { path: 'invite-users', element: <InviteUsers /> },
          { path: 'dashboard', element: <Home /> },
          { path: 'forms', element: <DiscoveryFlow /> },
          // { path: 'tasks', element: <Tasks /> },
          { path: 'board', element: <BoardSelect /> },
          { path: 'time-entries', element: <TimeEntries /> },
          { path: 'user', element: <User /> },
          { path: 'users', element: <ActivityFeed /> },
          { path: 'settings', element: <Settings /> },
          { path: 'billing', element: <Billing /> },
          { path: 'workflows', element: <Workflow /> },
          // new pages
          { path: 'tasks', element: <Inbox /> },
        ],
      },
      {
        element: (
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        ),
        children: [
          { path: 'workspaces-login', element: <WorkspaceLogin /> },
          { path: 'collaborate', element: <Collaborate /> },
          { path: 'login', element: <Login /> },
          { path: 'register', element: <Register /> },
          { path: 'reset-password/:token', element: <ResetPassword /> },
          { path: 'set-password/:token', element: <ResetPassword /> },
          { path: 'forgot-password', element: <ForgotPassword /> },
          {
            path: 'one-click-set-password',
            element: <ResetOneClickPassword />,
          },
          { path: 'view-form', element: <ShowForms /> },
          { path: 'fetching', element: <AddSite /> },
          { path: '*', element: <Navigate to="/" /> },
        ],
      },
      {
        element: <AdminRoute />,
        children: [{ path: 'impersonate-user', element: <Impersonate /> }],
      },
    ],
  },
]);
