import {
  EMPTY_MESSAGE_ACTIVITY,
  EMPTY_SITE_LIST_USER,
  ERROR_ADD_TO_PROJECT,
  ERROR_ADD_TO_SITE,
  ERROR_DELETE_USER,
  ERROR_FETCH_FEED_USER,
  ERROR_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
  ERROR_FETCH_MORE_SITE_LIST_USER,
  ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  ERROR_FETCH_PROJECT_USER,
  ERROR_FETCH_SITE_LIST_USER,
  ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE,
  ERROR_REMOVE_INVITED_TEAM,
  ERROR_RE_INVITE_USER,
  ERROR_UPDATE_NOTIFICATION,
  ERROR_UPDATE_PROFILE,
  ERROR_UPLOAD_IMAGE,
  FETCH_FEED_USER,
  FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
  FETCH_MORE_SITE_LIST_USER,
  FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  FETCH_PROJECT_USER,
  FETCH_SITE_LIST_USER,
  FETCH_USERS_LIST_ACTIVITY_PAGE,
  INIT_UPLOAD,
  LEFT_ACTIVITY_PAGE,
  LOAD_SELF_DATA_AS_SELECTED_USER,
  SELECT_USER,
  START_RE_INVITE_USER,
  START_UPLOAD_IMAGE,
  SUCCESS_ADD_TO_PROJECT,
  SUCCESS_ADD_TO_SITE,
  SUCCESS_DELETE_USER,
  SUCCESS_FETCH_FEED_USER,
  SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_FETCH_MORE_SITE_LIST_USER,
  SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_FETCH_PROJECT_USER,
  SUCCESS_FETCH_SITE_LIST_USER,
  SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_REMOVE_INVITED_TEAM,
  SUCCESS_RE_INVITE_USER,
  SUCCESS_UPDATE_NOTIFICATION,
  SUCCESS_UPDATE_PROFILE,
  SUCCESS_UPLOAD_IMAGE,
  UPDATE_NOTIFICATION,
  UPDATE_PROFILE,
  UPDATE_SITES_CHECKED_STATUS,
  UPDATE_USERS_CHECKED_STATUS,
  USER_ACTIVITY_PANEL_HIDE,
} from 'actions/activity';
import {
  ERROR_REVOKE_USER_ACCESS,
  LOGOUT,
  SUCCESS_REVOKE_USER_ACCESS,
} from 'actions/user';

const initState = {
  users: [],
  errorUsersListMessage: '',
  errorUpdatingNotificationMessage: '',
  successUpdatingNotificationMessage: '',
  errorFetchingFeedsMessage: '',
  errorFetchingSitesForUser: '',
  successAddToSiteMessage: '',
  errorAddToSiteMessage: '',
  errorUploadAvatarMessage: '',
  errorUpdateProfileMessage: '',
  successUpdateProfileMessage: '',
  successfullyDeletedUser: '',
  errorInDeletingUser: '',
  selectedUser: '', // this stores only id of the current selected user
  selectedUserStatus: 'single',
  isFetchingFeeds: false,
  feeds: [],
  currentPage: 0,
  lastPage: 0,
  sites: [],
  isAvatarUploading: false,
  uploadedAvatarSuccessfully: false,
  reInvitationUser: '',
  successInvitationMessage: '',
  errorInvitationMessage: '',
  useractivityPanelHide: false,
  projects: [],
  isFetchingSite: false,
  isFetchingProject: false,
  isFetchingUsers: false,
  moreSitesRecord: false,
  moreUsersRecord: false,
  invitedUsers: [],
};

const ActivityReducer = (prevState = initState, action) => {
  switch (action.type) {
    case SUCCESS_REVOKE_USER_ACCESS:
      const { user_id } = action.payload;
      return {
        ...prevState,
        users: prevState.users.filter((u) => u.id !== user_id),
      };
    case ERROR_REVOKE_USER_ACCESS:
      return {
        ...prevState,
        message: action.message,
      };
    case LOAD_SELF_DATA_AS_SELECTED_USER:
      return {
        ...prevState,
        selectedUser: action.id,
      };
    case FETCH_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        users: [],
        errorUsersListMessage: '',
        isFetchingUsers: true,
      };
    case SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        users: action.usersList,
        errorUsersListMessage: '',
        selectedUser:
          prevState.selectedUser === ''
            ? action.usersList[0]?.id
            : prevState.selectedUser,
        isFetchingUsers: false,
        moreUsersRecord: action.moreUsersRecord,
      };
    case ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        users: [],
        errorUsersListMessage: action.message,
        isFetchingUsers: false,
      };
    case FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        errorUsersListMessage: '',
        isFetchingUsers: true,
      };
    case SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE:
      const nextUsers = prevState.users.concat(action.usersList);
      return {
        ...prevState,
        users: nextUsers,
        errorUsersListMessage: '',
        selectedUser:
          prevState.selectedUser === ''
            ? action.usersList[0]?.id
            : prevState.selectedUser,
        moreUsersRecord: action.moreUsersRecord,
        isFetchingUsers: false,
      };
    case ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        // users: [],
        errorUsersListMessage: action.message,
        isFetchingUsers: false,
      };
    case SELECT_USER:
      return {
        ...prevState,
        selectedUser: action.id,
        selectedUserStatus: action.status,
        feeds: [],
        currentPage: 0,
        lastPage: 0,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...prevState,
        errorUpdatingNotificationMessage: '',
        successUpdatingNotificationMessage: '',
      };
    case SUCCESS_UPDATE_NOTIFICATION:
      const { paramObj, message } = action;
      const updatedUsers = prevState.users.map((u) =>
        u.id === paramObj.user_id
          ? {
              ...u,
              notification_preference: u.notification_preference.map((n) =>
                n.value === paramObj.value
                  ? { ...n, selected: paramObj.selected }
                  : n
              ),
            }
          : u
      );
      return {
        ...prevState,
        users: updatedUsers,
        errorUpdatingNotificationMessage: '',
        successUpdatingNotificationMessage: message,
      };
    case ERROR_UPDATE_NOTIFICATION:
      return {
        ...prevState,
        errorUpdatingNotificationMessage: action.message,
        successUpdatingNotificationMessage: '',
      };
    case EMPTY_MESSAGE_ACTIVITY:
      return {
        ...prevState,
        [action.key]: '',
      };
    case FETCH_FEED_USER:
      return {
        ...prevState,
        isFetchingFeeds: true,
        errorFetchingFeedsMessage: '',
        feeds: action.pageNumber === 1 ? [] : prevState.feeds,
      };
    case SUCCESS_FETCH_FEED_USER:
      const prevFeedsDate = Object.keys(prevState.feeds);
      const actionFeedsDate = Object.keys(action.feeds);
      const newFeeds = [];
      prevFeedsDate.forEach((k) => {
        newFeeds[k] = prevState.feeds[k].slice();
      });
      actionFeedsDate.forEach((k) => {
        const temp = action.feeds[k].slice();
        if (newFeeds[k]) {
          newFeeds[k] = newFeeds[k].concat(temp);
        } else {
          newFeeds[k] = temp;
        }
      });
      const keys = Object.keys(newFeeds);
      keys.forEach((k) => {
        newFeeds[k] = newFeeds[k].filter(
          (f, i) => newFeeds[k].findIndex((t) => t.id === f.id) === i
        );
      });

      return {
        ...prevState,
        isFetchingFeeds: false,
        errorFetchingFeedsMessage: '',
        feeds: newFeeds,
        currentPage: action.currentPage,
        lastPage: action.lastPage,
      };
    case ERROR_FETCH_FEED_USER:
      return {
        ...prevState,
        isFetchingFeeds: false,
        errorFetchingFeedsMessage: action.message,
      };
    case FETCH_SITE_LIST_USER:
      return {
        ...prevState,
        sites: [],
        isFetchingSite: true,
        errorFetchingSitesForUser: '',
      };
    case SUCCESS_FETCH_SITE_LIST_USER:
      // filter the archved sites out and get only the active sites
      const sites = action.sites.filter((s) => s.is_active === 1);
      return {
        ...prevState,
        errorFetchingSitesForUser: '',
        isFetchingSite: false,
        sites: sites,
        moreSitesRecord: action.moreSitesRecord,
      };
    case ERROR_FETCH_SITE_LIST_USER:
      return {
        ...prevState,
        sites: [],
        isFetchingSite: false,
        errorFetchingSitesForUser: action.message,
      };

    case FETCH_MORE_SITE_LIST_USER:
      return {
        ...prevState,
        errorFetchingSitesForUser: '',
      };
    case SUCCESS_FETCH_MORE_SITE_LIST_USER:
      // filter the archved sites out and get only the active sites
      const moreSites = action.sites.filter((s) => s.is_active === 1);
      const data = prevState.sites.concat(moreSites);

      return {
        ...prevState,
        errorFetchingSitesForUser: '',
        isFetchingSite: false,
        sites: data,
        moreSitesRecord: action.sites.length === 20 ? true : false,
      };
    case ERROR_FETCH_MORE_SITE_LIST_USER:
      return {
        ...prevState,
        isFetchingSite: false,
        errorFetchingSitesForUser: action.message,
      };

    case SUCCESS_ADD_TO_SITE:
      return {
        ...prevState,
        sites: prevState.sites.map((s) =>
          s.id === action.websiteId ? { ...s, is_added: action.isAdd } : s
        ),
        successAddToSiteMessage: `User ${
          action.isAdd ? 'added to' : 'removed from'
        } project`,
        errorAddToSiteMessage: '',
      };
    case ERROR_ADD_TO_SITE:
      return {
        ...prevState,
        successAddToSiteMessage: '',
        errorAddToSiteMessage: action.message,
      };
    case START_UPLOAD_IMAGE:
      return {
        ...prevState,
        isAvatarUploading: true,
        errorUploadAvatarMessage: '',
        uploadedAvatarSuccessfully: false,
      };
    case SUCCESS_UPLOAD_IMAGE:
      const { userId, avatar } = action;
      return {
        ...prevState,
        isAvatarUploading: false,
        errorUploadAvatarMessage: '',
        uploadedAvatarSuccessfully: true,
        users: prevState.users.map((u) =>
          u.id === userId ? { ...u, avatar } : u
        ),
      };
    case ERROR_UPLOAD_IMAGE:
      return {
        ...prevState,
        isAvatarUploading: false,
        errorUploadAvatarMessage: action.message,
        uploadedAvatarSuccessfully: false,
      };
    case INIT_UPLOAD:
      return {
        ...prevState,
        isAvatarUploading: false,
        errorUploadAvatarMessage: '',
        uploadedAvatarSuccessfully: false,
      };
    case UPDATE_PROFILE:
      return {
        ...prevState,
        errorUpdateProfileMessage: '',
        successUpdateProfileMessage: '',
      };
    case SUCCESS_UPDATE_PROFILE:
      return {
        ...prevState,
        successUpdateProfileMessage: 'Updated!',
        errorUpdateProfileMessage: '',
        users: prevState.users.map((u) =>
          u.id === action.userId ? { ...u, [action.key]: action.value } : u
        ),
      };
    case ERROR_UPDATE_PROFILE:
      return {
        ...prevState,
        successUpdateProfileMessage: '',
        errorUpdateProfileMessage: action.message,
      };
    case SUCCESS_DELETE_USER: {
      let nextSelectedUserId = '';
      prevState.users.some((user, index) => {
        if (user.id === action.userId) {
          const tempIndex =
            index === prevState.users.length - 1 ? 0 : index + 1;
          nextSelectedUserId = prevState.users[tempIndex].id;
          return true;
        }
        return false;
      });
      return {
        ...prevState,
        successfullyDeletedUser: action.message,
        errorInDeletingUser: '',
        users: prevState.users.filter((u) => u.id !== action.userId),
        selectedUser: nextSelectedUserId,
      };
    }
    case ERROR_DELETE_USER:
      return {
        ...prevState,
        errorInDeletingUser: action.message,
        successfullyDeletedUser: '',
      };
    case START_RE_INVITE_USER:
      return {
        ...prevState,
        reInvitationUser: prevState.reInvitationUser.concat([action.userId]),
      };
    case SUCCESS_RE_INVITE_USER:
      return {
        ...prevState,
        successInvitationMessage: action.message,
        errorInvitationMessage: '',
        reInvitationUser: action.userId,
      };
    case ERROR_RE_INVITE_USER:
      return {
        ...prevState,
        successInvitationMessage: '',
        errorInvitationMessage: action.message,
        reInvitationUser: action.userId,
      };
    case USER_ACTIVITY_PANEL_HIDE:
      return {
        ...prevState,
        useractivityPanelHide: action.status,
      };
    case FETCH_PROJECT_USER:
      return {
        ...prevState,
        projects: [],
        isFetchingProject: true,
        errorFetchingSitesForUser: '',
      };
    case SUCCESS_FETCH_PROJECT_USER:
      return {
        ...prevState,
        errorFetchingSitesForUser: '',
        isFetchingProject: false,
        projects: action.projects,
      };
    case ERROR_FETCH_PROJECT_USER:
      return {
        ...prevState,
        projects: [],
        isFetchingProject: false,
        errorFetchingSitesForUser: action.message,
      };
    case UPDATE_USERS_CHECKED_STATUS: {
      return {
        ...prevState,
        users: prevState.users.map((u) =>
          u.normal_id === action.userId
            ? { ...u, checked: action.checked, assigned: action.checked }
            : u
        ),
      };
    }
    case UPDATE_SITES_CHECKED_STATUS: {
      return {
        ...prevState,
        sites: prevState.sites.map((s) =>
          s.normal_id === action.siteId
            ? {
                ...s,
                project_checked: action.checked,
                assigned: action.checked,
              }
            : s
        ),
      };
    }
    case SUCCESS_ADD_TO_PROJECT:
      return {
        ...prevState,
        projects: prevState.projects.map((s) =>
          s.id === action.projectId ? { ...s, is_added: action.isAdd } : s
        ),
        successAddToSiteMessage: `User ${
          action.isAdd ? 'added to' : 'removed from'
        } Folder`,
        errorAddToSiteMessage: '',
      };
    case ERROR_ADD_TO_PROJECT:
      return {
        ...prevState,
        successAddToSiteMessage: '',
        errorAddToSiteMessage: action.message,
      };
    case LEFT_ACTIVITY_PAGE:
      return initState;
    case LOGOUT:
      return initState;
    case FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        invitedUsers: [],
        errorUsersListMessage: '',
        isFetchingInvitedUsers: true,
      };
    case SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        invitedUsers: action.data,
        errorUsersListMessage: '',
        isFetchingInvitedUsers: false,
      };
    case ERROR_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE:
      return {
        ...prevState,
        invitedUsers: [],
        errorUsersListMessage: action.message,
        isFetchingInvitedUsers: false,
      };
    case SUCCESS_REMOVE_INVITED_TEAM: {
      let newInvited = prevState.invitedUsers.filter(
        (each) => each.id !== action.userId
      );
      return {
        ...prevState,
        errorInvitationMessage: '',
        invitedUsers: newInvited,
        successAddToSiteMessage: action.message,
      };
    }
    case ERROR_REMOVE_INVITED_TEAM: {
      return {
        ...prevState,
        errorInvitationMessage: action.message,
        successAddToSiteMessage: '',
      };
    }
    case EMPTY_SITE_LIST_USER:
      return {
        ...prevState,
        sites: [],
        isFetchingSite: false,
        errorFetchingSitesForUser: '',
      };
    default:
      return prevState;
  }
};

export default ActivityReducer;
