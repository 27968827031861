// Actions
import {
  EMPTY_MESSAGE_DASHBOARD,
  START_FETCH_DASHBOARD_DATA,
} from 'actions/dashboard';
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  EMPTY_PROJECT_ID,
  ERROR_CREATE_PROJECT,
  ERROR_DELETE_PROJECT,
  FETCH_PROJECTS,
  SET_WEBSITE_IDS,
  SUCCESS_CREATE_PROJECT,
  SUCCESS_DELETE_PROJECT,
  SUCCESS_FETCH_PROJECTS,
  SUCCESS_UPDATE_PROJECT,
  UPDATE_PROJECT,
} from 'actions/projects';

const initState = {
  successMessage: '',
  errorMessage: '',
  projects: [],
  deletingProject: false,
  projectId: '',
  websiteIds: [],
};

const projects = (previousState = initState, action) => {
  switch (action.type) {
    case CREATE_PROJECT:
    case FETCH_PROJECTS:
    case UPDATE_PROJECT:
    case START_FETCH_DASHBOARD_DATA:
      return {
        ...previousState,
        successMessage: '',
        errorMessage: '',
      };
    case SUCCESS_CREATE_PROJECT:
      return {
        ...previousState,
        projectId: action.projectId,
        successMessage: action.message,
        errorMessage: '',
      };
    case SET_WEBSITE_IDS:
      return {
        ...previousState,
        websiteIds: action.websiteIds,
      };
    case ERROR_CREATE_PROJECT:
      return {
        ...previousState,
        successMessage: '',
        errorMessage: action.message,
      };
    case SUCCESS_FETCH_PROJECTS:
      return {
        ...previousState,
        projects: action.data,
      };
    case SUCCESS_UPDATE_PROJECT:
      return {
        ...previousState,
        projectId: action.projectId,
        successMessage: action.message,
      };
    case DELETE_PROJECT:
      return {
        ...previousState,
        deletingProject: true,
        deletedSuccessfully: null,
        successMessage: '',
        errorMessage: '',
      };
    case SUCCESS_DELETE_PROJECT:
      return {
        ...previousState,
        deletingProject: false,
        deletedSuccessfully: true,
        successMessage: action.message,
        errorMessage: '',
      };
    case ERROR_DELETE_PROJECT:
      return {
        ...previousState,
        deletingProject: false,
        deletedSuccessfully: null,
        successMessage: '',
        errorMessage: action.message,
      };
    case EMPTY_MESSAGE_DASHBOARD:
      return {
        ...previousState,
        [action.key]: '',
      };
    case EMPTY_PROJECT_ID:
      return {
        ...previousState,
        projectId: '',
      };
    default:
      return previousState;
  }
};

export default projects;
