import { FIXME } from '@src/types';
import { motion } from 'framer-motion';
import { Cog, RefreshCw } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertTitle, Button } from 'ui';
import { cn } from 'utils/cn';

export const MaintenancePage = ({
  className,
  svgOptions,
}: {
  className?: string;
  svgOptions?: {
    duration?: number;
  };
}) => {
  const handleRefresh = () => {
    window.location.reload();
  };
  const [message, setMessage] = useState(
    "We are currently performing maintenance to improve our service. We'll be back at 2.30pm (GMT+1)."
  );
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      const messageJson = posthog.getFeatureFlagPayload('Maintenance');
      console.log('messageJson', messageJson);
      setMessage(
        ((posthog.getFeatureFlagPayload('Maintenance') as FIXME)
          ?.message as string) ||
          'We are currently performing maintenance to improve our service.'
      );
    }
  }, [posthog]);

  return (
    <div
      className={cn(
        'relative h-screen w-full overflow-hidden bg-white dark:bg-black',
        className
      )}
    >
      <SVG svgOptions={svgOptions} />
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md px-4"
        >
          <Alert className="mb-6">
            <AlertTitle className="mb-2 flex items-center justify-center text-2xl font-bold uppercase">
              <Cog className="mr-2 h-5 w-5" />
              Under maintenance
            </AlertTitle>
            <AlertDescription className="text-center text-lg">
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              ></div>
            </AlertDescription>
          </Alert>

          <Button onClick={handleRefresh} className="w-full">
            <RefreshCw className="mr-2 h-5 w-5" />
            Retry Page
          </Button>
        </motion.div>
      </div>
    </div>
  );
};

export default MaintenancePage;

const SVG = ({
  svgOptions,
}: {
  svgOptions?: {
    duration?: number;
  };
}) => {
  const paths = [
    `M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z
     M20 89L32 66L89.2084 43.7949L20 89Z
     M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z`,
    `M81.3656 68.0739L65.3768 73.9458L74.2283 90.9982L93.2922 90.9759L81.3656 68.0739Z
     M22 91L34 68L91.2084 45.7949L22 91Z
     M48.1411 4.03317L3 90.976H22.0744L48.1354 40.7298L56.5 59L73.5 52.5L48.1411 4.03317Z`,
    `M77.3656 64.0739L61.3768 69.9458L70.2283 86.9982L89.2922 86.9759L77.3656 64.0739Z
     M18 87L30 64L87.2084 41.7949L18 87Z
     M44.1411 0.03317L-1 86.976H18.0744L44.1354 36.7298L52.5 55L69.5 48.5L44.1411 0.03317Z`,
    `M80.3656 67.0739L64.3768 72.9458L73.2283 89.9982L92.2922 89.9759L80.3656 67.0739Z
     M21 90L33 67L90.2084 44.7949L21 90Z
     M47.1411 3.03317L2 89.976H21.0744L47.1354 39.7298L55.5 58L72.5 51.5L47.1411 3.03317Z`,
  ];

  const colors = [
    '#46A5CA',
    '#8C2F2F',
    '#4FAE4D',
    '#D6590C',
    '#811010',
    '#247AFB',
    '#A534A0',
    '#A8A438',
    '#D6590C',
    '#46A29C',
    '#670F6D',
    '#D7C200',
    '#59BBEB',
    '#504F1C',
    '#55BC54',
    '#4D3568',
    '#9F39A5',
    '#363636',
    '#860909',
    '#6A286F',
    '#604483',
  ];

  const pathVariants = {
    initial: { strokeDashoffset: 800, strokeDasharray: '50 800' },
    animate: {
      strokeDashoffset: 0,
      strokeDasharray: '20 800',
      opacity: [0, 1, 1, 0],
    },
  };

  return (
    <motion.svg
      viewBox="0 0 1440 900"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="absolute inset-0 h-full w-full"
    >
      {paths.flatMap((path, idx) =>
        Array.from({ length: 10 }).map((_, i) => (
          <motion.g
            key={`path-group-${idx}-${i}`}
            transform={`translate(${Math.random() * 1200}, ${Math.random() * 600}) scale(${0.5 + Math.random()})`}
          >
            <motion.path
              d={path}
              stroke={colors[(idx + i) % colors.length]}
              strokeWidth="2.3"
              strokeLinecap="round"
              variants={pathVariants}
              initial="initial"
              animate="animate"
              transition={{
                duration: svgOptions?.duration || 10,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'loop',
                delay: Math.random() * 10,
                repeatDelay: Math.random() * 10 + 2,
              }}
            />
          </motion.g>
        ))
      )}
    </motion.svg>
  );
};

export { SVG };