import { GoogleOAuthProvider } from '@react-oauth/google';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { Provider } from 'react-redux';
import store from './store/index';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PostHogProvider client={posthog}>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </PostHogProvider>
  </Provider>
);
