import {
  DELETE_TASK,
  EMPTY_MESSAGE,
  ERROR_BOARD_STATUS_TASKS,
  ERROR_DELETE_TASK,
  ERROR_FETCH_ALL_BOARD_TASKS,
  ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK,
  ERROR_SAVE_NEW_BOARD_GENERAL_TASK,
  ERROR_SEARCH_BOARD,
  ERROR_UPDATE_BOARD_TASK,
  ERROR_UPDATE_BOARD_TASK_ORDER,
  ERROR_UPDATE_TASK_ATTRIBUTES,
  FETCH_ALL_BOARD_TASKS,
  FETCH_BOARD_COLUMN,
  FETCH_BOARD_URGENCY,
  MESSAGE_SENT_NEW_BOARD_TASK,
  NEW_BOARD_WEBSITE_SELECTED,
  RESET_LOAD_MORE,
  SAVE_NEW_BOARD_GENERAL_TASK,
  SET_ATTRIBUTES_FOR_BOARD_NEW_TASK,
  START_SEARCH_BOARD,
  START_UPDATE_BOARD_TASK_ORDER,
  SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS,
  SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS,
  SUCCESS_BOARD_NEXT_URGENCY_TASKS,
  SUCCESS_BOARD_STATUS_COMPLETE_TASKS,
  SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS,
  SUCCESS_BOARD_STATUS_OPEN_TASKS,
  SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS,
  SUCCESS_BOARD_URGENCY_CRITICAL_TASKS,
  SUCCESS_BOARD_URGENCY_HIGH_TASKS,
  SUCCESS_BOARD_URGENCY_MEDIUM_TASKS,
  SUCCESS_BOARD_URGENCY_TASKS,
  SUCCESS_DELETE_TASK,
  SUCCESS_FETCH_ALL_BOARD_TASKS,
  SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK,
  SUCCESS_SEARCH_BOARD,
  SUCCESS_UPDATE_BOARD_TASK,
  SUCCESS_UPDATE_BOARD_TASK_ORDER,
  SUCCESS_UPDATE_TASK_ATTRIBUTES,
  UPDATE_BOARD_TASK,
  UPDATE_TASK_ATTRIBUTES,
} from 'actions/board';
import { NEW_EMAIL_SELECTED } from 'actions/email';
import {
  NEW_WEBSITE_SELECTED,
  SUCCESS_UPDATE_INTERNAL_TASK,
} from 'actions/tasks';
import { LOGOUT } from 'actions/user';
import {
  bulkUpdateAddTags,
  bulkUpdateReorderCards,
  deleteCards,
  reorderCards,
} from 'utils/board_utils';

const messageStatus = {
  INIT: -1,
  SENT: 1,
  ERROR: 0,
};

const initState = {
  listIsLoading: false,
  filterIsLoading: false,
  attributeIsLoading: false,
  isAllTasks: false,
  taskList: [],
  errorListMessage: '',
  moreRecords: false,
  boardUrgency: false,
  errorUpdateMessage: '',
  successUpdateMessage: '',
  isDeletingTask: false,
  successDeleteTaskMessage: '',
  errorDeleteTaskMessage: '',
  successFetchTaskList: false,
  selectedWebsiteOnBoard: '',
  fatchAllBoardTaskStatus: false,
  newTaskSelectedStatus: '',
  newTaskSelectedUrgency: '',
  newTaskSelectedUsers: [],
  newBoardTaskUrgency: false,
  newBoardTaskStatus: false,
  successNewBoardTaskCreation: '',
  newTaskdata: {},
  errorSearchMessage: '',
  searchText: '',
  successUpdateTaskOrderMessage: '',
  errorUpdateTaskOrderMessage: '',
  updatingTask: false,
  openListIsLoading: false,
  inProgressListIsLoading: false,
  pendingReviewListIsLoading: false,
  completeListIsLoading: false,
  lowListIsLoading: false,
  mediumListIsLoading: false,
  highListIsLoading: false,
  criticalListIsLoading: false,
  openTaskList: [],
  inProgressTaskList: [],
  pendingReviewTaskList: [],
  completeTaskList: [],
  openEmailTaskList: [],
  inProgressEmailTaskList: [],
  pendingReviewEmailTaskList: [],
  completeEmailTaskList: [],
  taskUrgencyLow: [],
  taskUrgencyMedium: [],
  taskUrgencyHigh: [],
  taskUrgencycritical: [],
  emailTaskUrgencyLow: [],
  emailTaskUrgencyMedium: [],
  emailTaskUrgencyHigh: [],
  emailTaskUrgencyCritical: [],
  moreOpenRecords: false,
  moreInProgressRecords: false,
  morePendingReviewRecords: false,
  moreCompleteRecords: false,
  moreLowRecords: false,
  openTaskListCount: 0,
  inProgressTaskListCount: 0,
  pendingReviewTaskListCount: 0,
  completeTaskListCount: 0,
  taskUrgencyLowCount: 0,
  taskUrgencyMediumCount: 0,
  taskUrgencyHighCount: 0,
  taskUrgencycriticalCount: 0,
};

const board = (previousState = initState, action) => {
  switch (action.type) {
    case FETCH_BOARD_URGENCY:
      return {
        ...previousState, // initState is used because it is as
        // good as changing everything to their default value when new website is selected
        boardUrgency: !previousState.boardUrgency,
      };
    case NEW_BOARD_WEBSITE_SELECTED:
      return {
        ...previousState, // initState is used because it is as
        // good as changing everything to their default value when new website is selected
        listIsLoading: true,
        filterIsLoading: true,
        attributeIsLoading: true,
        newCommentsOfOtherTasks: previousState.newCommentsOfOtherTasks,
        usersWatchingList: previousState.usersWatchingList,
        successFetchTaskList: false,
        selectedWebsiteOnBoard: action.websiteId,
        searchText: '',
        fatchAllBoardTaskStatus: false,
        openListIsLoading: true,
        inProgressListIsLoading: true,
        pendingReviewListIsLoading: true,
        completeListIsLoading: true,
        lowListIsLoading: true,
        mediumListIsLoading: true,
        highListIsLoading: true,
        criticalListIsLoading: true,
      };
    case FETCH_ALL_BOARD_TASKS:
      return {
        ...previousState,
        listIsLoading: true,
        attributeIsLoading: true,
        isAllTasks: true,
        filterIsLoading: true,
        successFetchTaskList: false,
        fatchAllBoardTaskStatus: true,
        taskList: [],
      };
    case SUCCESS_FETCH_ALL_BOARD_TASKS:
      return {
        ...previousState,
        listIsLoading: action.listIsLoading,
        taskList: previousState.taskList.concat(action.allTasks),
        attributeIsLoading: action.allTasks.length > 0,
        errorListMessage: '',
        moreRecords: action.moreRecords,
        successFetchTaskList: true,
        fatchAllBoardTaskStatus: true,
      };
    case ERROR_FETCH_ALL_BOARD_TASKS:
      return {
        ...previousState,
        errorListMessage: action.message,
        listIsLoading: false,
        attributeIsLoading: false,
        successFetchTaskList: true,
      };
    case UPDATE_BOARD_TASK:
      if (!action.task_event.email_selected) {
        previousState = reorderCards(
          previousState,
          action.movedCard,
          action.task_event.email_selected
        );
      }
      action.task_event.task_event.sortable('cancel');

      return {
        ...previousState,
        updatingTask: true,
      };

    case FETCH_BOARD_COLUMN: {
      const mapStateToLoading = {
        open: 'openListIsLoading',
        'in-progress': 'inProgressListIsLoading',
        'pending-review': 'pendingReviewListIsLoading',
        complete: 'completeListIsLoading',
        low: 'lowListIsLoading',
        medium: 'mediumListIsLoading',
        high: 'highListIsLoading',
        critical: 'criticalListIsLoading',
      };

      const loadingStates = {};

      // If we are getting new data for a column, make the loading state true.
      for (let datum of action.params.data) {
        const curLoadingState = mapStateToLoading[datum.column_type];
        if (curLoadingState) loadingStates[curLoadingState] = true;
      }

      return {
        ...previousState,
        ...loadingStates,
      };
    }
    case SUCCESS_UPDATE_BOARD_TASK:
      if (!action.emailSelected) {
        return {
          ...previousState,
          successUpdateMessage: 'Task updated!',
          updatingTask: false,
        };
      } else {
        return {
          ...previousState,
          updatingTask: false,
        };
      }
    case ERROR_UPDATE_BOARD_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
        updatingTask: false,
      };
    case UPDATE_TASK_ATTRIBUTES: // managed in tasks reducer
      const { movedCards, updateTaskAttributeParams } = action;
      if (!action.emailSelected.email_selected) {
        if (action.keyOfAttributeUpdated === 'task_tags') {
          previousState = bulkUpdateAddTags(
            previousState,
            movedCards,
            updateTaskAttributeParams.value,
            action.emailSelected.email_selected
          );
        } else {
          previousState = bulkUpdateReorderCards(
            previousState,
            movedCards,
            updateTaskAttributeParams.method,
            updateTaskAttributeParams.value,
            action.emailSelected.email_selected
          );
        }
      }

      return {
        ...previousState,
        openListIsLoading: false,
        inProgressListIsLoading: false,
        pendingReviewListIsLoading: false,
        completeListIsLoading: false,
        lowListIsLoading: false,
        mediumListIsLoading: false,
        highListIsLoading: false,
        criticalListIsLoading: false,
      };
    case SUCCESS_UPDATE_TASK_ATTRIBUTES: {
      if (!action.emailSelected) {
        return {
          ...previousState,
          successUpdateMessage: 'Task attribute updated!',
          taskList: action.data,
          errorUpdateMessage: '',
          // attributes key is getting updated in tasks reducer
        };
      }
    }
    case ERROR_UPDATE_TASK_ATTRIBUTES:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case DELETE_TASK:
      if (!action.isEmail)
        previousState = deleteCards(previousState, action.deletedCards);

      return {
        ...previousState,
        isDeletingTask: true,
        successDeleteTaskMessage: '',
        errorDeleteTaskMessage: '',
      };
    case SUCCESS_DELETE_TASK:
      return {
        ...previousState,
        isDeletingTask: false,
        taskList: previousState.taskList.filter(
          (t) => !action.data.includes(t.task_id)
        ),
        successDeleteTaskMessage: action.message,
        errorDeleteTaskMessage: '',
      };
    case ERROR_DELETE_TASK:
      return {
        ...previousState,
        isDeletingTask: false,
        errorDeleteTaskMessage: action.message,
        successDeleteTaskMessage: '',
      };
    case EMPTY_MESSAGE:
      return {
        ...previousState,
        [action.key]: '',
      };
    case SET_ATTRIBUTES_FOR_BOARD_NEW_TASK:
      return {
        ...previousState,
        [action.name]: action.value,
      };
    case SAVE_NEW_BOARD_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: true,
        errorUpdateMessage: '',
      };
    case SUCCESS_SAVE_NEW_BOARD_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: false,
        errorUpdateMessage: '',
      };
    case ERROR_SAVE_NEW_BOARD_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: false,
        errorUpdateMessage: action.message,
      };
    case MESSAGE_SENT_NEW_BOARD_TASK:
      return {
        ...previousState,
        messageStatus: messageStatus.SENT,
        newTaskdata: {
          data: action.data,
          boardColumns: action.boardParams,
        },
        successNewBoardTaskCreation: action.message,
      };
    case ERROR_IN_SENDING_MESSAGE_NEW_BOARD_TASK:
      return {
        ...previousState,
        messageStatus: messageStatus.ERROR,
        errorNewTaskCreation: action.message,
      };
    case START_SEARCH_BOARD:
      return {
        ...initState,
        listIsLoading: true,
        attributeIsLoading: true,
        filterIsLoading: true,
        isAllTasks: true,
        searchText: action.searchText,
        selectedWebsiteOnBoard: '',
        fatchAllBoardTaskStatus: true,
      };
    case ERROR_SEARCH_BOARD:
      return {
        ...previousState,
        listIsLoading: false,
        attributeIsLoading: false,
        errorSearchMessage: action.message,
      };
    case SUCCESS_SEARCH_BOARD:
      return {
        ...previousState,
        listIsLoading: false,
        attributeIsLoading: action.tasks.length > 0, // if there are tasks then show loading else do not
        taskList: action.tasks,
        moreRecords: action.moreRecords,
        totalRecords: action.totalRecords,
        fatchAllBoardTaskStatus: true,
        isAllTasks: true,
        successFetchTaskList: true,
      };
    case START_UPDATE_BOARD_TASK_ORDER:
      return {
        ...previousState,
      };
    case SUCCESS_UPDATE_BOARD_TASK_ORDER:
      return {
        ...previousState,
        successUpdateTaskOrderMessage: action.message,
      };
    case ERROR_UPDATE_BOARD_TASK_ORDER:
      return {
        ...previousState,
        errorUpdateTaskOrderMessage: action.message,
      };
    case LOGOUT:
      return initState;
    case NEW_EMAIL_SELECTED:
      return {
        ...previousState,
        selectedWebsiteOnBoard: action.id,
        isAllTasks: false,
      };
    case NEW_WEBSITE_SELECTED:
      return {
        ...previousState,
        selectedWebsiteOnBoard: action.websiteId,
      };
    case SUCCESS_UPDATE_INTERNAL_TASK: {
      const tasks = previousState.taskList.map((t) =>
        t.task_id === action.task_id
          ? {
              ...t,
              is_internal: action.is_internal,
            }
          : t
      );

      return {
        ...previousState,
        taskList: tasks,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
      };
    }

    case RESET_LOAD_MORE: {
      return {
        ...previousState,
        moreOpenRecords: false,
        moreInProgressRecords: false,
        morePendingReviewRecords: false,
        moreCompleteRecords: false,
        moreLowRecords: false,
        moreMediumRecords: false,
        moreHighRecords: false,
        moreCriticalRecords: false,
        openListIsLoading: false,
        inProgressListIsLoading: false,
        pendingReviewListIsLoading: false,
        completeListIsLoading: false,
        lowListIsLoading: false,
        mediumListIsLoading: false,
        highListIsLoading: false,
        criticalListIsLoading: false,
      };
    }
    case SUCCESS_BOARD_STATUS_OPEN_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        openTaskList: action.tasks,
        openTaskListCount: action.totalColumnRecords,
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreOpenRecords: action.moreRecords,
        openListIsLoading: false,
      };
    case SUCCESS_BOARD_STATUS_IN_PROGRESS_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        inProgressTaskList: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreInProgressRecords: action.moreRecords,
        inProgressListIsLoading: false,
        inProgressTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_STATUS_PENDING_REVIEW_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        pendingReviewTaskList: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        morePendingReviewRecords: action.moreRecords,
        successFetchTaskList: true,
        pendingReviewListIsLoading: false,
        pendingReviewTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_STATUS_COMPLETE_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        completeTaskList: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreCompleteRecords: action.moreRecords,
        successFetchTaskList: true,
        completeListIsLoading: false,
        completeTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_NEXT_STATUS_OPEN_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        openTaskList: previousState.openTaskList.concat(action.tasks),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreOpenRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_STATUS_IN_PROGRESS_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        inProgressTaskList: previousState.inProgressTaskList.concat(
          action.tasks
        ),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreInProgressRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_STATUS_PENDING_REVIEW_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        pendingReviewTaskList: previousState.pendingReviewTaskList.concat(
          action.tasks
        ),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        morePendingReviewRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_STATUS_COMPLETE_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        completeTaskList: previousState.completeTaskList.concat(action.tasks),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreCompleteRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_URGENCY_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        taskUrgencyLow: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreLowRecords: action.moreRecords,
        successFetchTaskList: true,
        lowListIsLoading: false,
        taskUrgencyLowCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_URGENCY_MEDIUM_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        taskUrgencyMedium: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreMediumRecords: action.moreRecords,
        successFetchTaskList: true,
        mediumListIsLoading: false,
        taskUrgencyMediumCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_URGENCY_HIGH_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        taskUrgencyHigh: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreHighRecords: action.moreRecords,
        successFetchTaskList: true,
        highListIsLoading: false,
        taskUrgencyHighCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_URGENCY_CRITICAL_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        isAllTasks: false,
        taskUrgencycritical: action.tasks,
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreCriticalRecords: action.moreRecords,
        successFetchTaskList: true,
        criticalListIsLoading: false,
        taskUrgencycriticalCount: action.totalColumnRecords,
      };
    case SUCCESS_BOARD_NEXT_URGENCY_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        taskUrgencyLow: previousState.taskUrgencyLow.concat(action.tasks),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreLowRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_MEDIUM_URGENCY_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        taskUrgencyMedium: previousState.taskUrgencyMedium.concat(action.tasks),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreMediumRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_HIGH_URGENCY_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        taskUrgencyHigh: previousState.taskUrgencyHigh.concat(action.tasks),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreHighRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case SUCCESS_BOARD_NEXT_CRITICAL_URGENCY_TASKS:
      return {
        ...previousState,
        errorListMessage: '',
        taskUrgencycritical: previousState.taskUrgencycritical.concat(
          action.tasks
        ),
        attributes: {},
        attributeIsLoading: action.tasks.length > 0,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreCriticalRecords: action.moreRecords,
        successFetchTaskList: true,
      };
    case ERROR_BOARD_STATUS_TASKS:
      return {
        ...previousState,
        errorUpdateMessage: action.message,
      };
    default:
      return previousState;
  }
};

export default board;
