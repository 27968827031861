import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// Components
import LockFeatureModal from '../tasks/LockFeaturePopup';
import CreateWorkspaceModal from '../workspace/CreateWorkspace';
// Styling
import Lock from 'media/svgs/lock-white.svg?react';
import { BiCog, BiPlus } from 'react-icons/bi';
// Utils
import { Avatar } from 'ui/components/Avatar';
import Button from 'ui/components/Button';
import Separator from 'ui/components/Separator';
import { AdminRoles } from 'utils/constants';
import { formatNumber } from 'utils/functions';

const WorkspaceNav = () => {
  const { workspaceData, selectedWorkspace } = useSelector(
    (state) => state.workspace
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData, isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openWorkplace, setOpenWorkplace] = useState(null);
  const [openLockFeatureModal, setOpenLockFeatureModal] = useState(false);
  const [sortedWorkspaces, setSortedWorkspaces] = useState(null);

  const openCreateWorkplace = () => {
    setOpenWorkplace(!openWorkplace);
  };

  const sortWorkspaces = (workspaces) => {
    const personalWorkspaces = [];
    const externalWorkspaces = [];

    workspaces.forEach((ws) => {
      (ws.user_id == userData.normal_id
        ? personalWorkspaces
        : externalWorkspaces
      ).push(ws);
    });

    setSortedWorkspaces({ personalWorkspaces, externalWorkspaces });
  };

  const selectWorkspaceAndNavigate = (value) => {
    if (isLoading) {
      return;
    }
    // get the corresponding workspace from the workspaceData
    const workspace = workspaceData.find((workspace) => workspace.id === value);
    // set the selected workspace in the local storage
    localStorage.setItem('selectedWorkspace', JSON.stringify(workspace));
    // set the selected workspace in the redux store
    dispatch({ type: 'SET_SELECTED_WORKSPACE', workspace });
    dispatch({
      type: 'STORE_SELECTED_WORKSPACE',
      workspaceId: { workspace_id: value },
    });
    // finally we clear the search params
    setSearchParams({});

    // navigate to the projects page
    if (location.pathname.includes('collaborate')) {
      navigate(`/projects`);
    }
    if (!!isUserAdmin() && location.pathname.includes('settings')) {
      navigate(`/projects`);
    }
  };

  const isUserAdmin = () => {
    return AdminRoles.indexOf(selectedWorkspace?.role) !== -1;
  };

  useEffect(() => {
    if (workspaceData.length > 0) {
      sortWorkspaces(workspaceData);
    }
  }, [workspaceData, userData]);

  return (
    <div>
      <div className="popper-inner min-w-[246px]">
        <div className="p-2">Workspaces</div>
        <ul className="flex max-h-72 w-full flex-col gap-1 overflow-y-auto px-0">
          {sortedWorkspaces &&
            sortedWorkspaces.personalWorkspaces.length > 0 && (
              <>
                {sortedWorkspaces.personalWorkspaces.map((workspace, index) => (
                  <li
                    key={index}
                    className={`${
                      workspace.id === selectedWorkspace?.id
                        ? 'active opacity-100'
                        : ''
                    } workspace-container cursor-pointer ${
                      workspace.is_locked && 'workspace-container-locked'
                    } ${isLoading && 'pointer-events-none opacity-50'}`}
                    onClick={() =>
                      !workspace.is_locked &&
                      selectWorkspaceAndNavigate(workspace.id)
                    }
                  >
                    <div className="at-cs-workspace-container">
                      <div className="at-cs-workspace-content">
                        <div className="at-cs-workspace-content-image">
                          <Avatar
                            src={workspace?.icon}
                            alt={workspace?.name}
                            id={workspace?.id}
                            className="h-8 w-8"
                            title={workspace.name}
                            email={workspace?.email}
                          />
                        </div>
                        <span className="at-cs-workspace-content-counter">
                          {workspace.is_locked ? (
                            <Lock width={10} height={10} />
                          ) : (
                            formatNumber(workspace.project_count)
                          )}
                        </span>
                      </div>
                      <div className="workspace-name">{workspace.name}</div>
                    </div>
                    {workspace.id === selectedWorkspace?.id &&
                      isUserAdmin() && (
                        <BiCog
                          className="workspace-settings"
                          onClick={() => {
                            !workspace.is_locked &&
                              navigate(
                                `/settings?tab_flag=workspace&edit=${true}`
                              );
                            dispatch({
                              type: 'SET_SELECTED_WORKSPACE_SETTINGS',
                              workspace,
                              id: workspace?.id,
                            });
                          }}
                        />
                      )}
                  </li>
                ))}
              </>
            )}
          {sortedWorkspaces &&
            sortedWorkspaces.externalWorkspaces.length > 0 && (
              <>
                <div className="flex items-center gap-1 text-xs font-semibold text-gray-300">
                  <span>External</span>
                  <Separator className="shrink bg-gray-200" />
                </div>
                {sortedWorkspaces.externalWorkspaces.map((workspace, index) => (
                  <li
                    key={index}
                    className={`${
                      workspace.id === selectedWorkspace?.id
                        ? 'active opacity-100'
                        : ''
                    } workspace-container cursor-pointer ${
                      workspace.is_locked && 'workspace-container-locked'
                    } ${isLoading && 'cursor-default opacity-50'}`}
                    onClick={() =>
                      !workspace.is_locked &&
                      selectWorkspaceAndNavigate(workspace.id)
                    }
                  >
                    <div className="at-cs-workspace-container">
                      <div className="at-cs-workspace-content">
                        <div className="at-cs-workspace-content-image">
                          <Avatar
                            src={workspace?.icon}
                            alt={workspace?.name}
                            id={workspace?.id}
                            className="h-8 w-8"
                            title={workspace.name}
                          />
                        </div>
                        <span className="at-cs-workspace-content-counter">
                          {workspace.is_locked ? (
                            <Lock width={10} height={10} />
                          ) : (
                            formatNumber(workspace.project_count)
                          )}
                        </span>
                      </div>
                      <div className="workspace-name">{workspace.name}</div>
                    </div>
                    {workspace.id === selectedWorkspace?.id &&
                      isUserAdmin() && (
                        <BiCog
                          className="workspace-settings"
                          onClick={() => {
                            !workspace.is_locked &&
                              navigate(
                                `/settings?tab_flag=workspace&edit=${true}`
                              );
                            dispatch({
                              type: 'SET_SELECTED_WORKSPACE_SETTINGS',
                              workspace,
                              id: workspace?.id,
                            });
                          }}
                        />
                      )}
                  </li>
                ))}
              </>
            )}
        </ul>
        {isUserAdmin() || selectedWorkspace?.pivot?.is_default ? (
          <Button
            className="mt-3 w-full"
            size="sm"
            onClick={() => openCreateWorkplace()}
            leftIcon={<BiPlus size={18} />}
          >
            Create A New Workspace
          </Button>
        ) : null}
      </div>
      {openWorkplace && (
        <CreateWorkspaceModal
          open={openWorkplace}
          openCreateWorkplace={openCreateWorkplace}
          title={`Create a new Workspace`}
          setOpenLockFeatureModal={setOpenLockFeatureModal}
        />
      )}
      <LockFeatureModal
        openLockFeatureModal={openLockFeatureModal}
        lockTitle={'Workspaces'}
        lockParagraph={
          'Create a new workspace with a trial of our Starter plan or upgrade this workspace to unlock more slots.'
        }
        lockImg={
          'https://wpfeedback-image.s3.us-east-2.amazonaws.com/media/locked-workspaces.png'
        }
        handleCloseLock={() => setOpenLockFeatureModal(false)}
        upSellUrl={`${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade${
          userData.source ? `?_from=${userData.source}` : ''
        }`}
      />
    </div>
  );
};

export default WorkspaceNav;
