import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { SUCCESS_UPLOAD_IMAGE_GRAPHICS } from 'actions/collaborate';
import {
  ADD_GRAPHIC_SITE,
  ADD_MILESTONE,
  COMPLETE_MILESTONE,
  CREATE_INSTAWP_SITE,
  CREATE_ROCKET_SITE,
  DELETE_MILESTONE,
  DELETE_SITE,
  ERROR_ACTIVATE_ARCHIVED_SITE,
  ERROR_ADD_GRAPHIC_SITE,
  ERROR_ADD_MILESTONE,
  ERROR_COMPLETE_MILESTONE,
  ERROR_CREATE_INSTAWP_SITE,
  ERROR_CREATE_ROCKET_SITE,
  ERROR_DELETE_MILESTONE,
  ERROR_DELETE_SITE,
  ERROR_DESTINATION_WEBSITES,
  ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT,
  ERROR_FETCH_ARCHIVED_SITES,
  ERROR_FETCH_DASHBOARD_DATA,
  ERROR_FETCH_MILESTONES,
  ERROR_FETCH_NEXT_PROJECT_SITES,
  ERROR_FETCH_NEXT_WEB_LIST,
  ERROR_FETCH_ROCKET_LOCATION,
  ERROR_FETCH_SITES_BASED_ON_PROJECTS,
  ERROR_FETCH_SITE_SETTINGS,
  ERROR_FETCH_SOURCE_WEBSITE,
  ERROR_MERGE_WEBSITES,
  ERROR_NEXT_ARCHIVED_SITES,
  ERROR_SAVE_SITE_SETTINGS,
  ERROR_SEARCH_ARCHIVED,
  ERROR_SOFT_DELETE_SITE,
  ERROR_SYNC_SITE_IMAGE,
  ERROR_UPDATE_MILESTONE_DETAILS,
  FETCH_ALL_PROJECTS_FOR_EXPORT,
  FETCH_CONTRIBUTORS_OF_SITE,
  FETCH_CONTRIBUTORS_OF_SITE_ERROR,
  FETCH_CONTRIBUTORS_OF_SITE_SUCCESS,
  FETCH_DESTINATION_WEBSITES,
  FETCH_MILESTONES,
  FETCH_NEXT_DESTINATION_WEBSITES,
  FETCH_NEXT_PROJECT_SITES,
  FETCH_NEXT_SOURCE_WEBSITE,
  FETCH_NEXT_WEB_LIST,
  FETCH_ROCKET_LOCATION,
  FETCH_SITES_BASED_ON_PROJECTS,
  FETCH_SITE_SETTINGS,
  FETCH_SOURCE_WEBSITE,
  MERGE_WEBSITES,
  SAVE_SITE_SETTINGS,
  SEARCH_ARCHIVED,
  SOFT_DELETE_SITE,
  START_ACTIVATE_ARCHIVED_SITE,
  START_FETCH_ARCHIVED_SITES,
  START_FETCH_DASHBOARD_DATA,
  START_NEXT_ARCHIVED_SITES,
  START_SYNC_SITE_IMAGE,
  SUCCESS_ACTIVATE_ARCHIVED_SITE,
  SUCCESS_ADD_GRAPHIC_SITE,
  SUCCESS_ADD_MILESTONE,
  SUCCESS_COMPLETE_MILESTONE,
  SUCCESS_CREATE_INSTAWP_SITE,
  SUCCESS_CREATE_ROCKET_SITE,
  SUCCESS_DELETE_MILESTONE,
  SUCCESS_DESTINATION_WEBSITES,
  SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT,
  SUCCESS_FETCH_ARCHIVED_SITES,
  SUCCESS_FETCH_DASHBOARD_DATA,
  SUCCESS_FETCH_MILESTONES,
  SUCCESS_FETCH_NEXT_PROJECT_SITES,
  SUCCESS_FETCH_NEXT_SOURCE_WEBSITE,
  SUCCESS_FETCH_NEXT_USER_SITES_DATA,
  SUCCESS_FETCH_NEXT_WEB_LIST,
  SUCCESS_FETCH_ROCKET_LOCATION,
  SUCCESS_FETCH_SITES_BASED_ON_PROJECTS,
  SUCCESS_FETCH_SITE_SETTINGS,
  SUCCESS_FETCH_SOURCE_WEBSITE,
  SUCCESS_FETCH_USER_SITES_DATA,
  SUCCESS_MERGE_WEBSITES,
  SUCCESS_NEXT_ARCHIVED_SITES,
  SUCCESS_NEXT_DESTINATION_WEBSITES,
  SUCCESS_SAVE_SITE_SETTINGS,
  SUCCESS_SEARCH_ARCHIVED,
  SUCCESS_SOFT_DELETE_SITE,
  SUCCESS_SYNC_SITE_IMAGE,
  SUCCESS_UPDATE_MILESTONE_DETAILS,
  UPDATE_CONTRIBUTOR_STATUS_IN_SITE,
  UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR,
  UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS,
  UPDATE_MILESTONE_DETAILS,
  UPDATE_SINGLE_ITEM_FOLDER_NAME,
} from 'actions/dashboard';
import { SET_PLACEHOLDER_MAILBOX_SELECTED } from 'actions/email';
import { LOGOUT } from 'actions/user';
import {
  activateArchivedSite,
  addGraphicSite,
  addMilestone,
  completeMilestone,
  createInstawpSite,
  createRocketSite,
  deleteMilestone,
  deleteWebsite,
  fetchArchivedSites,
  fetchContributors,
  fetchDestinationSite,
  fetchMilestones,
  fetchNextDestinationSite,
  fetchNextSourceSite,
  fetchRocketLocation,
  fetchSourceSite,
  fetchWebsites,
  getProjectsForExport,
  mergeWebsite,
  nextProjectSites,
  searchArchivedSites,
  siteSettings,
  sitesBasedOnProjects,
  softDeleteWebsite,
  syncSiteImage,
  updateContributorStatus,
  updateMilestone,
} from '../api/dashboard';
// Utils
import { REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT } from 'actions/workspace';
import { sendMessageToExtension } from 'utils/chromeExtension';
import { LogoutObjectForPutEffect } from 'utils/constants';
import { getSelectedWorkspace } from 'utils/functions';

export function* fetchDashboardData(action) {
  let userId = '';
  if (action.userId !== undefined) {
    userId = action.userId;
  }
  let searchText = '';
  if (action.searchText !== undefined) {
    searchText = action.searchText;
  }
  let siteOrder = '';
  if (action.site_order !== undefined) {
    siteOrder = action.site_order;
  } else {
    siteOrder = localStorage.getItem('siteSorting');
  }
  let projectId = '';
  if (action.project_id !== undefined) {
    projectId = action.project_id;
  }
  let screen = '';
  if (action.timeEntries !== undefined) {
    screen = 'time-entries';
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchWebsites,
    `${action.url}?offset=0&limit=16&site=${action.selectedWebsite}&userId=${userId}&search_keyword=${searchText}&site_order=${siteOrder}&project_id=${projectId}&screen=${screen}&filter_by=${action.filterWp},${action.filterCollab},${action.filterImg}&workspace_id=${selectedWorkspace?.id}`
  );

  if (data.status) {
    if (data.data.length === 0) {
      yield put({
        type: SET_PLACEHOLDER_MAILBOX_SELECTED,
        value: true,
      });
    }
    // update another state for sites of specific user so not to update all dashboard data
    if (action.userId) {
      yield put({
        type: SUCCESS_FETCH_USER_SITES_DATA,
        data: data.data,
        moreWebRecords: data.more_web_records,
      });
    } else {
      yield put({
        type: SUCCESS_FETCH_DASHBOARD_DATA,
        data: data.data,
        moreWebRecords: data.more_web_records,
      });
    }
  } else if (data.unauthenticated) {
    yield put({
      type: LOGOUT,
      message: 'User unauthenticated, please login again!',
      error: true,
    });
  } else {
    // error
    yield put({
      type: ERROR_FETCH_DASHBOARD_DATA,
      error: data.message,
    });
  }
}

export function* rootDashboard() {
  yield takeLatest(
    [
      START_FETCH_DASHBOARD_DATA,
      // SUCCESS_UPDATE_BOARD_TASK,
      // SUCCESS_UPDATE_TASK_ATTRIBUTES,
    ],
    fetchDashboardData
  );
}

export function* fetchContributorsListWatcher() {
  while (true) {
    const { websiteId, workspaceId } = yield take(FETCH_CONTRIBUTORS_OF_SITE);
    const data = yield call(fetchContributors, websiteId, workspaceId);
    if (data.status) {
      yield put({
        type: FETCH_CONTRIBUTORS_OF_SITE_SUCCESS,
        contributors: data.data,
      });
    } else if (data.unauthenticated) {
      yield put(LogoutObjectForPutEffect);
    } else {
      yield put({
        type: FETCH_CONTRIBUTORS_OF_SITE_ERROR,
      });
    }
  }
}

function* updateContributorStatusWorker(
  contributorId,
  attachContributor,
  websiteId
) {
  const data = yield call(updateContributorStatus, websiteId, {
    contributor_id: contributorId,
    attach_contributor: attachContributor,
  });
  if (data.status) {
    yield put({
      type: UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS,
      userId: contributorId,
      selected: attachContributor,
      message:
        data.message || `User ${attachContributor ? 'added' : 'removed'}`,
      websiteId, // this is added so I can update the list of users shown in website box
    });
    // bad approach, fix it later
    yield put({
      type: FETCH_SITES_BASED_ON_PROJECTS,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateContributorStatusWatcher() {
  while (true) {
    const { contributorId, attachContributor, websiteId } = yield take(
      UPDATE_CONTRIBUTOR_STATUS_IN_SITE
    );
    yield fork(
      updateContributorStatusWorker,
      contributorId,
      attachContributor,
      websiteId
    );
  }
}

function* fetchNextWebWorker(action) {
  let userId = '';
  if (action.userId !== undefined) {
    userId = action.userId;
  }
  let siteOrder = '';
  if (action.site_order !== undefined) {
    siteOrder = action.site_order;
  }
  let screen = '';
  if (action.timeEntries !== undefined) {
    screen = 'time-entries';
  }
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const data = yield call(
    fetchWebsites,
    `${action.url}?offset=${action.offset}&limit=8&userId=${userId}&site_order=${siteOrder}&screen=${screen}&filter_by=${action.filterWp},${action.filterCollab},${action.filterImg}&workspace_id=${selectedWorkspace?.id}`
  );

  if (data.status) {
    // successfully fetched
    if (action.userId) {
      yield put({
        type: SUCCESS_FETCH_NEXT_USER_SITES_DATA,
        data: data.data,
        moreWebRecords: data.more_web_records,
      });
    } else {
      yield put({
        type: SUCCESS_FETCH_NEXT_WEB_LIST,
        data: data.data,
        moreWebRecords: data.more_web_records,
      });
    }
  } else if (data.unauthenticated) {
    yield put({
      type: LOGOUT,
      message: 'User unauthenticated, please login again!',
      error: true,
    });
  } else {
    // error
    yield put({
      type: ERROR_FETCH_NEXT_WEB_LIST,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchNextWebWatcher() {
  yield takeLatest(FETCH_NEXT_WEB_LIST, fetchNextWebWorker);
}

function* softDeleteSiteWorker(action) {
  const { websiteId, websiteName, userId } = action;
  const siteIds = websiteId.join(',');
  const payload = {
    siteIds: siteIds,
  };
  const response = yield call(softDeleteWebsite, payload);
  if (response.status) {
    yield put({
      type: SUCCESS_SOFT_DELETE_SITE,
      message:
        websiteId.length === 1
          ? `Project archived successfully`
          : `Projects archived successfully`,
      websiteId,
    });
    yield put({
      type: REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT,
    });
    yield put({
      type: FETCH_SITES_BASED_ON_PROJECTS,
    });
    yield put({
      type: START_FETCH_ARCHIVED_SITES,
      userId,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SOFT_DELETE_SITE,
      message: `Could not archive ${websiteName}. Try again later!`,
    });
  }
}

export function* softDeleteSiteWatcher() {
  yield takeEvery(SOFT_DELETE_SITE, softDeleteSiteWorker);
}

function* archivedSitesWorker(action) {
  const { userId } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    fetchArchivedSites,
    userId,
    action.offset,
    action.limit,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_ARCHIVED_SITES,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ARCHIVED_SITES,
      error: response.message,
    });
  }
}

export function* archivedSitesWatcher() {
  yield takeEvery(START_FETCH_ARCHIVED_SITES, archivedSitesWorker);
}

function* nextArchivedSitesWorker(action) {
  let userId = '';
  if (action.userId !== undefined) {
    userId = action.userId;
  }

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(
    fetchArchivedSites,
    userId,
    action.offset,
    action.limit,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_NEXT_ARCHIVED_SITES,
      data: data.data,
      offset: action.offset,
      moreArchivedRecords: data.more_web_records,
    });
  } else if (data.unauthenticated) {
    yield put({
      type: LOGOUT,
      message: 'User unauthenticated, please login again!',
      error: true,
    });
  } else {
    yield put({
      type: ERROR_NEXT_ARCHIVED_SITES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* nextArchivedSitesWatcher() {
  yield takeLatest(START_NEXT_ARCHIVED_SITES, nextArchivedSitesWorker);
}

function* activateArchivedSiteWorker(action) {
  const { siteId, siteName } = action;
  const response = yield call(activateArchivedSite, siteId);

  if (response.status) {
    yield put({
      type: SUCCESS_ACTIVATE_ARCHIVED_SITE,
      message: `${siteName} restored successfully`,
      siteId,
    });
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      url: '/site',
      selectedWebsite: '',
    });
  } else {
    yield put({
      type: ERROR_ACTIVATE_ARCHIVED_SITE,
      error: response.message,
    });
  }
}

export function* activateArchivedSiteWatcher() {
  yield takeEvery(START_ACTIVATE_ARCHIVED_SITE, activateArchivedSiteWorker);
}

function* syncSiteImageWorker(action) {
  const { siteId } = action;
  const response = yield call(syncSiteImage, siteId);

  if (response.status) {
    yield put({
      type: SUCCESS_SYNC_SITE_IMAGE,
      status: response.status,
      message: response.message || 'Image updated successfully',
    });
  } else {
    yield put({
      type: ERROR_SYNC_SITE_IMAGE,
      status: response.status,
      message:
        response.data.message ||
        'An unknown error occured while syncing website image',
    });
  }
}

export function* syncSiteImageWatcher() {
  yield takeEvery(START_SYNC_SITE_IMAGE, syncSiteImageWorker);
}

function* addGraphicSiteWorker(action) {
  const { data } = action;
  const response = yield call(addGraphicSite, data);

  if (response.status) {
    yield put({
      type: SUCCESS_ADD_GRAPHIC_SITE,
      data: response.result,
      message: response.message,
      isFromProjects:
        String(data.get('isFromProjects')).toLowerCase() === 'true', // convert to boolean and check if true
    });
    data.has('site_id')
      ? yield put({
          type: SUCCESS_UPLOAD_IMAGE_GRAPHICS,
          graphics: response.result.graphics,
          message: response.message,
        })
      : yield put({
          type: START_FETCH_DASHBOARD_DATA,
          selectedWebsite: '',
          url: '/site',
        });
  } else {
    yield put({
      type: ERROR_ADD_GRAPHIC_SITE,
      message: response.message,
    });
  }
}

export function* addGraphicSiteWatcher() {
  yield takeLatest(ADD_GRAPHIC_SITE, addGraphicSiteWorker);
}

function* fetchSiteSettingsWorker(action) {
  const { websiteId } = action;
  const response = yield call(siteSettings, { site_id: websiteId });

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_SITE_SETTINGS,
      data: response.result,
      websiteId: websiteId,
    });
  } else {
    yield put({
      type: ERROR_FETCH_SITE_SETTINGS,
      message: response.message,
    });
  }
}

export function* fetchSiteSettingsWatcher() {
  yield takeLatest(FETCH_SITE_SETTINGS, fetchSiteSettingsWorker);
}

function* updateSiteSettingsWorker(action) {
  const { form, folderName } = action;
  const response = yield call(siteSettings, form);
  if (response.status) {
    yield put({
      type: SUCCESS_SAVE_SITE_SETTINGS,
      siteId: response.result.site_id,
      message: action.message || 'Saved successfully!',
      siteName: response.result.site_name,
      firstProjectId: action.firstProjectId,
      updatedProjectId: response.result.project?.id,
      folderName: folderName,
    });
    if (folderName) {
      yield put({
        type: UPDATE_SINGLE_ITEM_FOLDER_NAME,
        folderName: folderName,
        siteId: response.result.site_id,
        message: action.message || 'Saved successfully!',
      });
    }
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SAVE_SITE_SETTINGS,
      message: response.message,
    });
  }
}

export function* updateSiteSettingsWatcher() {
  yield takeLatest(SAVE_SITE_SETTINGS, updateSiteSettingsWorker);
}

function* fetchProjectsBasedSitesWorker(action) {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    sitesBasedOnProjects,
    action.siteOrder,
    action.filterWp,
    action.filterCollab,
    action.filterImg,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_SITES_BASED_ON_PROJECTS,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_SITES_BASED_ON_PROJECTS,
      error: response.message,
    });
  }
}

export function* fetchProjectsBasedSitesWatcher() {
  yield takeLatest(
    FETCH_SITES_BASED_ON_PROJECTS,
    fetchProjectsBasedSitesWorker
  );
}

function* fetchNextProjectSitesWorker(action) {
  const { projectId, offset } = action;

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    nextProjectSites,
    projectId,
    offset,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_PROJECT_SITES,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_PROJECT_SITES,
      error: response.message,
    });
  }
}

export function* fetchNextProjectSitesWatcher() {
  yield takeLatest(FETCH_NEXT_PROJECT_SITES, fetchNextProjectSitesWorker);
}

function* fetchMilestonesWorker(action) {
  const { siteId } = action;
  const response = yield call(fetchMilestones, siteId);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_MILESTONES,
      data: response.result,
      siteId: siteId,
    });
  } else {
    yield put({
      type: ERROR_FETCH_MILESTONES,
      error: response.message,
    });
  }
}

export function* fetchMilestonesWatcher() {
  yield takeLatest(FETCH_MILESTONES, fetchMilestonesWorker);
}

function* addMilestoneWorker(action) {
  const { params } = action;
  const response = yield call(addMilestone, params);
  if (response.status) {
    yield put({
      type: SUCCESS_ADD_MILESTONE,
      message: 'Project stage created successfully!',
      data: response.result,
    });
    yield put({
      type: FETCH_MILESTONES,
      siteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_ADD_MILESTONE,
      error: response.message,
    });
  }
}

export function* addMilestoneWatcher() {
  yield takeLatest(ADD_MILESTONE, addMilestoneWorker);
}

function* completeMilestoneWorker(action) {
  const { params } = action;
  const response = yield call(completeMilestone, params);

  if (response.status) {
    yield put({
      type: SUCCESS_COMPLETE_MILESTONE,
      message: response.message,
    });
  } else {
    yield put({
      type: ERROR_COMPLETE_MILESTONE,
      error: response.message,
    });
  }
}

export function* completeMilestoneWatcher() {
  yield takeLatest(COMPLETE_MILESTONE, completeMilestoneWorker);
}

function* updateMilestoneWorker(action) {
  const { params } = action;
  const response = yield call(updateMilestone, params);
  if (response.status) {
    yield put({
      type: SUCCESS_UPDATE_MILESTONE_DETAILS,
      message: response.message,
      content: response.milestone,
      due_date_at: params.due_date_at,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_MILESTONE_DETAILS,
      error: response.message,
    });
  }
}

export function* updateMilestoneWatcher() {
  yield takeLatest(UPDATE_MILESTONE_DETAILS, updateMilestoneWorker);
}

function* deleteMilestoneWorker(action) {
  const { params } = action;
  const response = yield call(deleteMilestone, params);

  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_MILESTONE,
      message: response.message,
      id: params.milestone_id,
      site_id: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_DELETE_MILESTONE,
      error: response.message,
    });
  }
}

export function* deleteMilestoneWatcher() {
  yield takeLatest(DELETE_MILESTONE, deleteMilestoneWorker);
}

function* mergeWebsiteWorker(action) {
  const { mergeObj } = action;
  const response = yield call(mergeWebsite, mergeObj);
  if (response.status) {
    yield put({
      type: SUCCESS_MERGE_WEBSITES,
      message: response.message,
    });
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      selectedWebsite: '',
      url: '/site',
    });
  } else {
    yield put({
      type: ERROR_MERGE_WEBSITES,
      error: response.message,
    });
  }
}

export function* mergeWebsiteWatcher() {
  yield takeLatest(MERGE_WEBSITES, mergeWebsiteWorker);
}

function* createRocketSiteWorker(action) {
  const { siteObj } = action;
  const response = yield call(createRocketSite, siteObj);
  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_ROCKET_SITE,
      message: response.message,
      status: response,
    });
  } else {
    yield put({
      type: ERROR_CREATE_ROCKET_SITE,
      error: response.message,
    });
  }
}

export function* createRocketSiteWatcher() {
  yield takeLatest(CREATE_ROCKET_SITE, createRocketSiteWorker);
}

function* fetchRocketLocationWorker() {
  const response = yield call(fetchRocketLocation);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_ROCKET_LOCATION,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ROCKET_LOCATION,
      error: response.message,
    });
  }
}

export function* fetchRocketLocationWatcher() {
  yield takeLatest(FETCH_ROCKET_LOCATION, fetchRocketLocationWorker);
}

function* fetchSourceSiteWorker(action) {
  const { fetch_all } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    fetchSourceSite,
    fetch_all,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_SOURCE_WEBSITE,
      data: response.data,
      moreSource: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_SOURCE_WEBSITE,
      error: response.message,
    });
  }
}

export function* fetchSourceSiteWatcher() {
  yield takeLatest(FETCH_SOURCE_WEBSITE, fetchSourceSiteWorker);
}

function* fetchNextSourceSiteWorker(action) {
  const { fetch_all, offset } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    fetchNextSourceSite,
    fetch_all,
    offset,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_SOURCE_WEBSITE,
      data: response.data,
      moreSource: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_SOURCE_WEBSITE,
      error: response.message,
    });
  }
}

export function* fetchNextSourceSiteWatcher() {
  yield takeLatest(FETCH_NEXT_SOURCE_WEBSITE, fetchNextSourceSiteWorker);
}

function* fetchDestinationSiteWorker(action) {
  const { sourceObj } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    fetchDestinationSite,
    sourceObj,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_DESTINATION_WEBSITES,
      data: response.data,
      moreDestination: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_DESTINATION_WEBSITES,
      error: response.message,
    });
  }
}

export function* fetchDestinationSiteWatcher() {
  yield takeLatest(FETCH_DESTINATION_WEBSITES, fetchDestinationSiteWorker);
}

function* fetchNextDestinationSiteWorker(action) {
  const { sourceObj, offset } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    fetchNextDestinationSite,
    sourceObj,
    offset,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_NEXT_DESTINATION_WEBSITES,
      data: response.data,
      moreDestination: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_DESTINATION_WEBSITES,
      error: response.message,
    });
  }
}

export function* fetchNextDestinationSiteWatcher() {
  yield takeLatest(
    FETCH_NEXT_DESTINATION_WEBSITES,
    fetchNextDestinationSiteWorker
  );
}

function* createInstawpSiteWorker(action) {
  const { siteObj } = action;
  const response = yield call(createInstawpSite, siteObj);
  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_INSTAWP_SITE,
      message: response.message,
      status: response.data,
    });
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      selectedWebsite: '',
      url: '/site',
    });
    window.location.href = response.data.redirect_url;
  } else if (response.unauthenticated) {
    yield put({
      type: LOGOUT,
      message: 'User unauthenticated, please login again!',
      error: true,
    });
  } else {
    yield put({
      type: ERROR_CREATE_INSTAWP_SITE,
      error: response.message,
    });
  }
}

export function* createInstawpSiteWatcher() {
  yield takeLatest(CREATE_INSTAWP_SITE, createInstawpSiteWorker);
}

function* searchArchivedWorker(action) {
  const { userId, pageNo, limit, text } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(
    searchArchivedSites,
    userId,
    pageNo,
    limit,
    text,
    selectedWorkspace?.id
  );

  if (response.status) {
    yield put({
      type: SUCCESS_SEARCH_ARCHIVED,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_SEARCH_ARCHIVED,
      error: response.message,
    });
  }
}

export function* searchArchivedWatcher() {
  yield takeLatest(SEARCH_ARCHIVED, searchArchivedWorker);
}

function* fetchProjectsForExportWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(getProjectsForExport, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT,
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT,
      error: response.message,
    });
  }
}

export function* fetchProjectsForExportWatcher() {
  yield takeLatest(FETCH_ALL_PROJECTS_FOR_EXPORT, fetchProjectsForExportWorker);
}

function* deleteSiteWorker(action) {
  const { websiteId, websiteName } = action;
  const siteIds = websiteId.join(',');
  const payload = {
    ids: siteIds,
  };
  const response = yield call(deleteWebsite, payload);
  if (response.status) {
    sendMessageToExtension({
      type: 'projectStatusUpdate',
      data: { websiteId: websiteId },
    });
    yield put({
      type: REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT,
    });
    yield put({
      type: SUCCESS_SOFT_DELETE_SITE,
      message:
        websiteId.length === 1
          ? `Project deleted successfully`
          : `Projects deleted successfully`,
      websiteId,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_SITE,
      message: `Could not delete ${websiteName}. Try again later!`,
    });
  }
}

export function* deleteSiteWatcher() {
  yield takeEvery(DELETE_SITE, deleteSiteWorker);
}
