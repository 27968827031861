import API from '../api';
import cleanApi from '../services/axiosClient';
import { taskLimit } from 'utils/constants';

export const getItemDetails = (id, reload, pageId, toggleProxyMethod) => {
  return API.get(
    `/collaborate/site/${id}?reload=${reload}${pageId ? `&page_id=${pageId}` : ''}${toggleProxyMethod ? `&toggle_proxy_method=${toggleProxyMethod}` : ''}`
  );
};

export const createSiteTask = (params) => {
  return API.post(`/collaborate/site/tasks/new`, params);
};
export const createGraphicTask = (params) => {
  return API.post(`/collaborate/graphic/tasks/new`, params);
};

export const getSiteTasks = (
  id,
  textSearch,
  notifiedUsers,
  taskType,
  orderBy
) => {
  return API.get(
    `/collaborate/site/tasks/${id}?text_search=${textSearch}&limit=${taskLimit}&offset=0&notified_users=${notifiedUsers}&order_by=${orderBy}`,
    taskType
  );
};

export const fetchNextSiteTasks = (
  id,
  offSet,
  textSearch,
  notifiedUsers,
  taskType,
  orderBy
) => {
  return API.get(
    `/collaborate/site/tasks/${id}?offset=${offSet}&limit=${taskLimit}&text_search=${textSearch}&notified_users=${notifiedUsers}&order_by=${orderBy}`,
    taskType
  );
};

export const addNewSite = (params) => {
  return API.post(`/collaborate/site/add`, params);
};

export const getSiteCurrentPageTasks = (
  id,
  page,
  textSearch,
  notifiedUsers,
  taskType,
  orderBy
) => {
  return API.get(
    `/collaborate/site/tasks/${id}?current_page_url=${page}&limit=${taskLimit}&offset=0&text_search=${textSearch}&notified_users=${notifiedUsers}&order_by=${orderBy}`,
    taskType
  );
};

export const getGraphicCurrentPageTasks = (id) => {
  return API.get(
    `/collaborate/graphic/tasks/${id}?limit=${taskLimit}&offset=0`
  );
};

export const fetchCurrentPageTasks = (
  id,
  page,
  offSet,
  textSearch,
  notifiedUsers,
  taskType,
  orderBy
) => {
  return API.get(
    `/collaborate/site/tasks/${id}?current_page_url=${page}&offset=${offSet}&limit=${taskLimit}&text_search=${textSearch}&notified_users=${notifiedUsers}&order_by=${orderBy}`,
    taskType
  );
};

export const addTaskComment = (params) => {
  return API.post(`/collaborate/comments/new`, params);
};

export const updataTaskStatus = (params) => {
  return API.post(`/collaborate/tasks/update`, params);
};

export const attachFileUpload = (params) => {
  return API.post(`/collaborate/comments/upload-file`, params);
};

export function getCollTaskAttributes(id) {
  return API.get(`/collaborate/tasks/${id}`);
}

export function deleteTask(params) {
  return API.post(`/collaborate/tasks/trash`, params);
}

export function deleteVersion(params) {
  return API.post(`/collaborate/graphic/delete-version`, params);
}

export function deleteDesign(params) {
  return API.post(`/collaborate/graphic/delete`, params);
}

export function deleteComment(params) {
  return API.post(`/collaborate/comments/trash`, params);
}

export function addApprovePage(params) {
  return API.post(`/collaborate/page/approve`, params);
}

export const userRegistration = (params) => {
  return cleanApi.post(`/collaborate/user/register`, {
    ...params,
    site_id: params.siteId,
  });
};

export const userLogin = (params) => {
  return API.post(`/collaborate/user/login`, params);
};

export const updateSiteURL = (params) => {
  return API.post(`/collaborate/site/update`, params);
};

export const upgradeAccount = () => {
  return API.post(`/collaborate/user/upgrade-account`);
};

export const addGraphicVersion = (data) => {
  return API.post(`/collaborate/graphic/add-new-version`, data);
};

export const updateGraphicTile = (data) => {
  return API.post(`/collaborate/graphic/update`, data);
};

export const getGraphicVersions = (id) => {
  return API.get(`/collaborate/graphic/${id}`);
};

export const createHotspot = (data) => {
  return API.post(`/collaborate/graphic/hotspot/create`, data);
};

export const updateHotspot = (data) => {
  return API.post(`/collaborate/graphic/hotspot/update`, data);
};

export const deleteHotspot = (data) => {
  return API.post(`/collaborate/graphic/hotspot/delete`, data);
};

export const updateGodaddayDone = (params) => {
  return API.post(`/push_to_godaddy`, params);
};

export const addNewCustomSite = (params) => {
  return API.post(`/one_click_collaborate`, params);
};

export const createAnnotatedTask = (params) => {
  return API.post(`/collaborate/site/tasks/new-annotated-task`, params);
};

export const inviteCollabs = (params) => {
  return API.post(`/collaborate/site/invite-people`, params);
};

export const getinvitedColabUsers = (siteId) => {
  return API.get(`/collaborate/site/invited-users/${siteId}`);
};

export const removeInviteCollabs = (params) => {
  return API.post(`/collaborate/site/remove-invited-user`, params);
};

export const acceptInvitation = (token) => {
  return API.get(`/collaborate/site/accept-invitation?token=${token}`);
};

export const addStandardFlowSite = (params) => {
  return API.post(`/standard_flow_collaborate`, params);
};

export const commentEditHistory = (commentId) => {
  return API.get(`/collaborate/comments/${commentId}/history`);
};

export const reinviteCollabUser = (params) => {
  return API.post(`/collaborate/site/re-invite`, params);
};

export const addPage = (params) => {
  return API.post(`/site/page`, params);
};

export function fetchPages(websiteId) {
  return API.get(`/site/pages/${websiteId}`);
}

export function deletePage(pageId) {
  return API.trash(`site/pages/${pageId}`);
}

export function deleteCommentFiles(params) {
  return API.post(`/collaborate/comments/delete-files`, params);
}

export const countOpenTask = (params) => {
  return API.get(`/collaborate/total-task-count/?site_id=${params}`);
};

export const postExtension = () => {
  return API.post(`/metrics/extension-installed`);
};

export const fetchDummyTasks = (id) => {
  return API.get(`/collaborate/site/tasks/${id}/initial-tasks`);
};
