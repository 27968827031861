import API from '../api';
import { ENTRIES_LISTING_PAGE_LIMIT } from 'utils/constants';

export function fetchWebsites(url) {
  return API.get(url);
}

export function fetchContributors(websiteId, workspaceId) {
  return API.get(`/site/contributors/${websiteId}?workspace_id=${workspaceId}`);
}

export function updateContributorStatus(websiteId, paramObj) {
  return API.post(`site/set-contributor/${websiteId}`, paramObj);
}

export function softDeleteWebsite(websiteIds) {
  return API.post(`/site/bulk-delete`, websiteIds);
  // return API.get(`/site/delete-site/${websiteId}`);
}

export function fetchArchivedSites(userId, offset, limit, selectedWorkspaceId) {
  return API.get(
    `archived-sites/${userId}?page_no=${offset}&limit=${limit}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function activateArchivedSite(siteId) {
  return API.post(`activate-archived-sites?site_id=${siteId}`);
}

export function syncSiteImage(siteId) {
  return API.post(`/site/${siteId}/sync-thumbnail`);
}

export function addGraphicSite(data) {
  return API.post(`/collaborate/graphic/create`, data);
}

export function siteSettings(data) {
  return API.post(`/collaborate/site/setting`, data);
}

export function sitesBasedOnProjects(
  params,
  filterWp,
  filterUrl,
  filterImg,
  selectedWorkspaceId
) {
  return API.get(
    `/site/project-grouped?site_order=${params}&filter_by=${filterWp},${filterUrl},${filterImg}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function nextProjectSites(projectId, offset, selectedWorkspaceId) {
  return API.get(
    `/site/project-grouped?project_id=${projectId}&offset=${offset}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchMilestones(siteId) {
  return API.get(`site/milestone/all/${siteId}`);
}

export function addMilestone(params) {
  return API.post(`site/milestone/new`, params);
}

export function completeMilestone(params) {
  return API.post(`/site/milestone/update`, params);
}

export function updateMilestone(params) {
  return API.post(`/site/milestone/update`, params);
}

export function deleteMilestone(params) {
  return API.post(`/site/milestone/delete`, params);
}

export function mergeWebsite(params) {
  return API.post(`/merge_sites`, params);
}

export function createRocketSite(params) {
  return API.post(`rocket/create_site`, params);
}

export function fetchRocketLocation() {
  return API.get(`/rocket/get_location`);
}

export function fetchSourceSite(params, selectedWorkspaceId) {
  return API.get(
    `/source_sites?fetch_all=${params}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchNextSourceSite(params, offset, selectedWorkspaceId) {
  return API.get(
    `/source_sites?fetch_all=${params}&offset=${offset}&limit=${ENTRIES_LISTING_PAGE_LIMIT}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchDestinationSite(params, selectedWorkspaceId) {
  return API.post(
    `/destination_sites${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function fetchNextDestinationSite(params, offset, selectedWorkspaceId) {
  return API.post(
    `/destination_sites?offset=${offset}&limit=${ENTRIES_LISTING_PAGE_LIMIT}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function createInstawpSite(params) {
  return API.post(`/instawp_create_site`, params);
}

export function searchArchivedSites(
  userId,
  offset,
  limit,
  text,
  selectedWorkspaceId
) {
  return API.get(
    `archived-sites/${userId}?page_no=${offset}&limit=${limit}&search=${text}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function getProjectsForExport(selectedWorkspaceId) {
  return API.get(
    `/site?export=true${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function deleteWebsite(websiteIds) {
  return API.post(`/site/delete`, websiteIds);
}
