import React from 'react';
//styling
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const IosSwitchLarge = withStyles((theme) => ({
  root: {
    minWidth: '400px',
    maxWidth: '400px',
    border: ' 1px solid #ffffff80',
    height: '64px !important',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    padding: 0,
    display: 'flex',
    borderRadius: '40px',
    alignItems: 'center !important',
    '&::before': {
      content: '"Billed Monthly"',
      position: 'absolute',
      whiteSpace: 'nowrap',
      left: -10,
      zIndex: 2,
      pointerEvents: (props) => (!props.isYearlyPlan ? 'all' : 'none'),
      borderRadius: 40,
      fontWeight: 'bold',
      fontSize: '20px !important',
      color: '#fff',
      padding: '20px 12px 20px 46px',
      fontFamily: theme.text.normalText.fontFamily,
      cursor: 'pointer',
      width: '175px',
    },
    '&::after': {
      content: '" Billed Annually"',
      whiteSpace: 'nowrap',
      position: 'absolute',
      // top: 0,
      left: 200,
      width: '175px',
      borderRadius: 40,
      zIndex: 2,
      pointerEvents: (props) => (props.isYearlyPlan ? 'all' : 'none'),
      fontWeight: 'bold',
      fontSize: '20px !important',
      color: '#fff',
      fontFamily: theme.text.normalText.fontFamily,
      padding: '20px 15px 20px 33px',
      cursor: 'pointer',
    },
  },
  switchBase: {
    transition: '.5s',
    padding: 1,
    '&$checked': {
      transition: '.5s',
      transform: 'translateX(197px) !important',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: 'rgba(240, 248, 255, 0.200)',
        border: 'none !important',
      },
    },
  },
  thumb: {
    width: '190px',
    minWidth: '190px',
    height: '50px',
    boxShadow: 'none',
    marginTop: '5px',
    border: 'none !important',
    borderRadius: 40,
    backgroundColor: '#6D5DF3',
    marginLeft: '5px',
    pointerEvents: 'none',
  },
  track: {
    borderRadius: 8 / 2,
    opacity: 1,
    backgroundColor: 'rgba(240, 248, 255, 0.200)',
    minWidth: '400px',
    maxWidth: '400px',
    border: 'none !important',
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  const { isYearlyPlan } = props;

  return (
    <Switch
      checked={isYearlyPlan}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IosSwitchLarge;
