import { call, put, takeLatest } from 'redux-saga/effects';
// Actions
import { START_FETCH_DASHBOARD_DATA } from 'actions/dashboard';
import {
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  EDIT_WORKSPACE,
  ERROR_CREATE_WORKSPACE,
  ERROR_DELETE_WORKSPACE,
  ERROR_EDIT_WORKSPACE,
  ERROR_FETCH_ACCOUNT_USERS,
  ERROR_FETCH_NEXT_ACCOUNT_USERS,
  ERROR_FETCH_WORKSPACES,
  ERROR_FETCH_WORKSPACE_OWNER,
  FETCH_ACCOUNT_USERS,
  FETCH_NEXT_ACCOUNT_USERS,
  FETCH_WORKSPACES,
  FETCH_WORKSPACE_OWNER,
  GET_WORKSPACE_DETAILS,
  SET_SELECTED_WORKSPACE,
  STORE_SELECTED_WORKSPACE,
  SUCCESS_CREATE_WORKSPACE,
  SUCCESS_DELETE_WORKSPACE,
  SUCCESS_EDIT_WORKSPACE,
  SUCCESS_FETCH_ACCOUNT_USERS,
  SUCCESS_FETCH_NEXT_ACCOUNT_USERS,
  SUCCESS_FETCH_WORKSPACES,
  SUCCESS_FETCH_WORKSPACE_OWNER,
} from 'actions/workspace';
// API
import { sendMessageToExtension } from 'utils/chromeExtension';
import {
  WorkspaceOwners,
  createWorkspaces,
  deleteWorkspaces,
  editWorkspaces,
  getAccountUsers,
  getNextAccountUsers,
  getWorkspaceDetails,
  getWorkspaces,
  storeWorkspace,
} from '../api/workspace';
// Utils
import { EMPTY_SITE_LIST_USER } from 'actions/activity';
import {
  START_FETCH_ALL_SNIPPET,
  START_FETCH_ALL_SNIPPET_TEMPLATE,
} from 'actions/global_settings';
import { getSelectedWorkspace } from 'utils/functions';

function* fetchWorkspacesWorker() {
  const response = yield call(getWorkspaces);

  if (response?.status) {
    yield put({
      type: SUCCESS_FETCH_WORKSPACES,
      data: response?.data,
    });
    const selectedWorkspace = yield call(getSelectedWorkspace);
    // if selected workspace is not there in the local storage or is undefined, set the first workspace as selected
    if (!selectedWorkspace) {
      const firstWorkspace = response?.data[0];
      yield put({
        type: SET_SELECTED_WORKSPACE,
        workspace: firstWorkspace,
      });
      // save the selected workspace in the local storage
      localStorage.setItem('selectedWorkspace', JSON.stringify(firstWorkspace));
    } else {
      // find selected workspace in the user workspace list
      let newWorkspace = response.data.find(
        (workspace) => workspace.id === selectedWorkspace?.id
      );
      // check if the selected workspce is available in the list
      if (newWorkspace) {
        // save the selected workspace in the local storage
        localStorage.setItem('selectedWorkspace', JSON.stringify(newWorkspace));
        // set the selected workspace in the redux store
        yield put({
          type: SET_SELECTED_WORKSPACE,
          workspace: newWorkspace,
        });
      } else {
        // if selected workspace is not there in the user workspace list, set the first workspace as selected
        const firstWorkspace = response?.data[0];
        yield put({
          type: SET_SELECTED_WORKSPACE,
          workspace: firstWorkspace,
        });
        // save the selected workspace in the local storage
        localStorage.setItem(
          'selectedWorkspace',
          JSON.stringify(firstWorkspace)
        );
      }
    }
  } else {
    yield put({
      type: ERROR_FETCH_WORKSPACES,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchWorkspacesWatcher() {
  yield takeLatest(FETCH_WORKSPACES, fetchWorkspacesWorker);
}

function* createWorkspaceWorker(action) {
  const { formData } = action;
  const response = yield call(createWorkspaces, formData);

  if (response?.status) {
    yield put({
      type: SUCCESS_CREATE_WORKSPACE,
      data: response?.data,
      message: 'Workspaces created successfully!',
    });
    // save the selected workspace in the local storage
    localStorage.setItem('selectedWorkspace', JSON.stringify(response?.data));
    // set the selected workspace in the redux store
    yield put({
      type: SET_SELECTED_WORKSPACE,
      workspace: response?.data,
    });
    // Send workspace Id to the backend as the selected workspace
    yield put({
      type: STORE_SELECTED_WORKSPACE,
      workspaceId: { workspace_id: response?.data?.id },
    });
    // fetch projects of the selected workspace
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      url: '/site',
      selectedWebsite: '',
    });
  } else {
    yield put({
      type: ERROR_CREATE_WORKSPACE,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* createWorkspaceWatcher() {
  yield takeLatest(CREATE_WORKSPACE, createWorkspaceWorker);
}

function* deleteWorkspaceWorker(action) {
  const { workspaceId } = action;
  const response = yield call(deleteWorkspaces, workspaceId);

  if (response.status) {
    sendMessageToExtension({
      type: 'projectStatusUpdate',
      data: { workspaceId: workspaceId },
    });
    yield put({
      type: SUCCESS_DELETE_WORKSPACE,
      workspaceId: workspaceId,
      message: response?.message || 'Workspaces deleted successfully!',
    });
  } else {
    yield put({
      type: ERROR_DELETE_WORKSPACE,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteWorkspaceWatcher() {
  yield takeLatest(DELETE_WORKSPACE, deleteWorkspaceWorker);
}

function* editWorkspaceWorker(action) {
  const {
    id,
    formData,
    updatedUsers,
    inviteRequests,
    removeAccess,
    userToggle,
  } = action;
  const response = yield call(editWorkspaces, id, formData);

  if (response.status) {
    if (removeAccess) {
      yield put({
        type: SUCCESS_DELETE_WORKSPACE,
        workspaceId: response?.data?.id,
        message: response?.message || 'Workspaces Switched successfully!',
      });
    } else {
      yield put({
        type: SUCCESS_EDIT_WORKSPACE,
        data: response.data,
        message: response?.message || 'Workspaces edited successfully!',
        updatedUsers: updatedUsers,
        inviteRequests: inviteRequests,
        userToggle: userToggle,
      });
    }
  } else {
    yield put({
      type: ERROR_EDIT_WORKSPACE,
      message: response.message || 'Something went wrong, try again later!',
      upgradeUser: response?.descriptor,
      inviteRequests: inviteRequests,
    });
  }
}

export function* editWorkspacesWatcher() {
  yield takeLatest(EDIT_WORKSPACE, editWorkspaceWorker);
}

function* fetchWorkspacesDetailsWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);
  yield call(getWorkspaceDetails, selectedWorkspace?.id);
}

export function* fetchWorkspacesDetailsWatcher() {
  yield takeLatest(GET_WORKSPACE_DETAILS, fetchWorkspacesDetailsWorker);
}

function* fetchAccountUsersWorker() {
  const response = yield call(getAccountUsers, 1, undefined);

  if (response?.status) {
    yield put({
      type: SUCCESS_FETCH_ACCOUNT_USERS,
      usersList: response.data,
      moreUsersRecord: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ACCOUNT_USERS,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchAccountUsersWatcher() {
  yield takeLatest(FETCH_ACCOUNT_USERS, fetchAccountUsersWorker);
}

function* fetchNextAccountUsersWorker(action) {
  const response = yield call(getNextAccountUsers, action.pageNo, action.limit);

  if (response?.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_ACCOUNT_USERS,
      usersList: response.data,
      moreUsersRecord: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_ACCOUNT_USERS,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchNextAccountUsersWatcher() {
  yield takeLatest(FETCH_NEXT_ACCOUNT_USERS, fetchNextAccountUsersWorker);
}

function* storeWorkspaceWorker(action) {
  const { workspaceId } = action;
  const response = yield call(storeWorkspace, workspaceId);
  if (window.location.pathname === '/settings') {
    yield put({
      type: START_FETCH_ALL_SNIPPET_TEMPLATE,
    });
    yield put({
      type: START_FETCH_ALL_SNIPPET,
    });
  }
  if (response?.status) {
    // save the selected workspace in the local storage
    localStorage.setItem('selectedWorkspace', JSON.stringify(response?.data));
    // empty folder projects of the previous workspace
    yield put({
      type: EMPTY_SITE_LIST_USER,
    });
  }
}

export function* storeWorkspaceWatcher() {
  yield takeLatest(STORE_SELECTED_WORKSPACE, storeWorkspaceWorker);
}

function* fetchWorkspaceOwnersWorker() {
  const response = yield call(WorkspaceOwners);

  if (response?.status) {
    yield put({
      type: SUCCESS_FETCH_WORKSPACE_OWNER,
      data: response.data,
      // moreUsersRecord: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_WORKSPACE_OWNER,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchWorkspaceOwnersWatcher() {
  yield takeLatest(FETCH_WORKSPACE_OWNER, fetchWorkspaceOwnersWorker);
}
