import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import PageLoading from '../components/PageLoading';

const AdminRoute = () => {
  const { userData } = useSelector((state) => state.user);

  if (!userData?.user_permissions?.can_impersonate) {
    return (
      <Navigate
        to="/login"
        replace
        state={{
          from: window.location.pathname + window.location.search,
        }}
      />
    );
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Outlet />
    </Suspense>
  );
};

export default AdminRoute;
