import React, { createContext, ReactNode, useContext } from 'react';
import { useResponsive } from 'utils/useMediaQuery';

type ResponsiveDialogContextType = boolean | undefined;

const ResponsiveDialogContext =
  createContext<ResponsiveDialogContextType>(undefined);

interface ResponsiveDialogProviderProps {
  children: ReactNode;
}

const ResponsiveDialogProvider: React.FC<ResponsiveDialogProviderProps> = ({
  children,
}) => {
  const { isMobile } = useResponsive();

  return (
    <ResponsiveDialogContext.Provider value={isMobile}>
      {children}
    </ResponsiveDialogContext.Provider>
  );
};

export const useResponsiveDialog = (): boolean => {
  const context = useContext(ResponsiveDialogContext);
  if (context === undefined) {
    throw new Error(
      'useResponsiveDialog must be used within a ResponsiveDialogProvider'
    );
  }
  return context;
};

export default ResponsiveDialogProvider;
