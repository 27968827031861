import React, { useEffect, useState } from 'react';
// Components
import AddWebsiteUpgrade from './AddWebsiteUpgrade';
import CompleteUpgrade from './CompleteIntegrationUpgrade';
import InviteTeamUgreade from './InviteTeamUgreade';
import SelectIntegration from './SelectIntegration';
import UpgradeStepperWizard from './UpgradeStepperWizard';
import UpgradeUserDetailStep from './UpgradeUserDetailStep';
// Styling
import {
  Button,
  Dialog,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FiX } from 'react-icons/fi';
import Lottie from 'react-lottie';
import videoAnimationData from 'media/jsons/Atarim_welcome_loop_animation_a';
import animationData from 'media/jsons/spinner.json';
import AtarimLogo from 'media/svgs/atarim-blue.svg?react';
// Utils
import { useLocation } from 'react-router-dom';
import { useTrialActions } from '../../hooks/actions';
import { useTrialSelector } from '../../hooks/selectors';
import { getStartWizard } from 'utils/functions';

const makeClasses = makeStyles((theme) => ({
  dialogClass: {
    boxShadow: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px`,
    height: '100vh',
    width: '100vw',
    overflowX: 'hidden',
    zIndex: 100000,
    margin: 0,
    borderRadius: 0,
    backgroundColor: (props) =>
      props.isCollaborativeMode && 'transparent !important',
    '@media (max-width: 768px)': {
      boxShadow: `rgba(0, 0, 0, 0.56) 0px 22px 70px 4px`,
      background: '#fff',
      overflowX: 'hidden',
    },
  },
  scrollPaper: {
    height: 'auto',
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    fontFamily: theme.text.normalText.fontFamily,
  },
  closeIcon: {
    fontSize: 20,
    color: '#E72D67',
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    userSElect: 'auto',
    zIndex: 100,
  },
  stagesLoader: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  upgradeCOntainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    fontFamily: theme.text.normalText.fontFamily,
    boxSizing: 'border-box',
  },
  copyContainer: {
    width: '50%',
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    flexDirection: 'column',
  },
  leftWrapper: {
    maxWidth: 380,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bubbleContainer: {
    width: '50%',
    flexBasis: '50%',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url(/assets/svgs/background-shape.svg)`,
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(768)]: {
      width: '0%',
      flexBasis: '0%',
    },
  },
  trainingContainer: {
    width: '50%',
    overflow: 'hidden',
    margin: '0 auto',
  },
  animationText: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 27,
    fontStyle: 'normal',
    fontWeight: 700,
    marginTop: 20,
    [theme.breakpoints.down(1100)]: {
      fontSize: 22,
    },
  },
  animationCopy: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: 15,
  },
  titleCopy: {
    color: '#272D3C',
    fontSize: 22,
    fontFamily: theme.text.normalText.fontFamily,
    fontStyle: 'normal',
    lineHeight: 'normal',
    fontWeight: '700',
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  premium: {
    background: '#279AF1',
    color: '#fff',
    padding: '5px',
    lineHeight: 2,
    borderRadius: '5px',
  },
  subTitleCopy: {
    color: '#272D3C',
    fontSize: 14,
    fontFamily: theme.text.normalText.fontFamily,
    fontStyle: 'normal',
    lineHeight: '21px',
    fontWeight: '400',
    marginBottom: 10,
    textAlign: 'center',
  },
  submit: {
    margin: '10px auto 10px',
    height: 56,
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  loginLink: {
    color: '#279AF1',
    fontWeight: 500,
    textDecorationLine: 'underline',
  },
  wizardContentContainer: {
    height: '100%',
  },
  contentSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  registerContentSection: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'row',
    height: '100%',
    fontFamily: theme.text.normalText.fontFamily,
    color: theme.palette.primary.main,
    width: '100%',
    boxSizing: 'border-box',
  },
}));

const UpgradeStartWizard = () => {
  const {
    upgradePlansLoading,
    trialStatus,
    existingWebsites,
    isPlansDialogOpened,
    userData,
    userTrialData,
  } = useTrialSelector();

  const {
    updateTrialValue,
    postTrialValue,
    activateTrial,
    addNewSite,
    eligibleToInactiveTrial,
    fetchExistingSiteUrl,
    closeTrialWizard,
  } = useTrialActions();

  const location = useLocation();
  const isCollaborativeMode = location.pathname === '/collaborate';
  const classes = makeClasses({ isCollaborativeMode });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [unlockPremium, setUnlockPremium] = useState(false);
  const [unlockPremiumRegister] = useState(true);
  const [wizardSteps, setWizardSteps] = useState(0);
  const [detailSteps, setDetailSteps] = useState(0);
  const [isContainerVisible, setIsContainerVisible] = useState(false);

  useEffect(() => {
    fetchExistingSiteUrl();
    setUnlockPremium(true);
    if (userTrialData.focus === undefined) {
      return;
    }
    getStartWizard(
      userTrialData,
      setWizardSteps,
      setDetailSteps,
      setUnlockPremium
    );
  }, []);

  useEffect(() => {
    if (isPlansDialogOpened) {
      closeTrialWizard();
    }
  }, [isPlansDialogOpened]);

  const handleNext = () => {
    setWizardSteps((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSelectNext = (value) => {
    setDetailSteps((prevActiveStep) => prevActiveStep + 1);
    if (value === 2) {
      handleNext();
    }
  };

  const handleSelectPrevDetail = () => {
    if (detailSteps === 0) {
      return;
    }
    setDetailSteps((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePrev = (value) => {
    if (value === 1) {
      handleSelectPrevDetail();
    }
    setWizardSteps((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUpdateTrial = (changeKey, changeValue) => {
    updateTrialValue(changeKey, changeValue);
  };

  const handlePostTrial = (data) => {
    if (
      Object.keys(data)[0] === 'team_members' ||
      Object.keys(data)[0] === 'integrations'
    ) {
      postTrialValue(data);
    } else if (
      Object.keys(data)[0] === 'team_size' ||
      Object.keys(data)[0] === 'project_count'
    ) {
      postTrialValue(data);
    } else if (Object.keys(data)[0] === 'focus') {
      postTrialValue(data);
    } else {
      postTrialValue(userTrialData);
    }
  };

  const toggleContainer = () => {
    setIsContainerVisible((prevState) => !prevState);
  };

  const closeWizard = () => {
    if (trialStatus === 'ineligible') {
      eligibleToInactiveTrial('ineligible');
    } else {
      closeTrialWizard();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={userTrialData?.status === 'inactive' || isPlansDialogOpened}
      disableEnforceFocus={true}
      scroll="paper"
      PaperProps={{
        className: classes.dialogClass,
      }}
      classes={{
        scrollPaper: classes.scrollPaper,
      }}
      className="at-cs-modal-popover"
    >
      {upgradePlansLoading ? (
        <div className={classes.stagesLoader}>
          <Lottie
            options={{
              animationData: animationData,
            }}
            height={150}
            width={150}
          />
        </div>
      ) : (
        <>
          {unlockPremium ? (
            <div
              className={`${classes.wizardContentContainer} ${
                isCollaborativeMode ? 'at-cs-collab-mode' : ''
              }`}
            >
              {(trialStatus === 'ineligible' || trialStatus === 'inactive') && (
                <FiX
                  onClick={() => closeWizard()}
                  className={classes.closeIcon}
                />
              )}
              {unlockPremiumRegister ? (
                <div className={classes.registerContentSection}>
                  {!isCollaborativeMode && (
                    <UpgradeStepperWizard
                      wizardSteps={wizardSteps}
                      detailSteps={detailSteps}
                    />
                  )}
                  <div
                    className={`${
                      wizardSteps === 4
                        ? isCollaborativeMode
                          ? 'at-cs-trial-wizard-modal-container-complete-collab'
                          : 'at-cs-trial-wizard-modal-container-complete'
                        : 'at-cs-trial-wizard-modal-container'
                    }`}
                  >
                    {isCollaborativeMode && (
                      <AtarimLogo
                        className={`my-10 max-w-[148px] mh700:hidden ${
                          wizardSteps === 4 && 'hidden'
                        }`}
                      />
                    )}
                    {wizardSteps === 0 && (
                      <UpgradeUserDetailStep
                        handleNext={handleNext}
                        detailSteps={detailSteps}
                        handleSelectNext={handleSelectNext}
                        handleSelectPrevDetail={handleSelectPrevDetail}
                        data={userTrialData}
                        handleUpdateTrial={handleUpdateTrial}
                        handlePostTrial={handlePostTrial}
                      />
                    )}
                    {wizardSteps === 1 && (
                      <AddWebsiteUpgrade
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        wizardSteps={wizardSteps}
                        websiteData={userTrialData.websites}
                        handleUpdateTrial={handleUpdateTrial}
                        handlePostTrial={handlePostTrial}
                        existingWebsites={existingWebsites || []}
                      />
                    )}
                    {wizardSteps === 2 && (
                      <InviteTeamUgreade
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        teamMemberData={userTrialData.team_members}
                        handleUpdateTrial={handleUpdateTrial}
                        handlePostTrial={handlePostTrial}
                        adminId={userData?.admin_id}
                      />
                    )}
                    {wizardSteps === 3 && (
                      <SelectIntegration
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                        integrationData={userTrialData.integrations}
                        handleUpdateTrial={handleUpdateTrial}
                        handlePostTrial={handlePostTrial}
                        activateTrial={activateTrial}
                        toggleContainer={toggleContainer}
                        isContainerVisible={isContainerVisible}
                      />
                    )}
                    {wizardSteps === 4 && (
                      <CompleteUpgrade
                        handleNext={handleNext}
                        websiteData={
                          userTrialData?.websites.filter(
                            (each) => each !== ''
                          ) || []
                        }
                        teamMemberData={userTrialData.team_members || []}
                        handleUpdateTrial={handleUpdateTrial}
                        adminId={userData?.admin_id}
                        addNewSite={addNewSite}
                        userId={userTrialData?.user_id || []}
                      />
                    )}
                    {/* <GotoChromeWideWidget/> */}
                  </div>
                </div>
              ) : (
                <div className={classes.contentSection}>
                  <div style={{ marginBottom: 20 }}>
                    <AtarimLogo width={147} height={40} />
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          ) : (
            <div className={classes.mainDiv}>
              <div className={classes.upgradeCOntainer}>
                <div className={classes.copyContainer}>
                  <div className={classes.leftWrapper}>
                    <div>
                      <AtarimLogo width={147} height={40} />
                    </div>
                    <div className={classes.titleCopy}>
                      Do You Want to Unlock <br />
                      Atarim <span className={classes.premium}>Premium</span>
                    </div>
                    <div className={classes.subTitleCopy}>
                      Experience Atarim's Premium Features <b>FREE</b> for{' '}
                      <b>14 Days!</b> Automate your tasks, collaborate
                      effortlessly, and customize with your logo for a
                      personalized, white-labeled experience
                    </div>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      onClick={() => setUnlockPremium(true)}
                    >
                      Unlock a Premium Account for 14 Days
                    </Button>
                    <a
                      className={classes.loginLink}
                      href={`/`}
                      target="__blank"
                    >
                      I'd like to continue with a Free account
                    </a>
                  </div>
                </div>
                <div className={classes.bubbleContainer}>
                  <div className={classes.bubbleBackgroundImageLottie}>
                    <div className={classes.trainingContainer}>
                      <Lottie
                        options={{
                          animationData: videoAnimationData,
                        }}
                        height={'100%'}
                        width={'100%'}
                      />
                    </div>
                    <div className={classes.animationText}>
                      Work together without the chaos.{' '}
                    </div>
                    <div className={classes.animationCopy}>
                      Don’t rely on guesswork and endless back-and-forth email
                      communication. <br />
                      Keep everything in one place, get organized, and be more
                      productive.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default UpgradeStartWizard;
